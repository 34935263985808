import React, { useState, useEffect } from "react";
import "./JoinUs.scss";

import { useTranslation } from "react-i18next";
import { useTransition, animated } from "react-spring";

import AppStore from "../../images/apple.svg";
import GooglePlay from "../../images/googleplay.svg";
import AppStoreDesktop from "../../images/appstore-desktop.svg";
import GooglePlayDesktop from "../../images/googleplay-desktop.svg";
import info from "../../images/info.svg";

import SignUpBtn from "../../elements/SignBtn";
import AccText from "../AccText";

const JoinUs = ({ navigation }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  useEffect(() => {
    if (open === true) {
      // setTimeout(() => {
      //   setOpen(false);
      // }, 4000);
    }
  }, [open]);

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  return (
    <div className="joinUs">
      {/* Success email */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}
      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={info} alt="info" />
                <h3>
                  COMING SOON! <br /> ( APRIL 2023)
                </h3>
                <p>
                  The service is currently under construction. Sign up to our
                  newsletter to get notified when it launches
                </p>
              </div>
            </animated.div>
          )
      )}
      {/* End success */}

      <h2>{t("modals.joinUs")}</h2>
      <div className="joinUsInside">
        <div className="businessInside">
          <div className="businessContent" onClick={() => navigation.next()}>
            <h2 className="title">{t("modals.business")}</h2>
            <p style={{ marginBottom: 40 + "px" }}>
              {t("modals.businessDescription")}
            </p>
            <SignUpBtn title="Create business account" />
          </div>
        </div>

        <div className="businessInside">
          <div className="businessContent">
            <h2 className="title">{t("modals.individual")}</h2>
            <p>{t("modals.individualDescription")}</p>
            <div className="icons">
              <a href="https://apps.apple.com/rs/app/linker/id1671716248" target="_blank">
                <img className="appMobile" src={AppStore} alt="AppStore" />
                <img
                  className="appDesktop"
                  src={AppStoreDesktop}
                  alt="AppStoreDesktop"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=se.linker" target="_blank">
                <img
                  className="googleMobile"
                  src={GooglePlay}
                  alt="GooglePlay"
                />
                <img
                  className="googleDesktop"
                  src={GooglePlayDesktop}
                  alt="GooglePlayDesktop"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <AccText onClick={() => navigation.go(5)} />
      {/* <AccText onClick={() => setOpen(!open)} /> */}
    </div>
  );
};

export default JoinUs;
