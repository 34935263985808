import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "./AccountSetting.scss";
import "../Settings.scss";
import arrow from "../../../../../images/arrow.svg";
import {useDispatch} from "react-redux";
import axios from "../../../../../services/https";

const AccountSetting = () => {
    const dispatch = useDispatch();
    const [subscription, setSubscription] = useState(false);
    const [stripeUrl, setStripeUrl] = useState(false);


    useEffect(() => {
        axios.get("stripe").then((res) => {
            setStripeUrl(res.data.data.url);
        });
    }, []);

  return (
    <div className="accountSettings">
      <h2>Account settings</h2>

      <div className="settings">
        <Link to="/change-password" className="changePassword">
          Change password <img src={arrow} alt="arrow" />
        </Link>
        <Link to="/change-email" className="changePassword">
          Email <img src={arrow} alt="arrow" />
        </Link>
        <Link to="/delete-account" className="changePassword">
          Delete account <img src={arrow} alt="arrow" />
        </Link>
          <Link to={stripeUrl} className="changePassword">
              Manage subscription <img src={arrow} alt="arrow" />
          </Link>
      </div>
    </div>
  );
};

export default AccountSetting;
