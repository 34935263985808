import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import card from "../../../../images/card-image.png";
import resume from "../../../../images/resume.svg";
import downArrow from "../../../../images/down_arrow.svg";
import rightarrow from "../../../../images/right_arrow.svg";
import suitcase from "../../../../images/suitcase.svg";
import cap from "../../../../images/cap.svg";
import location from "../../../../images/location.svg";
import close from "../../../../images/close.svg";
import like from "../../../../images/like.svg";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import axios from "../../../../services/https";
import { useSwipeable } from "react-swipeable";
import "./Card.scss";
import "react-multi-carousel/lib/styles.css";
import likeTag from '../../../../images/likeTag.svg';
import skipTag from '../../../../images/skipTag.svg';
import ReactHtmlParser from 'react-html-parser';
import {
  changeUserAction,
  likeUserAction, matchedUserAction, saveUserAction,
  updateConversationUser,
  updateConversationUserId,
  updateUserProfile
} from "../../../../redux/actions/app";
import {useDispatch, useSelector} from "react-redux";
import noImage from "../../../../images/upload-image.svg";
import Report from "./Report";


const Card = ({reports, element, removeUserFromList, addShadow}) => {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [moveReport, setMoveReport] = useState("");
  const [translateCardX, setTranslateCardX] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [cardOpacity, setCardOpacity] = useState(1);
  const [likeOpacity, setLikeOpacity] = useState(0);
  const [skipOpacity, setSkipOpacity] = useState(0);
  const [visibility, setVisibility] = useState('visible');
  const [topImageIndex, setTopImageIndex] = useState(0);
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isMobile, setIsMobile] = useState(false)
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [matchStatusClass, setMatchStatusClass] = useState("matchStatusTag");

  const slicedCv = element?.cv?.split("").slice(9, element?.cv?.length).join("");

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  });

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    if (element.matchStatus) {
      switch(element.matchStatus.id) {
        case 1:
          setMatchStatusClass("networking matchStatusTag")
          break;
        case 2:
          setMatchStatusClass("jobSeeking matchStatusTag")
          break;
        case 3:
          setMatchStatusClass("openToWork matchStatusTag")
          break;
        default:
          setMatchStatusClass("matchStatusTag")
      }
    }
  }, element);


  const handleResize = () => {
    if (document.documentElement.clientWidth <= 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  const swiping = (eventData) => {
    setRotate(Math.round(-eventData.deltaX / 10))
    setTranslateCardX(eventData.deltaX);

    if (eventData.dir === "Left") {
      if (translateCardX < -50) {
        setSkipOpacity(1);
      }
    } else if (eventData.dir === "Right") {
      if (translateCardX > 50) {
        setLikeOpacity(1);
      }
    }
  };

  const swiped = (eventData) => {
    if (translateCardX < 150 && translateCardX > -150 ) {
        setTranslateCardX(0);
        setRotate(0);
        setCardOpacity(1);
        setVisibility('visible');
        setLikeOpacity(0);
        setSkipOpacity(0);
    }

    if (eventData.dir === "Right") {
      if (translateCardX > 150) {
        setCardOpacity(0);
        setVisibility('hidden');
        setTimeout(() => {
          removeUserFromList();
        }, 250);
        axios.post("match/like", {
          id: element.id,
          type: 1
        })
        .then((res) => {
          dispatch(likeUserAction(element));
          if (res.data.data.status === 1) {
            dispatch(matchedUserAction(res.data.data));
          }

        })
        .catch((res) => {
          console.log(res);
        });
      }
    }

    if (eventData.dir === "Left") {
      if (translateCardX < -150) {
        setCardOpacity(0);
        setVisibility('hidden');
        setTimeout(() => {
          removeUserFromList();
        }, 250);
      }
    }
  }

  const skip = () => {
    setRotate(0);
    setTranslateCardX(0);
    setCardOpacity(1);
    setVisibility('visible');
    setSkipOpacity(0);
    setRotate(30);
    setTranslateCardX(-151);
    setTimeout(() => {
      setCardOpacity(0);
      setVisibility('hidden');
      setTimeout(() => {
        removeUserFromList();
      }, 250);
      setSkipOpacity(1);
    }, 300);
  };

  const handleRightSwipe = () => {
    setRotate(0);
    setTranslateCardX(0);
    setCardOpacity(1);
    setVisibility('visible');
    setLikeOpacity(0);
    axios.post("match/like", {
      id: element.id,
      type: 1
    })
    .then((res) => {
      dispatch(likeUserAction(element));
      if (res.data.data.status === 1) {
        dispatch(matchedUserAction(res.data.data));
      }
    })
    .catch((err) => {
      console.log(err);
    });
    setTranslateCardX(151);
    setRotate(-30);

    setTimeout(() => {
      setCardOpacity(0);
      setVisibility('hidden');
      setTimeout(() => {
        removeUserFromList();
      }, 250);
      setLikeOpacity(1);
    }, 300);
  };


  const changeImage = (index) => {
    if (isMobile) {
    if ((index + 1) < (element.images.length)) {
      setTopImageIndex(topImageIndex + 1);
    } else {
      setTopImageIndex(0);
    }
    }

  }

  const nextImage = () => {
    if (topImageIndex + 1 < element.images.length) {
      setTopImageIndex(topImageIndex + 1);
    } else {
      setTopImageIndex(0);
    }
  }

  const backImage = () => {
    if (topImageIndex === 0) {
      setTopImageIndex(element.images.length - 1);
    } else {
      setTopImageIndex(topImageIndex - 1);
    }
  }


  const handlers = useSwipeable({
    onSwipeStart: () => true,
    onSwiping: swiping,
    onSwiped: swiped,
    onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  });

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 200,
  });

  const handleSaveMatch = async () => {
    await axios.post("match/save", {
      id: element.id,
      type: 1
    })
    dispatch(saveUserAction(1));
    element.saved = true;
    dispatch(changeUserAction(element));
  };

  const handleDeleteSaveMatch = async () => {
    await axios.post("match/delete/save", {
      id: element.id,
      type: 1
    })
    element.saved = false;
    dispatch(saveUserAction(-1));
    dispatch(changeUserAction(element));


  };

  const handleSendMessage = () => {
    dispatch(updateConversationUser(element));
    navigate("/messages");
  };

  const handlePopup = async (user) => {
    setOpen(!open);
    setMoveReport(user);
  };


  useEffect(() => {
    if (element) {
      setChecked(element.saved);
    }
  }, [element]);

  useEffect(() => {
    if (app.userChanged) {
      if (app.userChanged.id === element.id) {
        element.saved = app.userChanged.saved;
        if (app.userChanged.saved === true) {
          setChecked(true)
        } else {
          setChecked(false);
        }
      }
    }
  }, [app.userChanged]);


  const openCV = () => {
    if (slicedCv) {
      setTimeout(() => {
        window.open(process.env.REACT_APP_BASE_URL + slicedCv, '_top')
      });
    } else {
      alert("This user has no CV");
    }
  }

  const openUser = () => {
    dispatch(updateUserProfile(element));
  }


  return (
    <div>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)} onTouchStart={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="reportPopup">
              <Report setOpen={setOpen} user={element} />
            </animated.div>
          )
      )}
      <div 
        {...handlers} 
        style={{
          transform: `rotate(${rotate}deg) translateX(${translateCardX}px`,
          visibility: visibility,
          opacity: cardOpacity
        }} 
        className="card"
        id={element.id}>
          <div>
            <div className="cardLook" style={{ boxShadow: addShadow && "0px 0px 13px 0px rgba(194,186,194,1)" }}>
              <div className="leftSide">
                { isMobile ? <img style={{ opacity: skipOpacity }} className="skipTag" alt="skip" src={skipTag}  /> : null }
                <img style={{ opacity: likeOpacity }} className="likeTag" alt="like" src={likeTag}  />
                <div className="UserCardImage">
                  {element.images.length === 0 ?
                      <img className={"UserCardImageOnly"} src={noImage} /> : null}
                  {element.images.map((image, index) => (
                      <img className="UserCardImageOnly" onClick={() => changeImage(index)} onTouchStart={() => changeImage(index)}
                           style={topImageIndex === index ? {zIndex: '2'} : {zIndex: '1'}}
                           key={index} src={baseUrl + image.imagePath}/>
                  )).reverse()}

                  {!isMobile ?
                      <img className={"leftArrow"} onClick={backImage} src={rightarrow} />
                      : null }

                  <div className={isMobile? "UserCardImageLinesMobile" : "UserCardImageLines"} style={!isMobile ? {width: ((28 * element.images.length) + (4 * (element.images.length - 1))) + "px"} : null}>
                    {element.images.map((image, index) => (
                        <div className="UserCardImageLine"
                             style={topImageIndex === index ? {backgroundColor: 'white'} : {backgroundColor: '#7F7D87'}}/>
                    ))}
                  </div>

                  {!isMobile ?
                    <img className={"rightArrow"} onClick={nextImage} src={rightarrow} />
                  : null }

                </div>

                <div className="mobileBookmark">
                  {checked ? (
                    <div
                      onClick={() => {
                        setChecked(false);
                        handleDeleteSaveMatch();
                      }} onTouchStart={() => {
                      setChecked(false);
                      handleDeleteSaveMatch();
                    }}>
                      <BookmarkIcon style={{color: '0066FF'}} />
                    </div>) 
                    : (
                    <div
                        onClick={() => {
                          setChecked(true);
                          handleSaveMatch();
                        }} onTouchStart={() => {
                      setChecked(true);
                      handleSaveMatch();
                    }}>
                        <BookmarkBorderIcon style={{color: '0066FF'}} />
                    </div>
                  )}
                </div>
                <div
                    onTouchStart={() => openCV()}
                    className="mobileResumeLink"
                  >
                    <img
                      src={resume}
                      alt="cv"
                    />
                    <span className="mobileResumeText">
                       CV
                    </span>
                  </div>
                <div className={"moreInfoButton"} onTouchStart={() => openUser()}>
                  <img src={downArrow} style={{transform: "rotate(180deg)"}} />
                </div>
                <div className="buttons">
                  <button
                    className="sendMessage"
                    onClick={() => handleSendMessage(element.id)}
                  >
                    Send message
                  </button>
                  <hr className="hrTag"/>
                  <button
                    className="report"
                    onClick={() => handlePopup(element)}
                  >
                    Report user
                  </button>
                </div>
              </div>
              <div className="rightSide">
                { !isMobile ? <img style={{ opacity: skipOpacity }} className="skipTag" alt="skip" src={skipTag}  />   : null }

                <div className="mobileWrap">
                  <div className="nameBookmarkWrap">
                    <h2 className="name">{element.name}</h2>
                    <div className="bookmark">
                      {checked ? (
                        <div
                          onClick={() => {
                            setChecked(false);
                            handleDeleteSaveMatch();
                          }}>
                          <BookmarkIcon style={{color: '0066FF'}} />
                        </div>) 
                        : (<div
                            onClick={() => {
                              setChecked(true);
                              handleSaveMatch();
                            }}>
                            <BookmarkBorderIcon style={{color: '0066FF'}} />
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="matchJobWrap">
                    <div className="matchStatus">
                      {element.matchPercentage}% MATCH
                    </div>
                    {element.matchStatus ?
                        <div className={matchStatusClass}>
                         {element.matchStatus.name}
                      </div> : null
                    }


                  </div>
                  <div className="info">
                    <div className="infoWrap">
                      <img
                        src={suitcase}
                        alt="suitcase"
                        className="icon"
                      />
                      {element.currentJobTitle
                        ? `${element.currentJobTitle}`
                        : "-"}
                    </div>
                    <div className="infoWrap">
                      <img
                        src={cap}
                        alt="cap"
                        className="icon"
                      />
                      {element.school ? `${element.school}` : "-"}
                    </div>
                    <div className="infoWrap">
                      <img
                        src={location}
                        alt="location"
                        className="icon"
                        style={{ marginLeft: 3}}
                      />
                      {element.city && element.city.name
                        ? `${element.city?.name}`
                        : "-"}
                      {element.distance != null && element.distance !== -1 ? " | " + Math.round(element.distance / 1000) + "km away" : null}
                    </div>
                  </div>
                  <div className="allTagsCard">
                    {element.preferredWorkTypes?.map((tag, index) => {
                      return <div className={"darkTag"} key={index}> {tag.name} </div>
                    })
                    }
                    {element.preferredWorkPlaces?.map((tag, index) => {
                      return <div className={"darkTag"} key={index}> {tag.name} </div>
                    })
                    }
                    {element.tags.map((tag, index) => {
                      return <div className={"darkTag"} key={index}> {tag.name} </div>
                    })
                    }
                  </div>
                  <div className={"for-resume-link"}>
                  <div
                      className="resumeLink" onClick={() => openCV()} onTouchStart={() => openCV()}
                  >
                    <img
                        src={resume}
                        alt="CV"
                        className={" user-" + element.id}
                    />
                    <span className="resumeText">
                         CV
                      </span>
                  </div>
                  </div>
                  <div className="aboutLinkWrap">
                    <div className="about">
                      <h5 className="aboutText">About me</h5>
                      <p className="aboutContent">{element.about ? ReactHtmlParser(element.about) : "-"}</p>
                    </div>

                    <div className="links">
                      <h5 className="linksText">Links</h5>
                      <ul>
                        {element?.links.map((link, index) => {
                          return <a href={link.url} target={"_blank"} key={index}
                                    className={"UserProfileLink"}>{link.url}</a>
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="mobileButtons">
                    <div className="mobileSendWrap">
                      <button
                        className="mobileSendMessage"
                        onClick={() => handleSendMessage(element.id)}
                        onTouchStart={() => handleSendMessage(element.id)}
                      >
                        Send message
                      </button>
                    </div>
                    <button
                      className="mobileReport"
                      onClick={() => handlePopup(element)} onTouchStart={() => handlePopup(element)}
                    >
                      Report user
                    </button>
                  </div>
                </div>
                <div className="skipLinkWrap">
                  <button
                    className="skipButton"
                    onClick={() => skip(element.id)} onTouchStart={() => skip(element.id)}
                  >
                    <img
                      src={close}
                      alt="close"
                      className={" user-" + element.id}
                    />{" "}
                    Skip
                  </button>
                  <button
                    className="likeButtonWrap"
                    onClick={() =>
                      handleRightSwipe(element.id, element.type)
                    } onTouchStart={() =>
                      handleRightSwipe(element.id, element.type)
                  }
                  >
                    <img
                      src={like}
                      alt="like"
                      className={"user-" + element.id}
                    />
                    Like
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
      {/* .reverse() */}
    </div>
  );
};

export default Card;
