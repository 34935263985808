import "./NewMessageDrawer.scss"
import React, {useEffect, useState} from "react";
import {animated, useTransition} from "react-spring";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    changeUserAction, openNewMessageAction, openUserAction,
    saveUserAction,
    updateConversationUser,
    updateUserProfile
} from "../../../../redux/actions/app";
import axios from "../../../../services/https";
import close from "../../../../images/close.svg";
import noImage from "../../../../images/upload-image.svg";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import resume from "../../../../images/resume.svg";
import downArrow from "../../../../images/down_arrow.svg";
import suitcase from "../../../../images/suitcase.svg";
import cap from "../../../../images/cap.svg";
import location from "../../../../images/location.svg";
import ReactHtmlParser from "react-html-parser";
import Search from "../../../../components/Search";
import avatarImage from "../../../../images/upload-image.svg";
import message from "../../../../images/messages.svg";


const NewMessageDrawer = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = useState(false);
    const desktopTransition = useTransition(open, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 1},
    });
    const [inputText, setInputText] = useState("");
    const app = useSelector((state) => state.app);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [topImageIndex, setTopImageIndex] = useState(0);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);


    useEffect(() => {
        window.onpopstate = () => {
            closePopup();
        }
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    });


    const handleMatchStatusColor = (user) => {
        if (user.matchStatus.id === 1) {
            return "networking";
        } else if (user.matchStatus.id === 3) {
            return "openToWork";
        } else {
            return "jobSeeking"
        }
    }

    const handleResize = () => {
        if (document.documentElement.clientWidth <= 992) {
            setIsMobile(true);
            // setProfileTransition(mobileProfileTransiton);
        } else {
            setIsMobile(false);
            // setProfileTransition(desktopTransition);
        }
    }


    useEffect(() => {
        if (app.openNewMessage) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [app.openNewMessage]);


    const closePopup = () => {
        setOpen(false);
        setUser(null);
        dispatch(openNewMessageAction(false));
    }

    useEffect(() => {
        axios
            .get("matches/search/" + inputText + "/0")
            .then((res) => {
                setUsers(res.data.data);
            });
    }, [inputText]);



    const openUser = (user) => {
        setOpen(false);
        dispatch(updateConversationUser(user));
        dispatch(openNewMessageAction(false));
    }

    return (
        <div>
            <div>
                { desktopTransition(
                    (styles, item) =>
                        item && (
                            <animated.div style={styles} className="NewMessageDrawer">
                                    <div className="NewMessageDrawerHeader">
                                        <div className="NewMessageDrawerHeaderClose" onClick={() => closePopup()}>
                                            <img
                                                src={close}
                                                alt="close"
                                            />
                                        </div>
                                        <span>New Message</span>
                                    </div>


                                <div className={"newMessageInner"}>
                                    <Search setInputText={setInputText} />

                                <ul className="list">
                                    {users.map((item, index) => (
                                        <li key={index} className={"listItem"}>
                                            <div className="matchInfo" onClick={() => openUser(item)}>
                                                <img
                                                    className={"avatar-image"}
                                                    src={
                                                        item?.images?.length > 0
                                                            ? process.env.REACT_APP_BASE_URL + item.images[0].imagePath
                                                            : avatarImage
                                                    }
                                                    alt="avatar"
                                                />
                                                <div className="matchUser">
                                                    <span className={"matchUserName"}>{item.name}</span>
                                                    <div className={"pillsContainer"} >
                                                        <div className={"matchPercentages"}>{item.matchPercentage}% match</div>
                                                        {
                                                            item.matchStatus && (
                                                                <div className={handleMatchStatusColor(item)}>{item.matchStatus?.name}</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                            </animated.div>
                        )
                )}
            </div>

        </div>
    )

}

export default NewMessageDrawer;