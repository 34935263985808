import React from "react";
import "./SignBtns.scss";

const SignIn = ({ onClick }) => {
  return (
    <div className="signInBtn" onClick={onClick}>
      Sign in
    </div>
  );
};

export default SignIn;
