import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Baner.scss";

import banerImage from "../../../images/baner-image.png";
import banerImage2 from "../../../images/baner-image-second.png";
import line1 from "../../../images/line1.svg";
import line2 from "../../../images/line2.png";
import close from "../../../images/close.svg";

import { useTransition, animated } from "react-spring";
import SignUp from "../../auth/SignUp/SignUp";

const Baner = ({ navigation }) => {
  const { t } = useTranslation();
  const [signUpPopup, setsignUpPopup] = useState(false);

  // Sign up popup
  const transitionsSignUp = useTransition(signUpPopup, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const signUpPopupTransitions = useTransition(signUpPopup, {
    from: { opacity: 0, transform: "translateY(-100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-100%)" },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="baner">
      {/* Sign up popup */}
      {transitionsSignUp(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="signInPopupBackground"
              onClick={() => setsignUpPopup()}
            ></animated.div>
          )
      )}

      {signUpPopupTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="signInPopupContent">
              <div className="signUpPopupHeader">
                <div className="closePopup">
                  <img
                    src={close}
                    alt="close"
                    onClick={() => setsignUpPopup(!signUpPopup)}
                  />
                </div>
              </div>

              <SignUp />
            </animated.div>
          )
      )}
      {/* End Sign up popup */}
      <div className="banerContent">
        <div className="leftContent">
          {/* <h2>{t("banerSection.title")}</h2> */}
          <h1>{t("banerSection.smallTitle")}</h1>
          <p>{t("banerSection.description")}</p>
          <img className="line1" src={line1} alt="line1" />

          <div className="btns">
            <button
              className="getStarted"
              onClick={() => setsignUpPopup(!signUpPopup)}
            >
              Get started
            </button>

            {/* <a href="/#our-services" className="learnMore">
              Learn more
            </a> */}
          </div>
          <img className="mobileBanerImage" src={banerImage} alt="banerImage" />
        </div>
        <div className="rightContent">
          <img className="banerFirstImage" src={banerImage} alt="banerImage" />
          <img
            className="banerSecondImage"
            src={banerImage2}
            alt="banerImage2"
          />
          <img className="line2" src={line2} alt="line2" />
        </div>
      </div>
    </div>
  );
};

export default Baner;
