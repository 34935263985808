import React from 'react'

import MobileNavigation from './mobileNavigation/MobileNavigation'
import DesktopNavigation from './desktopNavigation/DesktopNavigation'

const Navigation = () => {
  return (
    <div>
      <MobileNavigation />
      <DesktopNavigation />
    </div>
  )
}

export default Navigation
