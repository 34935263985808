import React, {useState, useEffect} from "react";
import {useTransition, animated} from "react-spring";
import "./Jobs.scss";
import axios from "../../../../services/https";

import Navigation from "../../Navigation";
import Search from "../../../../components/Search";
import Tabs from "./Tabs/Tabs";
import Content from "../Jobs/Content/Content";

import TabContext from "@material-ui/lab/TabContext";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {useDispatch, useSelector} from "react-redux";
import {openFilter, openJobAction, openNewJobAction, saveJob, updateUserProfile} from "../../../../redux/actions/app";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import createJob from "../../../../images/plus.svg";
import close from "../../../../images/close.svg";
import back from "../../../../images/ic_back.svg";
import pin from "../../../../images/pin.svg";

import Card from "./Cards/Card";
import "./Cards/Card.scss";
import parse from "html-react-parser";
import JobForm from "../../../../JobForm";

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import noImage from "../../../../images/upload-image.svg";
import {useSwipeable} from "react-swipeable";
import avatarImage from "../../../../images/upload-image.svg";

const stripePromise = loadStripe(`${process.env.REACT_APP_STAGING}`);

const Jobs = () => {
    const [value, setValue] = useState("1");
    const [filter, setOpenFilter] = useState(false);
    const [reviewJob, setReviewJob] = useState(false);
    const [payJob, setPayJob] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [user, setUser] = useState("");
    const app = useSelector((state) => state.app);
    const [allWorkTypes, setWorkTypes] = useState([]);
    const [allWorkPlaces, setWorkPlaces] = useState([]);
    const [allLevels, setLevels] = useState([]);
    const [uid, setUid] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const [jobTitle, setJobTitle] = useState("");
    const [workType, setWorkType] = useState([]);
    const [workTypeNext, setWorkTypeNext] = useState([]);
    const [workPlace, setWorkPlace] = useState([]);
    const [workPlaceNext, setWorkPlaceNext] = useState([]);
    const [workLevels, setWorkLevels] = useState([]);
    const [workLevelsNext, setWorkLevelsNext] = useState([]);
    const [salary, setSalary] = useState(false);
    const [jobDescription, setJobDescription] = useState("");
    const [jobApplicationPage, setJobApplicationPage] = useState("");
    const [val, setVal] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [links, setLink] = useState([]);
    const [isActive, setIsActive] = useState("0");
    const [isAutoApply, setIsAutoApply] = useState(0);
    // const [isCompetitiveCompensation, setIsCompetitiveCompensation] =
    //   useState("0");

    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [selectedPath, setSelectedPath] = useState("");
    const [imageURLs, setImageURLs] = useState([]);

    const [text, setText] = useState("");
    const [search, setSearch] = useState([]);
    const [city, setCity] = useState([]);
    const [valueS, setValues] = useState();
    const [showCity, setShowCity] = useState("");
    const [showCityId, setShowCityId] = useState("");

    const [showClickedLocation, setShowClickedLocation] = useState("");
    const [showClickedLocationId, setShowClickedLocationId] = useState("");

    const [jobData, setJobData] = useState("");

    const [subscriptionType, setPayment] = useState();
    const [type, setType] = useState();

    let [itemList, setItemList] = useState([]);
    const [tags, setTags] = useState([]);
    const [jobTags, setJobTags] = useState([]);
    const dispatch = useDispatch();
    const [tagsMode, setTagsMode] = useState(false);
    const [extraTags, setExtraTags] = useState();
    const [extraTagsText, setExtraTagsText] = useState("");
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [topImageIndex, setTopImageIndex] = useState(0);
    const [reviewOnly, setReviewOnly] = useState(false);
    const [address, setAddress] = useState("");
    const [free, setFree] = useState(false);
    const [options, setOptions] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 1,
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1,
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
        },
    };

    useEffect(() => {
        axios.get("count/job").then((res) => {
            if (res.data.data.count != 0) {
                setFree(true);
            }
        });
    }, []);

    useEffect(() => {
        axios.get("count/job").then((res) => {
            if (res.data.data.count != 0) {
                setFree(true);
            }
        });
    }, [jobs]);


    useEffect(() => {
        showSubscriptionType();
    }, []);

    useEffect(() => {
        if (app.openNewJob) {
            setOpenFilter(true);
            dispatch(openNewJobAction(null));
        }
    }, [app.openNewJob]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get(`/tag?tag=${extraTagsText}`).then((res) => {
                setExtraTags(res.data.data);
            });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [extraTagsText]);

    const selectedTag = (value) => {

        if (value) {
            const tagIndex = tags.findIndex(t => t.id === value.id);

            if (tagIndex === -1) {
                tags.push(value);
            }

            setTags([...tags]);

            let updatedTags = jobTags;
            const index = updatedTags.findIndex((c) => c === value.id);

            if (index === -1) {
                updatedTags.push(value.id);
            } else {
                updatedTags.splice(index, 1);
            }

            setJobTags([...updatedTags]);
        }
    }

    const swipedImages = (eventData) => {
        const el = document.getElementsByClassName('UserProfileImage')[0];
        let newIndex = 0;
        if (eventData.dir === "Right") {
            newIndex = topImageIndex - 1;
            if (topImageIndex === 0) {
                newIndex = 0;
            }
            setTopImageIndex(newIndex);
        } else {
            newIndex = topImageIndex + 1;
            if (topImageIndex === imageURLs.length - 1) {
                newIndex = topImageIndex;
            }
            setTopImageIndex(newIndex);
        }
        el.scrollTo({
            top: 0,
            left: newIndex * el.clientWidth,
            behavior: "smooth",
        });
    }


    const handlers = useSwipeable({
        onSwipeStart: () => true,
        onSwiped: swipedImages,
        onTouchStartOrOnMouseDown: ({event}) => event.preventDefault(),
        touchEventOptions: {passive: true},
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const showSubscriptionType = () => {
        axios.get("/intent/start").then((res) => {
            setOptions(res.data.url)
        });
    };

    useEffect(() => {
        axios
            .get("app-data")
            .then((res) => {
                setWorkTypes(res.data.data.tagCategories[1].tags);
                setWorkPlaces(res.data.data.tagCategories[2].tags);
                setLevels(res.data.data.tagCategories[3].tags);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get("job")
            .then((res) => {
                setJobs(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });

        const itemList = JSON.parse(localStorage.getItem("jobData"));
        if (jobData) {
            setItemList(itemList);
        }

        getUsers();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get(`/city?city=${search}`).then((res) => {
                setCity(res.data.data);
            });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const showText = (e) => {
        setSearch(e.target.value);
    };

    const showClickedList = (name, id) => {
        const n = name;
        const i = id;

        setShowClickedLocation(n);
        setShowClickedLocationId(i);
    };

    const isTagChecked = (tag) => {
        const index = jobTags.findIndex((c) => c == tag.id);
        return index !== -1;
    }

    const handleTags = (e) => {
        const id = e.target.id;

        let updatedTags = jobTags;
        const index = updatedTags.findIndex((c) => c == id);

        if (index === -1) {
            updatedTags.push(id);
        } else {
            updatedTags.splice(index, 1);
        }


        setJobTags([...updatedTags]);

    }

    function getUsers() {
        axios.get("me").then((res) => {
            setShowCity(res.data.data.city.name);
            setShowCityId(res.data.data.city.id);
            setImageURLs(res.data.data.images.map((i) => i.imagePath));
        });
    }

    //   Filter transition
    const transitionsFilter = useTransition(filter, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const filterTransitions = useTransition(filter, {
        from: {opacity: 0, transform: "translateX(100%)"},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: "translateX(100%)"},
    });
    //  End filter transition

    //   Job review
    const transitionsJobReview = useTransition(reviewJob, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const filterTransitionsReview = useTransition(reviewJob, {
        from: {opacity: 0, transform: "translateX(100%)"},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: "translateX(100%)"},
    });
    //   End job review

    //   Pay job transition
    // const transitionsPay = useTransition(payJob, {
    //     from: {opacity: 0},
    //     enter: {opacity: 1},
    //     leave: {opacity: 0},
    // });
    //
    // const payTransitions = useTransition(payJob, {
    //     from: {opacity: 0, transform: "translateX(100%)"},
    //     enter: {opacity: 1, transform: "translateX(0%)"},
    //     leave: {opacity: 0, transform: "translateX(100%)"},
    // });
    //  End pay job transition

    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        if (searchInput !== "") {
            const filteredData = jobs.filter((item) => {
                return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(searchInput.toLowerCase());
            });
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(jobs);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleWorkType = (e) => {
        const id = +e.target.id;
        const index = workType.findIndex((t) => t === id);

        if (index === -1) {
            workType.push(id);
            setWorkType([...workType]);
        } else {
            workType.splice(index, 1)
            setWorkType([...workType]);
        }

    };

    const isWorkTypeChecked = (id) => {
        const index = workType?.findIndex((t) => t === +id);
        return index !== -1;
    }

    const handleWorkPlace = (e) => {
        const id = +e.target.id;
        const index = workPlace?.findIndex((t) => t === id);

        if (index === -1) {
            workPlace.push(id);
            setWorkPlace([...workPlace]);
        } else {
            workPlace.splice(index, 1)
            setWorkPlace([...workPlace]);
        }
    };

    const isWorkPlaceChecked = (id) => {
        const index = workPlace?.findIndex((t) => t === +id);
        return index !== -1;
    }

    const handleLevels = (e) => {
        const id = +e.target.id;
        const index = workLevels?.findIndex((t) => t === id);

        if (index === -1) {
            workLevels.push(id);
            setWorkLevels([...workLevels]);
        } else {
            workLevels.splice(index, 1)
            setWorkLevels([...workLevels]);
        }
    };

    const isLevelsChecked = (id) => {
        const index = workLevels?.findIndex((t) => t === +id);
        return index !== -1;
    }

    // const handleSalary = (e) => {
    //   const checked = e.target.checked;

    //   if (checked) {
    //     setSalary(true);
    //   } else {
    //     setSalary(!false);
    //   }
    // };

    // const handleApply = (e) => {
    //   const checked = e.target.checked;

    //   console.log(checked);

    //   if (checked) {
    //     setIsAutoApply(true);
    //   } else {
    //     setIsAutoApply(!false);
    //   }
    // };

    const handleAdd = (e) => {
        e.preventDefault();
        const abc = [...val, {link: ''}];
        setVal(abc);
    };

    const handleAddLinks = (e) => {
        e.preventDefault();
        const link = [...links, {url: ''}];
        setLink(link);
    };

    const handleContact = (onChangeValue, i) => {
        val[i].email = onChangeValue.target.value;
        setVal(val);

    };

    const handleLinks = (onChangeValue, index) => {
        links[index].url = onChangeValue.target.value;
        setLink(links);
    };

    const goBack = () => {
        if (!reviewOnly) {
            setOpenFilter(!filter);
        } else {
            // refresh data
            setJobTitle("");
            setJobDescription("");
            setIsAutoApply(false);
            setWorkType([]);
            setWorkPlace([]);
            setWorkLevels([]);
            setSalary(false);
            setVal([]);
            setApplicants([]);
            setJobApplicationPage(null);
            setShowClickedLocationId("");
            setJobTags([]);
            setAddress("");
        }
        setReviewJob(!reviewJob);
    };

    useEffect(() => {
        if (app.openJob) {
            setReviewJob(true);
            setReviewOnly(true);


            // assign data
            setJobTitle(app.openJob.title);
            if (app.openJob.description) {
                setJobDescription(app.openJob.description)
            }
            setIsAutoApply(app.openJob.isAutoMatch);
            setWorkType(app.openJob.preferredWorkType);
            setWorkPlace(app.openJob.preferredWorkPlace);
            setWorkLevels(app.openJob.experienceLevel);
            setSalary(app.openJob.isCompetitiveCompensation);
            setVal(app.openJob.contacts);
            setApplicants(app.openJob.applicants);
            setJobApplicationPage(app.openJob.applicantPageUrl);
            setShowClickedLocationId(app.openJob.cityId);
            setJobTags(app.openJob.tags);
            setAddress(app.openJob.address);
            setLink(app.openJob.links);

            dispatch(openJobAction(null));

        }
    }, [app.openJob]);


    const goBackOnReview = () => {
        setReviewJob(!reviewJob);
        setPayJob(!payJob);
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
    }, [address]);


    function getUsers() {
        axios.get("me").then((res) => {
            setUser(res.data.data);
            setName(res.data.data.name);
            setAbout(res.data.data.about);
            setSelectedPath(res.data.data.logo);
            setImageURLs(res.data.data.images.map((i) => i.imagePath));
            // setLink(res.data.data.companyUrl);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleInactive = (e) => {
        e.preventDefault();


        const fineLinks = [];
        links.forEach((link) => {
            if (link.url !== "" && link.url.trim !== "") {
                fineLinks.push(link.url);
            }
        });

        const fineContacts = [];
        val.forEach((contact) => {
            if (contact.email !== "" && contact.email.trim !== "") {
                fineContacts.push(contact.email);
            }
        });

        const nowUid = `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
        setUid(nowUid);

        const data = {
            title: jobTitle,
            isActive: 0,
            uid: nowUid,
            isAutoMatch: isAutoApply,
            // isCompetitiveCompensation: isCompetitiveCompensation,
            preferredWorkType: workType,
            preferredWorkPlace: workPlace,
            experienceLevel: workLevels,
            isCompetitiveCompensation: salary,
            description: jobDescription,
            contacts: fineContacts,
            applicantPageUrl: jobApplicationPage,
            links: fineLinks,
            cityId: !showCityId ? valueS?.id : showCityId,
            tags: jobTags,
            address: address
        };

        axios
            .post("job", data)
            .then((res) => {
                window.location.reload(false);
            })
            .catch((res) => {
                alert(res.response.data.errorMessage);
            });
    };

    const handleReview = (e) => {
        e.preventDefault();

        itemList = {
            title: jobTitle,
            isActive: 1,
            isAutoMatch: isAutoApply,
            // isCompetitiveCompensation: isCompetitiveCompensation,
            preferredWorkType: workType,
            preferredWorkPlace: workPlace,
            experienceLevel: workLevels,
            isCompetitiveCompensation: salary,
            description: jobDescription,
            contacts: val,
            applicantPageUrl: jobApplicationPage,
            links: links,
            tags: jobTags,
            address: address
        };

        console.log(itemList);

        // localStorage.setItem("mydata", JSON.stringify(itemList));
        dispatch(saveJob(itemList));

        setOpenFilter(!filter);
        setReviewJob(!reviewJob);
        setReviewOnly(false);
    };

    const handleSubmitPreview = (e) => {
        setPaymentLoading(true);
        e.preventDefault();



        const fineLinks = [];
        links.forEach((link) => {
            if (link.url !== "" && link.url.trim !== "") {
                fineLinks.push(link.url);
            }
        });

        const fineContacts = [];
        val.forEach((contact) => {
            if (contact.email !== "" && contact.email.trim !== "") {
                fineContacts.push(contact.email);
            }
        });

        const nowUid = `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
        setUid(nowUid);

        const data = {
            title: jobTitle,
            isActive: 1,
            uid: nowUid,
            isAutoMatch: isAutoApply,
            preferredWorkType: workType,
            preferredWorkPlace: workPlace,
            experienceLevel: workLevels,
            isCompetitiveCompensation: salary,
            description: jobDescription,
            contacts: fineContacts,
            applicantPageUrl: jobApplicationPage,
            links: fineLinks,
            cityId: showClickedLocationId,
            tags: jobTags,
            address: address
        };

        console.log(data);

        localStorage.setItem("jobData", JSON.stringify(data));

        // setReviewJob(!reviewJob);

        axios.get("count/job").then((res) => {
            console.log(res);
            if (res.data.data.count == 0) {
                data.isActive = 0;
                //
                axios
                    .post("job", data)
                    .then((jobRes) => {
                        axios.get("/intent/start/3/" + jobRes.data.data.uid).then((res) => {

                            if (res.data.url) {
                                window.location.href = res.data.url;
                            }
                        });
                    }).catch((err) => {
                    setPaymentLoading(false);
                })
                    .catch((err) => {
                        setPaymentLoading(false);
                        alert(res.response.data.errorMessage);
                    });

            } else {
                axios
                    .post("job", data)
                    .then((res) => {
                        window.location.reload(false);
                    })
                    .catch((err) => {
                        alert(res.response.data.errorMessage);
                        setPaymentLoading(false);
                    });
            }
        });
    };


    useEffect(() => {
        const jobData = JSON.parse(localStorage.getItem("jobData"));
        if (jobData) {
            setJobData(jobData);
        }

        console.log();
    }, []);

    const handlePaySubmit = (e) => {
        e.preventDefault();

        const data = {
            title: jobData.title,
            isActive: 0,
            isAutoMatch: jobData.isAutoMatch,
            preferredWorkType: jobData.preferredWorkType,
            preferredWorkPlace: jobData.preferredWorkPlace,
            experienceLevel: jobData.experienceLevel,
            isCompetitiveCompensation: jobData.isCompetitiveCompensation,
            description: jobData.description,
            contacts: jobData.contacts,
            applicantPageUrl: jobData.applicantPageUrl,
            links: jobData.links,
            cityId: jobData.cityId,
        };

        axios
            .get("/payment/start")
            .then((res) => {
                console.log("poslato");
                // window.location.reload(false);
            })
            .catch((err) => {
                console.log(err);
            });

    };

    const openApplicant = (app) => {
        dispatch(updateUserProfile(app));
    }

    return (
        <div className="view">
            <div className="jobs">
                <TabContext value={value}>
                    <div className="content jobsContent">
                        {/* Add job */}
                        {transitionsFilter(
                            (styles, item) =>
                                item && (
                                    <animated.div
                                        style={styles}
                                        className="FilterPopupBackground"
                                        onClick={() => setOpenFilter(false)}
                                    ></animated.div>
                                )
                        )}

                        {filterTransitions(
                            (styles, item) =>
                                item && (
                                    <animated.div style={styles} className="FilterPopupContent">
                                        <div className="FilterPopupHeader">
                                            {tagsMode ?
                                                <h3 style={{display: "flex"}}>
                                                    <div className="backPopUp">
                                                        <img
                                                            src={back}
                                                            alt="back"
                                                            onClick={() => setTagsMode(false)}
                                                        />
                                                    </div>
                                                    Add match tags</h3>
                                                :
                                                <h3>Post a new job</h3>
                                            }

                                            {!tagsMode ?
                                                <div className="closePopup">
                                                    <img
                                                        src={close}
                                                        alt="close"
                                                        onClick={() => setOpenFilter(false)}
                                                    />
                                                </div>
                                                : null}
                                        </div>
                                        {tagsMode ?

                                            <div className="filterPopupInside">
                                                <Autocomplete
                                                    onChange={(event, newValue) => {
                                                        selectedTag(newValue);
                                                    }}
                                                    key={"tags_ " + jobTags.count}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(option) => option.name}
                                                    options={extraTags}
                                                    sx={{width: '100%'}}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value.name
                                                    }
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.id}>
                                                            {option.name}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Search"
                                                                   onChange={(e) => setExtraTagsText(e.target.value)}/>
                                                    )}
                                                />

                                                <div className={"otherTags"}>
                                                    <div className="tags">
                                                        {tags.map((tag) => (
                                                            <div key={tag.id} className="style">
                                                                <input
                                                                    type="checkbox"
                                                                    id={tag.id}
                                                                    name="workType"
                                                                    value={tag.name}
                                                                    className="chk-btn workTypeButton"
                                                                    checked={isTagChecked(tag)}
                                                                    onChange={handleTags}

                                                                />
                                                                <label
                                                                    htmlFor={tag.id}
                                                                    id={tag.id}
                                                                    key={tag.id}
                                                                    className="chk-btn workTypeButton"
                                                                >
                                                                    {" "}
                                                                    {tag.name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>


                                            :
                                            <div className="filterPopupInside">
                                                <form onSubmit={handleSubmit}>
                                                    <TextField
                                                        label="Job title"
                                                        value={jobTitle}
                                                        id="outlined-required"
                                                        defaultValue={jobTitle}
                                                        required={true}
                                                        onChange={(e) => setJobTitle(e.target.value)}
                                                    />

                                                    <Autocomplete
                                                        onChange={(event, newValue) => {
                                                            setValues(newValue);
                                                        }}
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        getOptionLabel={(option) => option.name}
                                                        options={city}
                                                        value={valueS}
                                                        sx={{width: 300}}
                                                        isOptionEqualToValue={(option, value) =>
                                                            option.name === valueS.name
                                                        }
                                                        renderOption={(props, option) => (
                                                            <li
                                                                {...props}
                                                                key={option.id}
                                                                onChange={() =>
                                                                    showClickedList(option.name, option.id)
                                                                }
                                                            >
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                onChange={showText}
                                                                required={true}
                                                                {...params}
                                                                label={showCity ? showCity : "Location"}
                                                            />
                                                        )}
                                                    />
                                                    <TextField
                                                        label="Address"
                                                        value={address}
                                                        style={{marginTop: "20px"}}
                                                        defaultValue={address}
                                                        inputProps={{maxLength: 500}}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />

                                                    <div className="workType">
                                                        <h5>Work type</h5>
                                                        <div className="content">
                                                            {allWorkTypes?.map((tag) => (
                                                                <div className="style">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={handleWorkType}
                                                                        checked={isWorkTypeChecked(tag.id)}
                                                                        id={tag.id}
                                                                        name="workType"
                                                                        value={tag.name}
                                                                        className="chk-btn"
                                                                    />
                                                                    <label
                                                                        htmlFor={tag.id}
                                                                        key={tag.id}
                                                                        className="chk-btn"
                                                                    >
                                                                        {" "}
                                                                        {tag.name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="workPlace">
                                                        <h5>Work place</h5>
                                                        <div className="content">
                                                            {allWorkPlaces?.map((tag) => (
                                                                <div className="style">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={tag.id}
                                                                        value={tag.name}
                                                                        onChange={handleWorkPlace}
                                                                        name="workPlace"
                                                                        checked={isWorkPlaceChecked(tag.id)}
                                                                        className="chk-btn"
                                                                    />
                                                                    <label
                                                                        htmlFor={tag.id}
                                                                        key={tag.id}
                                                                        className="chk-btn"
                                                                    >
                                                                        {tag.name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="level">
                                                        <h5>Level</h5>
                                                        {allLevels?.map((tag) => (
                                                            <div className="levelStyle">
                                                                <label key={tag.id}>
                                                                    <div className="style">
                                                                        {tag.name}
                                                                        <span>{tag.description}</span>
                                                                    </div>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={tag.id}
                                                                        value={tag.name}
                                                                        onChange={handleLevels}
                                                                        checked={isLevelsChecked(tag.id)}
                                                                        name="workPlace"
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="salaryCompetitive">
                                                        <h5>Competitive salary</h5>
                                                        <label>
                                                            Competitive salary
                                                            <input
                                                                type="checkbox"
                                                                name="salary"
                                                                onChange={() => setSalary((salary) => !salary)}
                                                                // onChange={handleSalary}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className="jobDescription">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={{
                                                                toolbar: [
                                                                    "heading",
                                                                    "|",
                                                                    "bold",
                                                                    "italic",
                                                                    "bulletedList",
                                                                ],
                                                                height: 500
                                                            }}
                                                            data={jobDescription}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setJobDescription(data);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="contactPerson">
                                                        <div className="title">
                                                            <h5>Contact person</h5>
                                                            <button onClick={handleAdd}>+ Add new</button>
                                                        </div>
                                                        {val?.map((data, i) => (
                                                            <div>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    initialValue={data.email}
                                                                    onChange={(e) => handleContact(e, i)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="jobApplicationPage">
                                                        <h5>Job application page</h5>
                                                        <TextField
                                                            value={jobApplicationPage}
                                                            id="outlined-required"
                                                            onChange={(e) =>
                                                                setJobApplicationPage(e.target.value)
                                                            }
                                                        />
                                                    </div>

                                                    <div className="links">
                                                        <div className="title">
                                                            <h5>Links</h5>
                                                            <button onClick={handleAddLinks}>+ Add new</button>
                                                        </div>

                                                        {links?.map((data, index) => (
                                                            <div>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    defaultValue={data.url}
                                                                    onChange={(e) => handleLinks(e, index)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="salaryCompetitive">
                                                        <h5>Auto match</h5>
                                                        <label>
                                                            Auto match (70%)
                                                            <input
                                                                type="checkbox"
                                                                name="autoApply"
                                                                // onChange={handleApply}
                                                                onChange={() =>
                                                                    setIsAutoApply((isAutoApply) => !isAutoApply)
                                                                }
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="otherTags">
                                                        <h6>Your match tags</h6>
                                                        <div className="tags">
                                                            {tags.map((tag) => (
                                                                <div key={tag.id} className="style">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={tag.id}
                                                                        name="workType"
                                                                        value={tag.name}
                                                                        className="chk-btn workTypeButton"
                                                                        checked={isTagChecked(tag)}
                                                                        onChange={handleTags}

                                                                    />
                                                                    <label
                                                                        htmlFor={tag.id}
                                                                        id={tag.id}
                                                                        key={tag.id}
                                                                        className="chk-btn workTypeButton"
                                                                    >
                                                                        {" "}
                                                                        {tag.name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className={"addMatches"} onClick={() => setTagsMode(true)}>
                                                            + Add match tags
                                                        </div>
                                                    </div>


                                                    {free ? <p className="adCost">
                                                        One active job is included in your subscription plan.
                                                    </p> : <p className="adCost">
                                                        The ad will cost <span>1499 SEK</span> per month.
                                                        <br/>
                                                        (All prices are exclusive of applicable taxes.)
                                                    </p>}
                                                    <button
                                                        className="reviewPublich"
                                                        onClick={handleReview}
                                                    >
                                                        Review & publish
                                                    </button>


                                                    <button
                                                        onClick={handleInactive}
                                                        className="inactiveJob"
                                                    >
                                                        Save as inactive
                                                    </button>
                                                </form>
                                            </div>
                                        }
                                    </animated.div>
                                )
                        )}
                        {/* End add job */}

                        {/* Preview job */}
                        {transitionsJobReview(
                            (styles, item) =>
                                item && (
                                    <animated.div
                                        style={styles}
                                        className="FilterPopupBackground"
                                        onClick={() => setReviewJob(false)}
                                    ></animated.div>
                                )
                        )}

                        {filterTransitionsReview(
                            (styles, item) =>
                                item && (
                                    <animated.div style={styles} className="FilterPopupContent">
                                        <div className="FilterPopupHeader">
                                            <h3>
                                                <img src={back} alt="back" onClick={goBack}/>
                                                Review job ad
                                            </h3>
                                        </div>
                                        <div className="filterPopupInside">
                                            <form onSubmit={handleSubmit}>
                                                <div className="jobPreview">
                                                    <div className={"UserProfileImageContainer"}>
                                                        <div className="UserProfileImage" {...handlers} >
                                                            {imageURLs?.length === 0 ?
                                                                <img className={"UserProfileImageOnly"}
                                                                     src={noImage}/> : null}
                                                            {imageURLs?.map((image, index) => (
                                                                <img className="UserProfileImageOnly"
                                                                     key={index} src={baseUrl + image}/>
                                                            ))}

                                                        </div>
                                                        <div className={"UserProfileImageLines"}>
                                                            {imageURLs.map((image, index) => (
                                                                <div className="UserProfileImageLine"
                                                                     style={topImageIndex === index ? {backgroundColor: 'white'} : {backgroundColor: '#7F7D87'}}/>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="jobPreviewContent">
                                                        <div className="companyLogoName">
                                                            <div className="logo">
                                                                <img
                                                                    className="logo"
                                                                    src={process.env.REACT_APP_BASE_URL + selectedPath}
                                                                />
                                                            </div>
                                                            <div className="name">{name}</div>
                                                        </div>
                                                        <h2>{jobTitle ? jobTitle : "-"}</h2>
                                                        <div className="levels">
                                                            {workLevelsNext ? (
                                                                <div className="workLevelNext">
                                                                    {workLevelsNext?.map((wl) => (
                                                                        <p>{wl}</p>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                "-"
                                                            )}
                                                            {/* {workLevelsNext ? workLevelsNext : "-"} */}
                                                        </div>
                                                        <div className="location" style={{color: 'white'}}>
                                                            {showClickedLocation} {address}
                                                        </div>
                                                        <div className="workType">
                                                            {workTypeNext ? (
                                                                <div className="workTypesNext">
                                                                    {workTypeNext?.map((wt) => (
                                                                        <p>{wt}</p>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                "-"
                                                            )}
                                                            {/* {workTypeNext ? workTypeNext : "-"} */}
                                                        </div>
                                                        <h3>Job description</h3>
                                                        <div className="jobDesc">
                                                            {parse(jobDescription)}
                                                        </div>
                                                        <h3>About</h3>
                                                        <div className="jobDesc">
                                                            {user.about ? parse(user.about) : null}
                                                        </div>
                                                        <h3>Salary</h3>
                                                        <div className="salary">{salary === 1 ? "Competitive" : "-"}</div>
                                                        <h3>Links</h3>
                                                        <div className="links">
                                                            {links ? (
                                                                <div className="link">
                                                                    {links?.map((l) => (
                                                                        <a href={l} target="_blank">
                                                                            {l.url}
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                "-"
                                                            )}
                                                            {/* {links ? links : "-"} */}
                                                        </div>
                                                        <h3>Job applicant page</h3>
                                                        <div className="jobApplicantPage">
                                                            {jobApplicationPage ? (
                                                                <a
                                                                    className="link"
                                                                    href={jobApplicationPage}
                                                                    target="_blank"
                                                                >
                                                                    {jobApplicationPage}
                                                                </a>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </div>
                                                        <h3>Contact person</h3>
                                                        <div className="contactPerson">
                                                            {val ? (
                                                                <div className="text">
                                                                    {val?.map((v) => (
                                                                        <p>{v.email}</p>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                "-"
                                                            )}

                                                            {/* {val ? val : "-"} */}
                                                        </div>
                                                        <div>
                                                            <h3>Applicants</h3>
                                                            {applicants?.map((applicant) => (
                                                                <div key={applicant.id} className={"jobApplicant matchInfo"} onClick={() => openApplicant(applicant)}>
                                                                    <img
                                                                        className={"avatar-image"}
                                                                        src={
                                                                            applicant?.images?.length > 0
                                                                                ? process.env.REACT_APP_BASE_URL + applicant.images[0].minifiedPath
                                                                                : avatarImage
                                                                        }
                                                                        alt="avatar"
                                                                    />
                                                                    <div className="matchUser" style={{color: "white"}}>
                                                                        <span className={"matchUserName applicantName"}>{applicant.name}</span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {workPlaceNext}
                                                    </div>

                                                </div>

                                                {reviewOnly === false ?
                                                    <div>
                                                        {free ? <p className="adCost">
                                                            One active job is included in your subscription plan.
                                                        </p> : <p className="adCost">
                                                            The ad will cost <span>1499 SEK</span> per month.
                                                            <br/>
                                                            (All prices are exclusive of applicable taxes.)
                                                        </p>}
                                                        <button
                                                            className="reviewPublich"
                                                            type="submit"
                                                            onClick={handleSubmitPreview}
                                                        >
                                                            {paymentLoading ? 'Loading...' : 'Publish' }
                                                        </button>
                                                    </div>
                                                    : null}
                                            </form>
                                        </div>
                                    </animated.div>
                                )
                        )}
                        {/* End preview job */}

                        {/* Pay job */}
                        {/*{transitionsPay(*/}
                        {/*    (styles, item) =>*/}
                        {/*        item && (*/}
                        {/*            <animated.div*/}
                        {/*                style={styles}*/}
                        {/*                className="FilterPopupBackground"*/}
                        {/*                onClick={() => setPayJob(false)}*/}
                        {/*            ></animated.div>*/}
                        {/*        )*/}
                        {/*)}*/}

                        {/*{payTransitions(*/}
                        {/*    (styles, item) =>*/}
                        {/*        item && (*/}
                        {/*            <animated.div style={styles} className="FilterPopupContent">*/}
                        {/*                <div className="FilterPopupHeader">*/}
                        {/*                    <h3>*/}
                        {/*                        <img src={back} alt="back" onClick={goBackOnReview}/>*/}
                        {/*                        Publish your job*/}
                        {/*                    </h3>*/}
                        {/*                </div>*/}
                        {/*                /!*<div className="filterPopupInside">*!/*/}
                        {/*                /!*    <Elements options={options} stripe={stripePromise}>*!/*/}
                        {/*                /!*        <JobForm uid={uid}/>*!/*/}
                        {/*                /!*    </Elements>*!/*/}

                        {/*                /!*    <form onSubmit={handlePaySubmit}></form>*!/*/}
                        {/*                /!*</div>*!/*/}
                        {/*            </animated.div>*/}
                        {/*        )*/}
                        {/*)}*/}
                        {/* End pay job */}

                        <Tabs onChange={handleChange}/>

                        <Search onChange={(e) => searchItems(e.target.value)}/>
                    </div>

                    {searchInput.length > 1 ? (
                        <div className="searchedList">
                            {filteredResults?.map((item) => {
                                return <Card job={item}/>;
                            })}
                        </div>
                    ) : (
                        <Content jobs={jobs}/>
                    )}

                    {/* {jobs.map((job) => (
            <Content job={job} key={job.id} />
          ))} */}
                </TabContext>

                <img
                    className="createJob"
                    src={createJob}
                    alt="createJob"
                    onClick={() => setOpenFilter(!filter)}
                />

            </div>
        </div>
    );
};

export default Jobs;
