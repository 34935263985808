import React from "react";
import "./SignBtns.scss";

const SignUp = ({ onClick }) => {
  return (
    <div className="signUpBtn" onClick={onClick}>
      Sign up
    </div>
  );
};

export default SignUp;
