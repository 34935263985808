import React from "react";

const GoToLogin = () => {
  const GoToText = {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#7F7D87",
    lineHeight: "24px",
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "20px",
  };

  const link = {
    color: "#040015",
    fontWeight: "600",
  };

  return (
    <div style={GoToText}>
      Go back to{" "}
      <a style={link} href="/ ">
        log in page!
      </a>
    </div>
  );
};

export default GoToLogin;
