import React, { useState } from "react";
import "./SavedProfiles.scss";

import Navigation from "../../Navigation";
import List from "../List";
import Search from "../../../../components/Search";

import { useSelector } from "react-redux";

const SavedProfiles = () => {
  const [inputText, setInputText] = useState("");
  const app = useSelector((state) => state.app);

  return (
      <div className="view">
        <div className="matchContent pagePadding">
            <Search setInputText={setInputText} />
          <List input={inputText} app={app} type={"saves"} />
        </div>
      </div>
  );
};

export default SavedProfiles;
