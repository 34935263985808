import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTransition, animated } from "react-spring";
import edit from "../../../../images/edit.svg";
import send from "../../../../images/send.svg";
import tdots from "../../../../images/tdots.svg";
import conversation from "../../../../images/conversation.svg";
import close from "../../../../images/close.svg";
import axios from "../../../../services/https";
import moment from "moment";
import uploadImage from '../../../../images/upload-image.svg';
import CircularProgress from '@mui/material/CircularProgress';
import "./Desktop.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  changeTagAction,
  openChangeTagAction,
  openNewMessageAction,
  saveUserAction,
  updateConversationUser, updateUserProfile
} from "../../../../redux/actions/app";
import  audioEffect  from "../../../../effect.mp3";

const Desktop = forwardRef (({
  page,
  conversations,
  messages,
  setMessages,
  updateMessages,
  search,
  loadMore,
  loadChat,
  isChatLoading,
    refreshMessage,
    getTime
}, ref) => {
  const [open, setOpen] = useState(false);
  const [deleteConversation, setDeleteConversation] = useState(false);
  const [savedMatch, setSavedMatch] = useState(false);
  const [unSave, setUnSave] = useState(false);
  const [passId, setPassId] = useState();
  const [name, setName] = useState("");
  const [typeMessage, setTypeMessage] = useState();
  const [userMessage, setUserMessage] = useState("");
  const [matchPer, setMatchPer] = useState("");
  const [body, setBody] = useState("");
  const [scrollHeight, setScrollHeight] = useState(0);  
  const [me, setMe] = useState([]);
  const [participantLogo, setParticipantLogo] = useState("");
  const [participantMatchStatus, setParticipantMatchStatus] = useState("");
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const audio = new Audio(audioEffect);
  const [participant, setParticipant] = useState(null);

  const textareaRef = useRef(null);
  const listInnerRef = useRef(null);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsDelete = useTransition(deleteConversation, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const deleteTransitions = useTransition(deleteConversation, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsSave = useTransition(savedMatch, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const saveTransitions = useTransition(savedMatch, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsUnSave = useTransition(unSave, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const UnSaveTransitions = useTransition(unSave, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const onScroll = () => {
    if (listInnerRef.current && conversations.length === (page + 1) * 20) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        loadMore();
      }
    }
  };

  const onChatScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (-scrollTop + clientHeight === scrollHeight) {
        loadChat();
      }
    }
  };

  const handleClick = async (
    conversationId,
    type,
    id,
    name,
    matchPercentage,
    logo,
    matchStatus,
    images,
    participant
  ) => {
    setPassId(conversationId);
    setTypeMessage(type);
    setUserMessage(id);
    setName(name);
    setMatchPer(matchPercentage);
    if (images.length > 0) {
      setParticipantLogo(images[0].image_path);
    } else {
      setParticipantLogo(null);
    }
    setParticipantMatchStatus(matchStatus);
    setParticipant(participant);

    if (conversationId) {
      updateMessages(conversationId);
    };
  };

  const handleDelete = () => {
    axios.delete(`/conversation/${passId}`).then((res) => {
      window.location.reload(false);
    });
  };

  const handlePopups = () => {
    setDeleteConversation(!deleteConversation);
    setOpen(!open);
    setSavedMatch(!savedMatch);
    setUnSave(!unSave);
  };

  const handleSaveMatch = async (userMessage, typeMessage) => {
    axios.post("/match/save", {
      id: userMessage,
      type: typeMessage
    }).then(() => {
      setSavedMatch(!savedMatch);
      setOpen(!open);
    });
    dispatch(saveUserAction(1));

  };


  useEffect(() => {
    if (app.changeTag) {
      const index = conversations.findIndex((c) => c.id === app.changeTag.id);

      if (index !== -1) {
        conversations[index].jobPositionTitle = app.changeTag.jobPositionTitle;
        conversations = [...conversations];
      }

      dispatch(changeTagAction(false));

    }
  }, [app.changeTag]);


  const handleDeleteMatch = async (userMessage, typeMessage) => {
    axios.get("/unmatch/" + userMessage).then(() => {
      setUnSave(!unSave);
      setOpen(!open);
    });
    dispatch(saveUserAction(-1));
  };

  const handleMessageSend = (e) => {
    e.preventDefault();

    if (body.length === 0 && body.trim().length === 0) {
      return;
    }

    axios.post("/message", {
      body: body,
      userId: userMessage
    }).then(() => {
      //
    });

    audio.play();

    setMessages(prev => [...prev, {
      body: body,
      conversationId: passId,
      userId: me.id,
      id: Math.random(),
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss')
    }])

    refreshMessage({
          body: body,
          conversationId: passId,
          userId: me.id,
          id: Math.random(),
          createdAt: moment().format('YYYY-MM-DD hh:mm:ss')
        }
    );

    ref?.current.scrollTo({top: -20, behavior: 'smooth'});

    setBody("");

    textareaRef.current.style.borderRadius = '100px';
    const form = document.querySelector('.messageForm');
    form.style.borderRadius = '100px';
    form.style.alignItems = 'center';
    const messageBtn = document.querySelector('.messageBtn');
    messageBtn.style.alignSelf = 'unset';
    textareaRef.current.style.height = '20px';
  };

  const handleMatchStatusColor = () => {
    if (participantMatchStatus.id === 1) {
      return "networking";
    } else if (participantMatchStatus.id === 3) {
      return "openToWork";
    } else {
      return "jobSeeking"
    } 
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleMessageSend(e);
    }
  };

  const searchHandle = (e) => {
    let key = e.target.value || "";

    axios
      .get(`/conversations/${page}?search=${key}`, {
        search: key
      })
      .then((res) => {
        search(res.data.data)
      })
      .catch((res) => {
        console.log(res.response.data.messages);
      });
  };

  const openUser = () => {
    const userForSend = participant;
    userForSend.images = participant.fineImages;
    dispatch(updateUserProfile(userForSend));
  }

  useEffect(() => {
    if (scrollHeight > 42) {
      textareaRef.current.style.borderRadius = '10px';
      const form = document.querySelector('.messageForm');
      form.style.borderRadius = '10px';
      form.style.alignItems = 'unset';
      const messageBtn = document.querySelector('.messageBtn');
      messageBtn.style.alignSelf = 'end';
    }

    if (scrollHeight === 32) {
      textareaRef.current.style.borderRadius = '100px';
      const form = document.querySelector('.messageForm');
      form.style.borderRadius = '100px';
      form.style.alignItems = 'center';
      const messageBtn = document.querySelector('.messageBtn');
      messageBtn.style.alignSelf = 'unset';
      textareaRef.current.style.height = '20px'
    }
  }, [scrollHeight])

  useEffect(() => {
    if (app.conversationUser && app.conversationUser.id !== userMessage) {
      axios.get("/conversation/id/" + app.conversationUser.id).then((res) => {
        if (res.data.data.conversationId && passId !== res.data.data.conversationId ) {
          setPassId(res.data.data.conversationId);
          updateMessages(res.data.data.conversationId);
        } else {
          setUserMessage(app.conversationUser.id);
        }
          setTypeMessage(app.conversationUser.type);
          setUserMessage(app.conversationUser.id);
          setName(app.conversationUser.name);
          setMatchPer(app.conversationUser.matchPercentage);
          setParticipantLogo(app.conversationUser.logo);
          setParticipantMatchStatus(app.conversationUser.matchStatus);
          dispatch(updateConversationUser(null))

      });
    }
  }, [app.conversationUser])

  const changeTag = () => {
      dispatch(openChangeTagAction(passId));
      setOpen(false);

  }

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      setMe(me);
    }
  }, []);

  useEffect(() => {
    if (savedMatch === true) {
      setTimeout(() => {
        setSavedMatch(false);
      }, 2000);
    }
    if (unSave === true) {
      setTimeout(() => {
        setUnSave(false);
      }, 2000);
    }
  }, [savedMatch, unSave]);

  return (
    <div className="desktop">
      {/* Delete conversation */}
      {transitionsDelete(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackgroundDelete"
              onClick={handlePopups}
            ></animated.div>
          )
      )}
      {deleteTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="deletePopup">
              <div className="deleteHeader">
                <img
                  src={close}
                  alt="close"
                  onClick={() => setDeleteConversation(false)}
                />
                <h3>Delete conversation</h3>
                <p>Are you sure that you want to do that?</p>
                <div className="btns">
                  <button
                    className="cancel"
                    onClick={() => setDeleteConversation(false)}
                  >
                    Cancel
                  </button>
                  <button className="delete" onClick={handleDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </animated.div>
          )
      )}
      {/* End delete conversations */}

      {/* Save conversation */}
      {transitionsSave(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackgroundDelete"
              onClick={handlePopups}
            ></animated.div>
          )
      )}
      {saveTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="deletePopup">
              <div className="deleteHeader">
                <img
                  src={close}
                  alt="close"
                  onClick={() => setSavedMatch(false)}
                />
                <h3>You have save user!</h3>
                <p>Go to saved matches to see new one!</p>
              </div>
            </animated.div>
          )
      )}
      {/* End save conversations */}

      {/* unSave conversation */}
      {transitionsUnSave(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackgroundDelete"
              onClick={handlePopups}
            ></animated.div>
          )
      )}
      {UnSaveTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="deletePopup">
              <div className="deleteHeader">
                <img src={close} alt="close" onClick={() => setUnSave(false)} />
                <h3>You have deleted match!</h3>
              </div>
            </animated.div>
          )
      )}
      {/* End unSave conversations */}

      <div className="leftSide">
        <div className="header">
          <div className="messagesSearch">
            <input type="text" placeholder="Search" onChange={searchHandle} />
          </div>
          <div className="edit" onClick={() => dispatch(openNewMessageAction(true))}>
            <img src={edit} alt="edit" />
          </div>
        </div>

        <div className="list" onScroll={onScroll} ref={listInnerRef}>
          {conversations?.sort((a,b) => 
            moment(b?.lastMessage?.createdAt).valueOf() -
            moment(a?.lastMessage?.createdAt).valueOf()).map((c, index) => {
            return (
              <div
                key={index}
                className="conversation"
                id={c.lastMessage.conversationId}
                onClick={() =>
                  handleClick(
                    c.lastMessage.conversationId,
                    c.lastMessage.type,
                    c.participant.id,
                    c.participant.name,
                    c.participant.matchPercentage,
                    c.participant.logo,
                    c.participant.matchStatus,
                      c.participant.images,
                      c.participant
                  )
                }
              >
                <div className="info">
                  <div className="image">

                    {
                      !c.participant?.images?.length > 0 ?
                        <div><img alt="no-img" src={uploadImage} /></div>
                      : (
                        <img className={"participant-image"} src={process.env.REACT_APP_BASE_URL + c.participant?.images[0].minified_path} alt="avatar" />
                      )
                    }
                  </div>
                  <div className="data">
                    {c.unreadCount > 0 ? (
                      <h3>
                        <strong>{c.participant?.name}</strong>
                      </h3>
                    ) : (
                      <h3>{c.participant?.name}</h3>
                    )}
                    {c.jobPositionTitle === null ? (
                      ""
                    ) : (
                      <h5 className={"jobPositionTitle"}>About: {c.jobPositionTitle}</h5>
                    )}
                    {c.unreadCount > 0 ? (
                      <p>
                        <strong>{c.lastMessage.body}</strong>
                      </p>
                    ) : (
                      <p>{c.lastMessage.body}</p>
                    )}
                  </div>
                </div>
                <div className="time">
                  {getTime(c.lastMessage.createdAt)}
                </div>
                {c.unreadCount === 0 ? (
                  ""
                ) : (
                  <div className="notification">{c.unreadCount}</div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {!passId && !userMessage ? (
        <div className="chouseConversation">
          <img src={conversation} alt="converstaion" />
          Start chating and help each other.
        </div>
      ) : (
        <div className="rightSide" id={userMessage}>
          {transitions(
            (styles, item) =>
              item && (
                <animated.div
                  style={styles}
                  className="popupBackground"
                  onClick={() => setOpen(false)}
                ></animated.div>
              )
          )}

          {menuTransitions(
            (styles, item) =>
              item && (
                <animated.div style={styles} className="emailPopup">
                  <ul>
                    <li onClick={changeTag}>Change tag/tag job</li>
                    <li
                      onClick={() => handleSaveMatch(userMessage, typeMessage)}
                    >
                      Save match
                    </li>
                    <li
                      onClick={() =>
                        handleDeleteMatch(userMessage, typeMessage)
                      }
                    >
                      Remove match
                    </li>
                    <li onClick={() => setDeleteConversation(true)}>Delete conversation</li>
                  </ul>
                </animated.div>
              )
          )}
          <div className="conversationHeader">
            <div className="leftInfo" onClick={openUser}>
              {
                !participantLogo ? (
                  <div><img alt="no-img" src={uploadImage} /></div>
                ) : (
                  <img src={process.env.REACT_APP_BASE_URL + participantLogo} alt="avatar" className={"participant-image"} />
                )
              }
              <div className="avatarInfo">
                <h2>{name}</h2>
                <div className="tags">
                  <div className="match">{matchPer}% match</div>
                  {
                    participantMatchStatus && (
                      <div className={handleMatchStatusColor()}>{participantMatchStatus?.name}</div>
                    )
                  }
                </div>
              </div>
            </div>

            <div className="rightInfo">
              <img src={tdots} alt="tdots" onClick={() => setOpen(true)} />
            </div>
          </div>

          <div className="messageBody">
            <div className="messages">
              <div className="left" ref={ref} onScroll={onChatScroll}> 
                {isChatLoading ? <div className="loader"><CircularProgress /></div> : messages?.map((m) => {
                    return (
                      <div key={m.id}>
                        {me.id === m.userId ? (
                          <div className="right">
                            {/* {m.body === null ? (
                              <div>Start conversation</div>
                            ) : ( */}
                            <div>
                              <div className="content">
                                {m.body}
                              </div>
                              <div className="time">
                                {getTime(m.createdAt)}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="leftMessages">
                            <div className="content">
                              <p>{m.body}</p>
                            </div>
                            <div className="time">
                              {getTime(m.createdAt)}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                  .reverse()}
              </div>
              {/* <div className="right">
                <div className="content">hi</div>
              </div> */}
            </div>
            <div className="sendMessage">
              <div className="textMessage">
                <form
                  className="messageForm"
                  // ref={(el) => (handleMessageSend = el)}
                  onSubmit={handleMessageSend}
                >
                  <textarea
                    ref={textareaRef}
                    onChange={(e) => {
                      setBody(e.target.value)
                      const target = e.target;
                      if (textareaRef && textareaRef.current) {
                        textareaRef.current.style.height = "20px";
                        textareaRef.current.style.height = `${target.scrollHeight - 12}px`;
                        setScrollHeight(target.scrollHeight);
                      }
                    }}
                    rows="1"
                    value={body}
                    placeholder="Type message here"
                    onKeyDown={onEnterPress}
                  ></textarea>
                  <button className="messageBtn" type="submit">
                    <img src={send} alt="send" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Desktop;
