import React, { useState, useEffect } from "react";
import "../steps/Otp.scss";
import { API } from "../../services/base";
import axios from "axios";

import { useTranslation } from "react-i18next";
import useDigitInput from "react-digit-input";

import SignUpBtn from "../../elements/SignBtn";

const OtpSign = ({ navigation }) => {
  const [email, setEmail] = useState([]);
  const [reveal, setReveal] = useState(false);

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("email"));
    if (email) {
      setEmail(email);
    }
  }, []);

  const revealAnswer = () => {
    setReveal((reveal) => !reveal);
  };

  // const [code, setCode] = useState("");

  const [error, setError] = useState("");

  const { t, i18n } = useTranslation();

  const [value, onChange] = React.useState("");

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const info = {
      email: email,
      code: value,
    };

    axios
      .post(`${API}/auth/reset/password/verify`, info)
      .then((res) => {
        navigation.next();
        localStorage.setItem("code", JSON.stringify(value));
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });
  };

  const handleResetCode = (e) => {
    e.preventDefault();

    const data = {
      email: email,
    };

    axios
      .post(`${API}/auth/reset/password`, data)
      .then((res) => {})
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div className="otp">
      <div className="otpContent">
        <h2>{t("modals.checkInbox")}</h2>
        <p className="enterCodeText">{t("modals.enterCode")}</p>

        <form onSubmit={handleSubmit}>
          <div className="enterCode">
            {/* <input type="email" onChange={(e) => setEmail(e.target.value)} /> */}
            {/* <input type="number" onChange={(e) => setCode(e.target.value)} /> */}

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              hidden
            />

            <input inputMode="decimal" autoFocus {...digits[0]} />
            <input inputMode="decimal" {...digits[1]} />
            <input inputMode="decimal" {...digits[2]} />
            <input inputMode="decimal" {...digits[3]} />
          </div>
          <p className="errorMessage">{error.code}</p>
          <pre>
            <code>"{value}"</code>
          </pre>

          <SignUpBtn title={t("modals.verify")} />
          <p className="resetInfo">
            If you haven't received the code, make sure to check your spam or junk folder.
          </p>
          {/* <button onClick={() => navigation.next()}>next</button> */}
        </form>

        <div className="resendCode">
          <form onSubmit={handleResetCode}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              hidden
            />
            <br />
            <br />

            <button type="submit" onClick={revealAnswer}>
              {t("modals.resend")}
            </button>

          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpSign;
