import React from "react";

import TextField from "@mui/material/TextField";

const InputField = ({
  value,
  label,
  name,
  id,
  placeholder,
  type,
  onChange,
}) => {
  return (
    <div className="input">
      <TextField
        type={type}
        id={id}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        label={label}
        select={false}
      />
    </div>
  );
};

export default InputField;
