import "./style/main.scss";
import React, { useState, useEffect } from "react";
import Main from "./pages/inside/Main";
import Terms from "./pages/inside/Terms/Terms";
import Privacy from "./pages/inside/Privacy/Privacy";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/outside/pages/Dashboard/Dashboard";
import Matches from "./pages/outside/pages/Matches/Matches";
import RightSwipes from "./pages/outside/pages/rightSwipes/RightSwipes";
import SavedProfiles from "./pages/outside/pages/savedProfiles/SavedProfiles";
import Messages from "./pages/outside/pages/Messages/Messages";
import Jobs from "./pages/outside/pages/Jobs/Jobs";
import EditProfile from "./pages/outside/pages/editProfile/EditProfile";
import MainDashboard from "./pages/outside/pages/Main/Main"
import Settings from "./pages/outside/pages/Settings/Settings";
import ChangePassword from "./pages/outside/pages/Settings/ChangePassword/ChangePassword";
import ChangeEmail from "./pages/outside/pages/Settings/ChangeEmail/ChangeEmail";
import DeleteAccount from "./pages/outside/pages/Settings/DeleteAccount/DeleteAccount";
import FeedbackPage from "./pages/outside/pages/Settings/Feedback/FeedbackPage";

import PrivateRoute from "./PrivateRoute";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CheckoutForm from "./CheckoutForm";

import {
  getFirebaseToken,
  onForegroundMessage,
  onBackgroundMessage,
} from "./firebase";
import axios from "axios";
import version from "../package.json";

function App() {

  useEffect(() => {
   console.log("env   ", process.env.REACT_APP_BASE_URL)
  }, []);


  return (
    <div>
      <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main />}></Route>
              <Route path="/terms" element={<Terms />}></Route>
              <Route path="/privacy" element={<Privacy />}></Route>

              <Route element={<PrivateRoute />}>
                <Route path="/" element={<MainDashboard/>}>
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                  <Route path="/match" element={<Matches />}></Route>
                  <Route path="/right-swipes" element={<RightSwipes />}></Route>
                  <Route
                      path="/saved-profiles"
                      element={<SavedProfiles />}
                  ></Route>
                  <Route
                      path="/messages/(id)?/:id?/(page)?/:page?"
                      element={<Messages />}
                  ></Route>
                  <Route path="/my-jobs" element={<Jobs />}></Route>
                  <Route path="/edit-profile" element={<EditProfile />}></Route>
                  <Route path="/settings" element={<Settings />}></Route>
                  <Route
                      path="/change-password"
                      element={<ChangePassword />}
                  ></Route>
                  <Route path="/change-email" element={<ChangeEmail />}></Route>
                  <Route
                      path="/delete-account"
                      element={<DeleteAccount />}
                  ></Route>
                  <Route path="/feedback" element={<FeedbackPage />}></Route>
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
