export const LOGIN = "LOGIN";
export const SAVE_FILTER = "SAVE_FILTER";
export const SAVE_JOB = "SAVE_JOB";
export const OPEN_FILTER = "OPEN_FILTER";
export const SAVE_MANDATORY_INFO = "SAVE_MANDATORY_INFO";
export const UPDATE_CONVERSATION_ID = "UPDATE_CONVERSATION_ID";
export const UPDATE_CONVERSATION_USER = "UPDATE_CONVERSATION_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const USER_CHANGED = "USER_CHANGED";
export const LIKED_USER = "LIKED_USER";
export const SAVED_USER = "SAVED_USER";
export const CHANGED_LOGO = "CHANGED_LOGO";
export const OPEN_NEW_JOB = "OPEN_NEW_JOB";
export const OPENED_USER = "OPENED_USER";
export const MATCHED_USER = "MATCHED_USER";
export const OPEN_NEW_MESSAGE = "OPEN_NEW_MESSAGE";
export const OPEN_CHANGE_TAG = "OPEN_CHANGE_TAG";
export const CHANGED_TAG = "CHANGED_TAG";
export const OPEN_JOB = "OPEN_JOB";