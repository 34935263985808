import React, {useEffect, useState} from "react";
import "./MobileNavigation.scss";

import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {removeUserSession} from "../../../Utils/Common";
import axios from "../../../services/https";

import {animated, useTransition} from "react-spring";

import logo from "../../../images/logo.png";
import dashboard from "../../../images/dashboard.svg";
import matches from "../../../images/matches-menu.svg";
import rightSwipe from "../../../images/right-swipes.svg";
import savedProfile from "../../../images/saved-profile.svg";
import messages from "../../../images/messages.svg";
import myJobs from "../../../images/my-jobs.svg";
import editProfile from "../../../images/edit-profile.svg";
import settings from "../../../images/settings.svg";
import logOut from "../../../images/log-out.svg";

import hamburger from "../../../images/newHamburger.svg";
import close from "../../../images/close.svg";

import version from "../../../../package.json";
import {useDispatch, useSelector} from "react-redux";
import {
  changeLogoAction,
  likeUserAction,
  openFilter, openNewJobAction,
  saveMandatoryInfo,
  saveUserAction
} from "../../../redux/actions/app";
import filter from '../../../images/filterBlack.svg';

const MobileNavigation = () => {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [menuCounters, setMenuCounters] = useState({
    rightSwipes: 0,
    matches: 0,
    saved: 0,
    suggestions: 0,
  });
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

  useEffect(() => {
    if (app.likedUser && menuCounters) {
      menuCounters.rightSwipes += 1;
      dispatch(likeUserAction(null));
    }
  }, [app.likedUser]);

  useEffect(() => {
    if (app.changedLogo) {
      setUser({...user, logo: app.changedLogo});
      dispatch(changeLogoAction(null));

    }
  }, [app.changedLogo]);

  useEffect(() => {
    if (app.savedUser && menuCounters) {
      if (app.savedUser === 1) {
        menuCounters.saved += 1;
      } else {
        if (menuCounters.saved > 0) {
          menuCounters.saved -= 1;
        }
      }
      dispatch(saveUserAction(null));
    }
  }, [app.savedUser]);

  const openDashboard = () => {
    navigate("/dashboard");
  }

  const mandatoryInfo = useSelector(state => state.app.mandatoryInfo);

  const dispatch = useDispatch();

  const [newT, setNewT] = useState();
  const [me, setMe] = useState([]);

  const [gToken, setGToken] = useState(localStorage.getItem("firebaseToken"));
  // const user = getUser();

  useEffect(() => {
    getUsers();
    getMenuCounters();
    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      setMe(me);
    }
  }, []);

  function getUsers() {
    axios.get("/me").then((res) => {
      setUser(res.data.data);
      setName(res.data.data.name);
      dispatch(saveMandatoryInfo({
        images: res.data.data.images,
        name: res.data.data.name,
        cityId: res.data.data.city.id,
        logo: res.data.data.logo
      }))
    });
  }

  function getMenuCounters() {
    axios.get("/menu-counters").then((res) => {
      setMenuCounters(res.data);
    });
  }

  const handleLogout = () => {
    const data = {
      userId: me.id,
      token: gToken,
      model: navigator.product,
      appVersion: version.version,
      type: 3,
    };
    axios.delete("device", data).then((res) => {
      removeUserSession();
      navigate("/");
    });
  };

  // Menu transition
  const transitions = useTransition(openNavigation, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const calculateOffset = () => {
    const width = window.innerWidth;
    const navigationWidth = 300;
    return navigationWidth - width;
  }

  const menuTransitions = useTransition(openNavigation, {
    from: { opacity: 0, transform: "translateX(-" + window.innerWidth + "px)" },
    enter: { opacity: 1, transform: "translateX(" + calculateOffset() + "px" },
    leave: { opacity: 0, transform: "translateX(-" + window.innerWidth + "px)" },
  });

  const location = useLocation();
  const path = location.pathname;
  const [display, setDisplay] = useState(path == "/dashboard" ? true : false);

  const disableNavLink = (e, isMandatory = false) => {
    setOpenNavigation(false);
    getMenuCounters();
    if (isMandatory)
    if (mandatoryInfo?.images?.length === 0 || !mandatoryInfo?.name || !mandatoryInfo?.cityId || !mandatoryInfo?.logo ) {
      e.preventDefault()
    }
  }

  const handlePostJob = (e) => {
    e.preventDefault();
    setOpenNavigation(false);
    dispatch(openNewJobAction(true));
    navigate("/my-jobs");
  };

  return (
    <div className="navigationMobile customPadding">
      <img
        src={hamburger}
        alt="hamburger"
        onClick={() => setOpenNavigation(!openNavigation)}
        className="hamburger"
      />
      <img className="logo" src={logo} alt="logo" onTouchStart={() => openDashboard()} />

      {(display ? (
        <div onClick={() => dispatch(openFilter(true))}><img alt="filter" src={filter} /></div>
      ) : <div></div>)}

      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="menuBackground"
              onClick={() => setOpenNavigation(false)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="mobileContentMenu">
              <div className="mobileMenuHeader">
                <div className="mobileMenu">
                  <h3>Menu</h3>
                </div>
                <div className="closeMenu">
                  <img
                    src={close}
                    alt="close"
                    onClick={() => setOpenNavigation(!openNavigation)}
                  />
                </div>
              </div>

              <ul>
                <div className="avatarName" onTouchStart={() => navigate("/edit-profile")}>
                  <img src={baseUrl + user.logo} alt="avatarImage"  />
                  <h3>{user.name}</h3>
                </div>
                <li>
                  <img src={dashboard} alt="dashboardWhite" />
                  <NavLink onClick={(e) => disableNavLink(e, true)} to="/dashboard">Dashboard</NavLink>
                  {menuCounters?.suggestions > 0 ? (
                    <span className="notifications">
                      {menuCounters?.suggestions}
                    </span>
                  ) : null}
                </li>
                <li>
                  <img src={matches} alt="matchesWhite" />
                  <NavLink onClick={(e) => disableNavLink(e, true)} to="/match">Matches</NavLink>
                  {menuCounters?.matches > 0 ? (
                    <span className="notifications">
                      {menuCounters?.matches}
                    </span>
                  ) : null}
                </li>
                <li>
                  <img src={rightSwipe} alt="rightSwipeWhite" />
                  <NavLink onClick={(e) => disableNavLink(e, true)} to="/right-swipes">Right swipes</NavLink>
                  {menuCounters?.rightSwipes > 0 ? (
                    <span className="notifications">
                      {menuCounters?.rightSwipes}
                    </span>
                  ) : null}
                </li>
                <li>
                  <img src={savedProfile} alt="savedprofileWhite" />
                  <NavLink onClick={(e) => disableNavLink(e, true)} to="/saved-profiles">Saved profiles</NavLink>
                  {menuCounters?.saved > 0 ? (
                    <span className="notifications">{menuCounters?.saved}</span>
                  ) : null}
                </li>
                <li>
                  <img src={messages} alt="messagesWhite" />
                  <NavLink onClick={(e) => disableNavLink(e, true)} to="/messages">Messages</NavLink>
                </li>
                <li>
                  <img src={myJobs} alt="myJobsWhite" />
                  <NavLink onClick={(e) => disableNavLink(e, true)} to="/my-jobs">My jobs</NavLink>
                </li>
                <li>
                  <img src={editProfile} alt="edtiProfileWhite" />
                  <NavLink to="/edit-profile" onClick={disableNavLink} >Edit profile</NavLink>
                </li>
                <li>
                  <img src={settings} alt="settingsWhite" />
                  <NavLink to="/settings" onClick={disableNavLink} >Settings</NavLink>
                </li>
              </ul>
              <div className="logOut" onClick={handleLogout}>
                <img src={logOut} alt="logOutWhite" />
                <a href="/">Log out</a>
              </div>
              <div className="postJob" onClick={handlePostJob}>
                <a href="/">Post a new job</a>
              </div>
            </animated.div>
          )
      )}
    </div>
  );
};

export default MobileNavigation;
