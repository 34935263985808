import React, { useEffect, useState, useCallback } from "react";
import Carousel from "react-multi-carousel";
import axios from "../../../../services/https";
import "react-multi-carousel/lib/styles.css";
import "./Dashboard.scss";
import uploadImage from "../../../../images/upload-image.svg";
import {updateUserProfile} from "../../../../redux/actions/app";
import {useDispatch} from "react-redux";

const Matches = () => {
  const [page, setPage] = useState(0);
  const [matches, setMatches] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [end, setEnd] = useState(false);
  const dispatch = useDispatch();

  const responsive = {
    Largedesktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 11.5,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1100 },
      items: 7.5,
    },
    tablet: {
      breakpoint: { max: 1100, min: 464 },
      items: 5.5,
    },
    mobile: {
      breakpoint: { max: 464, min: 350 },
      items: 4.5,
    },
    mobilemini: {
      breakpoint: { max: 350, min: 0 },
      items: 3.5,
    },
  };

  const getMatches = useCallback(
    async () => {
      const matchesData = await axios.get(`matches/${page}`)
      setMatches(prev => [...prev, ...matchesData?.data?.data])
    },
    [page],
  )


  const openUser = (user) => {
    dispatch(updateUserProfile(user));
  }

  const handleRes = useCallback(
    () => {
      if ( matches.length >= 20 && end === false ) {
        let res = 0;
  
        if (window.innerWidth <= 3000 && window.innerWidth > 1400) {
          res = 12 + imageIndex;
        }
  
        if (window.innerWidth <= 1400 && window.innerWidth > 1100) {
          res = 8 + imageIndex;
        }
  
        if (window.innerWidth <= 1100 && window.innerWidth > 768) {
          res = 6 + imageIndex;
        }

        if (res === (page + 1) * 16) {
          setPage(page + 1)
        }
  
        if (matches.length === res) {
          setImageIndex(0);
          setEnd(true);
        }
      }
    },
    [end, imageIndex, matches.length, page],
  )

  useEffect(() => {
    handleRes()
  }, [handleRes])

  useEffect(() => {
    getMatches()
  }, [getMatches]);

  return (
    <div className="matches">
      <Carousel
        responsive={responsive}
        draggable={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        infinite={true}
        ssr={true}
        afterChange={() => {
          if (matches.length >= 20 && end === false) {
            setImageIndex(imageIndex + 1);
          }
        }}
      >
        {matches.length ? matches.map((item, key) => {
          return (
            <div className="avatar-icon" key={key} onClick={() => openUser(item)}>
              <div>
                {
                  item?.images?.length ? (
                    <img
                      className={"avatar-image"}
                      src={process.env.REACT_APP_BASE_URL + item?.images[0].imagePath}
                      alt="avatar"
                    />
                  ) : <div><img alt="no-img" src={uploadImage} /></div>
                }
                
                <h5>{item.name}</h5>
              </div>
            </div>
          );
        }) :  (<div style={{ height: 52, width: '100%' }} />)}
      </Carousel>

    </div>
  );
};

export default Matches;
