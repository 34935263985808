import React, { useState, useEffect } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import logo from "../../../images/logo.svg";
import message from "../../../images/message.svg";
import phone from "../../../images/phone.svg";
import appStore from "../../../images/appstore.svg";
import googlePlay from "../../../images/googleplay.png";
import facebook from "../../../images/facebook.svg";
import instagram from "../../../images/instagram.svg";
import twitter from "../../../images/twitter.svg";
import linkedin from "../../../images/linkedin.svg";
import tiktok from "../../../images/tiktok.svg";


const Footer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="footerFull">
      <div className="footerCopyright">
        <div className="footer">
          <div className="about">
            <img src={logo} alt="logo" />
            <p>{t("footer.description")}</p>
          </div>
          <div className="product">
            <h4>Product</h4>
            <ul>
              <li>
                Linker Business <span>Website</span>
              </li>
              <li>
                Linker Personal <span>Application</span>
              </li>
            </ul>
          </div>
          <div className="contactAndLegacy">
            <div className="footerContact">
              <h4>Contact</h4>
              <ul>
                <li>
                  <img src={message} alt="message" />
                  hello@linker.se
                </li>
                <li>
                  <img src={phone} alt="phone" />
                  (46) 763-212488
                </li>
              </ul>
            </div>
            <div className="legalPolicy">
              <h4>Legal & Policy</h4>
              <ul>
                <li>
                  <a href="/terms" target="_blank">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="icons">
          <div className="phone">
            <a href="https://apps.apple.com/rs/app/linker/id1671716248" target="_blank">
              <img src={appStore} alt="appStore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=se.linker" target="_blank">
              <img src={googlePlay} alt="googlePlay" />
            </a>
          </div>
          <div className="social">
            <a href="https://www.facebook.com/linker.se" target="_blank">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/linker.se" target="_blank">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://www.tiktok.com/@linker.se"  target="_blank">
              <div className={"tikTokIcon"}>
              <img src={tiktok} alt="tiktok" className={"tikTokIconImage"}/>
              </div>
            </a>
            <a href="https://www.linkedin.com/company/linker-ab/" target="_blank">
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="copyright">{t("footer.copyright")}</div>
      </div>
    </div>
  );
};

export default Footer;


