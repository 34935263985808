import React from "react";
import "../Terms/Terms.scss";
import Nav from "../Navigation/Nav";
import Footer from "../Footer/Footer";


const Privacy = () => {

  return (
    <div>
      <Nav />
      <iframe src={"https://prod.linker.se/business-privacy"} style={{width:"100vw", height: "calc(100vh - 106px)", border: 0}} />
      <Footer />
    </div>
  );
};

export default Privacy;
