import React, {useEffect, useState} from "react";
import "./Card.scss";

import {useTransition, animated} from "react-spring";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Autocomplete from "@mui/material/Autocomplete";

import company from "../../../../../images/company-logo.svg";
import dot from "../../../../../images/dots.svg";
import deleteAcc from "../../../../../images/delete.svg";
import close from "../../../../../images/close.svg";
import avatarImage from "../../../../../images/upload-image.svg";
import back from "../../../../../images/ic_back.svg";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {TailSpin} from "react-loader-spinner";
import payment from "../../../../../images/jobPay.png";
import ExtendPayment from "../ExtendPayment";
import {openJobAction} from "../../../../../redux/actions/app";
import {useDispatch} from "react-redux";

const Card = ({job}) => {
    const [options, setOptions] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [editJob, setEditJob] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [listWorkTypes, setListWorktypes] = useState([]);
    const [listWorkPlaces, setListWorkPlaces] = useState([]);
    const [listLevels, setListLevels] = useState([]);

    const [user, setUser] = useState("");
    const [selectedPath, setSelectedPath] = useState("");
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [title, setJobTitle] = useState("");
    const [isActive, setIsActive] = useState("0");
    const [isAutoApply, setIsAutoApply] = useState(0);
    const [allWorkTypes, setWorkTypes] = useState([]);
    const [allWorkPlaces, setWorkPlaces] = useState([]);
    const [allLevels, setLevels] = useState([]);
    const [jobApplicationPage, setJobApplicationPage] = useState("");
    const [salary, setSalary] = useState("");
    const [val, setVal] = useState([]);
    const [jobDescription, setJobDescription] = useState("");
    const [links, setLink] = useState([]);

    // Edit job states
    const [changeTitle, setChangeTitle] = useState();
    const [changeJobDescription, setChangeJobDescription] = useState("");
    const [changeJobApplicationPage, setChangeJobApplicationPage] = useState("");
    const [workType, setWorkType] = useState([]);
    const [workPlace, setWorkPlace] = useState([]);
    const [level, setLevel] = useState([]);

    const [text, setText] = useState("");
    const [search, setSearch] = useState([]);
    const [city, setCity] = useState([]);
    const [value, setValue] = useState();
    const [showCity, setShowCity] = useState("");
    const [showCityId, setShowCityId] = useState("");
    const [tagsMode, setTagsMode] = useState(false);
    const [extraTags, setExtraTags] = useState();
    const [extraTagsText, setExtraTagsText] = useState("");
    const [tags, setTags] = useState([]);
    const [jobTags, setJobTags] = useState([]);
    const [extendJob, setExtendJob] = useState(false);
    const stripePromise = loadStripe(`${process.env.REACT_APP_STAGING}`);
    const [type, setType] = useState(null);
    const [extendedJob, setExtendedJob] = useState(null);
    const dispatch = useDispatch();
    const [address, setAddress] = useState("");
    const [extendLoading, setExtendLoading] = useState(false);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get(`/city?city=${search}`).then((res) => {
                setCity(res.data.data);
            });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    useEffect(() => {
        getUsers();
        stripeClientSecret();
    }, []);

    function getUsers() {
        axios.get("me").then((res) => {
            setSelectedPath(res.data.data.logo);
        });
    }

    const showText = (e) => {
        setSearch(e.target.value);
    };

    const showClickedList = () => {
    };

    // Options
    const transitionsOptions = useTransition(options, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const filterOptions = useTransition(options, {
        from: {opacity: 0, transform: "translateX(100%)"},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: "translateX(100%)"},
    });

    // Edit job
    const transitionsEdit = useTransition(editJob, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });
    const filterEdit = useTransition(editJob, {
        from: {opacity: 0, transform: "translateX(100%)"},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: "translateX(100%)"},
    });

    const transitionExtend = useTransition(extendJob, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const filterExtend = useTransition(extendJob, {
        from: {opacity: 0, transform: "translateX(100%)"},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: "translateX(100%)"},
    });

    // Delete confirm
    const transitionsDelete = useTransition(confirmDelete, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const filterDelete = useTransition(confirmDelete, {
        from: {opacity: 0, transform: "translateX(100%)"},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: "translateX(100%)"},
    });

    const date = new Date(job.createdAt);
    const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get(`/tag?tag=${extraTagsText}`).then((res) => {
                setExtraTags(res.data.data);
            });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [extraTagsText]);

    const handleWorkType = (e) => {
        const id = parseInt(e.target.id);
        const value = e.target.value;
        const checked = e.target.checked;

        const found = workType.includes(id);

        if (checked && !found) {
            setWorkType([...workType, id]);
        } else {
            setWorkType(workType.filter((e) => e !== id));
        }
    };

    const isTagChecked = (tag) => {
        const index = jobTags.findIndex((c) => c == tag.id);
        return index !== -1;
    }

    const handleTags = (e) => {
        const id = e.target.id;

        let updatedTags = jobTags;
        const index = updatedTags.findIndex((c) => c == id);

        if (index === -1) {
            updatedTags.push(id);
        } else {
            updatedTags.splice(index, 1);
        }


        setJobTags([...updatedTags]);

    }

    const handleWorkPlaces = (e) => {
        const id = parseInt(e.target.id);
        const value = e.target.value;
        const checked = e.target.checked;

        const found = workPlace.includes(id);

        if (checked && !found) {
            setWorkPlace([...workPlace, id]);
        } else {
            setWorkPlace(workPlace.filter((e) => e !== id));
        }
    };

    const handleLevels = (e) => {
        const id = parseInt(e.target.id);
        const value = e.target.value;
        const checked = e.target.checked;

        const found = level.includes(id);

        if (checked && !found) {
            setLevel([...level, id]);
        } else {
            setLevel(level.filter((e) => e !== id));
        }
    };


    // const handleSalary = (e) => {
    //   const checked = e.target.checked;

    //   console.log(checked);

    //   if (checked) {
    //     setSalary(true);
    //   } else {
    //     setSalary(!false);
    //   }
    // };

    // const handleApply = (e) => {
    //   const checked = e.target.checked;

    //   console.log(checked);

    //   if (checked) {
    //     setIsAutoApply(true);
    //   } else {
    //     setIsAutoApply(!false);
    //   }
    // };

    const handleAdd = (e) => {
        e.preventDefault();
        const abc = [...val, {email: ''}];
        setVal(abc);
    };

    const handleContact = (onChangeValue, index) => {
        val[index].email = onChangeValue.target.value;
        setVal(val);
    };

    const handleAddLinks = (e) => {
        e.preventDefault();
        const link = [...links, {url: ""}];
        setLink(link);
    };

    const handleLinkChange = (onChangeValue, index) => {
        links[index].url = onChangeValue.target.value;
        setLink(links);
    };

    const extendJobAction = (e, job) => {
e.preventDefault();
        setExtendLoading(true);
        axios.get("count/job").then((res) => {
            if (res.data.data.count == 0) {
                axios.get("/intent/start/3/" + job.uid).then((res) => {
                  if (res.data.url) {
                      window.location.href = res.data.url;
                  }
                });
            } else {
                const data = {
                    id: job.id,
                    uid: job.uid
                };
                axios
                    .post("republish", data)
                    .then((res) => {
                        window.location.reload(false);
                    });
            }
        });

    }

    const openJob = (e, job) => {
        if (!e.target.classList.contains('dot')) {
            setOptions(false);
            dispatch(openJobAction(job));
        }
    }

    const edtiPopup = () => {
        setOptions(false);
        setEditJob(!editJob);

        axios
            .get(`job/${job.id}`)
            .then((res) => {
                setJobs(res.data.data);
                setVal(res.data.data.contacts);
                setWorkTypes(res.data.data.preferredWorkTypes);
                setWorkPlaces(res.data.data.preferredWorkPlaces);
                setLevels(res.data.data.experienceLevel);
                setLink(res.data.data.links);
                setShowCity(res.data.data.city?.name);
                setShowCityId(res.data.data.city?.id);
                setValue(res.data.data.city);
                setJobTags(res.data.data.tags.map(t => t.id));
                setTags(res.data.data.tags);
                setJobDescription(res.data.data.description);
                setAddress(res.data.data.address);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get("app-data")
            .then((res) => {
                setListWorktypes(res.data.data.tagCategories[1].tags);
                setListWorkPlaces(res.data.data.tagCategories[2].tags);
                setListLevels(res.data.data.tagCategories[3].tags);
            })
            .catch((res) => {
                console.log(res);
            });

        // () => setEditJob(!editJob);
    };

    const selectedTag = (value) => {

        if (value) {
            const tagIndex = tags.findIndex(t => t.id === value.id);

            if (tagIndex === -1) {
                tags.push(value);
            }

            setTags([...tags]);

            let updatedTags = jobTags;
            const index = updatedTags.findIndex((c) => c === value.id);

            if (index === -1) {
                updatedTags.push(value.id);
            } else {
                updatedTags.splice(index, 1);
            }

            setJobTags([...updatedTags]);
        }
    }


    const jobDelete = () => {
        axios
            .delete(`job/${job.id}`)
            .then((res) => {
                setConfirmDelete(false);
                window.location.reload(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const handleE = (e) => {
        e.preventDefault();

        const fineLinks = [];
        links.forEach((link) => {
            if (link.url !== "" && link.url.trim !== "") {
                fineLinks.push(link);
            }
        });

        const fineContacts = [];
        val.forEach((contact) => {
            if (contact.email !== "" && contact.email.trim !== "") {
                fineContacts.push(contact);
            }
        });

        const data = {
            title: changeTitle,
            isActive: isActive,
            isAutoMatch: isAutoApply,
            description: jobDescription,
            applicantPageUrl: changeJobApplicationPage,
            preferredWorkType: workType,
            preferredWorkPlace: workPlace,
            experienceLevel: level,
            isCompetitiveCompensation: salary,
            contacts: fineContacts,
            tags: jobTags,
            cityId: value ? value.id : null,
            address: address,
            links: fineLinks
        };

        axios
            .put(`job/${job.id}`, data)
            .then((res) => {
                window.location.reload(false);
            })
            .catch((res) => {
                alert(res.response.data.errorMessage);
            });
    };

    useEffect(() => {
        if (allWorkTypes) {
            setWorkType(allWorkTypes.map(t => t.id));
        }
    }, [allWorkTypes]);

    useEffect(() => {
        if (allWorkPlaces) {
            setWorkPlace(allWorkPlaces.map(t => t.id));
        }
    }, [allWorkPlaces]);

    useEffect(() => {
        if (allLevels) {
            setLevel(allLevels.map(t => t.id));
        }
    }, [allLevels]);

    const stripeClientSecret = () => {
        axios.get("/intent/start").then((res) => {
            setType(res.data.data.intent.client_secret);
        });
    };


    const stripeOptions = {
        clientSecret: `${type}`,
    };

    return (
        <div>
            {/* Delete job */}
            {transitionsDelete(
                (styles, item) =>
                    item && (
                        <animated.div
                            style={styles}
                            className="deletePopupBackground"
                            onClick={() => setConfirmDelete(false)}
                        ></animated.div>
                    )
            )}

            {filterDelete(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="deleteConfirm">
                            <div className="deleteHeader">
                                <img src={deleteAcc} alt="delete"/>
                                <h3>Delete job</h3>
                                <p>Are you sure you want to delete job?</p>
                                <div className="chooseOptio">
                                    <div className="delete" onClick={jobDelete}>
                                        Delete
                                    </div>
                                    <div
                                        className="cancel"
                                        onClick={() => setConfirmDelete(false)}
                                    >
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )
            )}
            {/* End delete job */}

            {/* Edit job */}
            {transitionsEdit(
                (styles, item) =>
                    item && (
                        <animated.div
                            style={styles}
                            className="EditPopupBackground"
                            onClick={() => setEditJob(false)}
                        ></animated.div>
                    )
            )}
            {filterEdit(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="EditPopupContent">
                            <div className="EditPopupHeader">
                                {tagsMode ?
                                    <h3 style={{display: "flex"}}>
                                        <div className="backPopUp">
                                            <img
                                                src={back}
                                                alt="back"
                                                onClick={() => setTagsMode(false)}
                                            />
                                        </div>
                                        Add match tags</h3>
                                    :
                                    <h3>Edit job</h3>
                                }

                                {!tagsMode ?
                                    <div className="closePopup">
                                        <img
                                            src={close}
                                            alt="close"
                                            onClick={() => setEditJob(false)}
                                        />
                                    </div>
                                    : null}
                            </div>
                            {!tagsMode ?
                                <div className="editPopupInside">
                                    <form className="editJobForm" onSubmit={handleE}>
                                        <TextField
                                            label="Job title"
                                            id="outlined-required"
                                            required={true}
                                            defaultValue={job.title}
                                            onChange={(e) => setChangeTitle(e.target.value)}
                                        />

                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            disablePortal
                                            id="combo-box-demo"
                                            getOptionLabel={(option) => option.name}
                                            options={city}
                                            sx={{width: 300}}
                                            isOptionEqualToValue={(option, value) =>
                                                option.name === value.name
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id} onChange={showClickedList}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    onChange={showText}
                                                    required={true}
                                                    {...params}
                                                    label={showCity ? showCity : "Location"}
                                                />
                                            )}
                                        />

                                        <TextField
                                            label="Address"
                                            value={address}
                                            id="outlined-required"
                                            style={{marginTop: "20px"}}
                                            required={true}
                                            defaultValue={address}
                                            inputProps={{maxLength: 500}}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />

                                        <div className="workType">
                                            <h5>Work type *</h5>
                                            <div className="content">
                                                {listWorkTypes.map((item) => {
                                                    return (
                                                        <div className="style">
                                                            <input
                                                                type="checkbox"
                                                                onChange={handleWorkType}
                                                                id={item.id}
                                                                name="workType"
                                                                checked={workType.includes(item.id)}
                                                                className="chk-btn"
                                                            />
                                                            <label
                                                                htmlFor={item.id}
                                                                key={item.id}
                                                                className="chk-btn"
                                                            >
                                                                {" "}
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="workType">
                                            <h5>Work place</h5>
                                            <div className="content">
                                                {listWorkPlaces.map((item) => {
                                                    return (
                                                        <div className="style">
                                                            <input
                                                                type="checkbox"
                                                                onChange={handleWorkPlaces}
                                                                id={item.id}
                                                                name="workPlace"
                                                                checked={workPlace.includes(item.id)}
                                                                className="chk-btn"
                                                            />
                                                            <label
                                                                htmlFor={item.id}
                                                                key={item.id}
                                                                className="chk-btn"
                                                            >
                                                                {" "}
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="level">
                                            <h5>Level *</h5>
                                            {listLevels.map((tag) => (
                                                <div className="levelStyle">
                                                    <label key={tag.id}>
                                                        <div className="style">
                                                            {tag.name}
                                                            <span>{tag.description}</span>
                                                        </div>
                                                        <input
                                                            type="checkbox"
                                                            id={tag.id}
                                                            value={tag.name}
                                                            onChange={handleLevels}
                                                            checked={level.includes(tag.id)}
                                                            name="level"
                                                        />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="salaryCompetitive">
                                            <h5>Competitive salary</h5>
                                            <label>
                                                Competitive salary
                                                {job.isCompetitiveCompensation == true ? (
                                                    <input type="checkbox" name="salary" defaultChecked/>
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        name="salary"
                                                        onChange={() => setSalary((salary) => !salary)}
                                                    />
                                                )}
                                            </label>
                                        </div>

                                        <div className="jobDescription">
                                            {/* <input
                      type="text"
                      onChange={(e) => setChangeJobDescription(e.target.value)}
                      defaultValue={job.description}
                    /> */}
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "bulletedList",
                                                    ],
                                                    height: 500
                                                }}
                                                data={job.description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setJobDescription(data);
                                                }}
                                            />
                                        </div>

                                        <div className="contactPerson">
                                            <div className="title">
                                                <h5>Contact person</h5>
                                                <button onClick={handleAdd}>+ Add new</button>
                                            </div>
                                            {/* {val.map((data, i) => (
                      <div>
                        <TextField
                          id="outlined-required"
                          value={data.email}
                          // onChange={(e) => handleContact(e, i)}
                        />
                      </div>
                    ))} */}

                                            {val.map((item, i) => {
                                                return (
                                                    <div>
                                                        <TextField
                                                            key={item.id}
                                                            id="outlined-required"
                                                            defaultValue={item.email}
                                                            onChange={(e) => handleContact(e, i)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            {val.email}
                                            <div className="content"></div>
                                        </div>

                                        <div className="jobApplicationPage">
                                            <h5>Job application page</h5>
                                            <TextField
                                                defaultValue={job.applicantPageUrl}
                                                id="outlined-required"
                                                placeholder={"Please insert url in format: https://..."}
                                                onChange={(e) =>
                                                    setChangeJobApplicationPage(e.target.value)
                                                }
                                            />
                                        </div>

                                        <div className="links">
                                            <div className="title">
                                                <h5>Links</h5>
                                                <button onClick={handleAddLinks}>+ Add new</button>
                                            </div>
                                            {links.map((item, index) => {
                                                return (
                                                    <div>
                                                        <TextField
                                                            key={item.id}
                                                            id="outlined-required"
                                                            defaultValue={item.url}
                                                            onChange={(e) => handleLinkChange(e, index)}

                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="salaryCompetitive">
                                            <h5>Auto match</h5>
                                            <label>
                                                Auto match (70%)
                                                {job.isAutoApply == true ? (
                                                    <input
                                                        type="checkbox"
                                                        name="autoApply"
                                                        defaultChecked
                                                    />
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        name="autoApply"
                                                        // onChange={handleApply}
                                                        onChange={() =>
                                                            setIsAutoApply((isAutoApply) => !isAutoApply)
                                                        }
                                                    />
                                                )}
                                            </label>
                                        </div>
                                        <div className="otherTags">
                                            <h6>Your match tags</h6>
                                            <div className="tags">
                                                {tags.map((tag) => (
                                                    <div key={tag.id} className="style">
                                                        <input
                                                            type="checkbox"
                                                            id={tag.id}
                                                            name="workType"
                                                            value={tag.name}
                                                            className="chk-btn workTypeButton"
                                                            checked={isTagChecked(tag)}
                                                            onChange={handleTags}

                                                        />
                                                        <label
                                                            htmlFor={tag.id}
                                                            id={tag.id}
                                                            key={tag.id}
                                                            className="chk-btn workTypeButton"
                                                        >
                                                            {" "}
                                                            {tag.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={"addMatches"} onClick={() => setTagsMode(true)}>
                                                + Add match tags
                                            </div>
                                        </div>


                                        <button className="editJobBtn" type="submit">
                                            Save changes
                                        </button>
                                    </form>
                                </div> :
                                <div className="editPopupInside">
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            selectedTag(newValue);
                                        }}
                                        key={"tags_ " + jobTags.count}
                                        disablePortal
                                        id="combo-box-demo"
                                        getOptionLabel={(option) => option.name}
                                        options={extraTags}
                                        sx={{width: '100%'}}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Search"
                                                       onChange={(e) => setExtraTagsText(e.target.value)}/>
                                        )}
                                    />

                                    <div className={"otherTags"}>
                                        <div className="tags">
                                            {tags.map((tag) => (
                                                <div key={tag.id} className="style">
                                                    <input
                                                        type="checkbox"
                                                        id={tag.id}
                                                        name="workType"
                                                        value={tag.name}
                                                        className="chk-btn workTypeButton"
                                                        checked={isTagChecked(tag)}
                                                        onChange={handleTags}

                                                    />
                                                    <label
                                                        htmlFor={tag.id}
                                                        id={tag.id}
                                                        key={tag.id}
                                                        className="chk-btn workTypeButton"
                                                    >
                                                        {" "}
                                                        {tag.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>

                            }
                        </animated.div>
                    )
            )}
            {/* End edit job */}

            {/* Extend job */}
            {transitionExtend(
                (styles, item) =>
                    item && (
                        <animated.div
                            style={styles}
                            className="EditPopupBackground"
                            onClick={() => setExtendJob(false)}
                        ></animated.div>
                    )
            )}
            {filterExtend(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="EditPopupContent">
                            <div className={"EditPopupHeader"}>
                                <h3 style={{display: "flex"}}>
                                    <div className="backPopUp">
                                        <img
                                            src={back}
                                            alt="back"
                                            onClick={() => setExtendJob(false)}
                                            style={{marginRight: "10px"}}
                                        />
                                    </div>
                                    {extendedJob.isActive ? "Extend job" : "Activate job"}
                                </h3>
                            </div>
                            <div style={{padding: "20px"}}>
                                <Elements options={stripeOptions} stripe={stripePromise}>
                                    <ExtendPayment jobId={job.id} uid={job.uid}/>
                                </Elements>
                            </div>
                        </animated.div>
                    )
            )}
            {/* End edit job */}

            <div className="cards">
                <div className="cardStyle" key={job.id} onClick={(e) => openJob(e, job)}>
                    {transitionsOptions(
                        (styles, item) =>
                            item && (
                                <animated.div
                                    style={styles}
                                    onClick={() => setOptions(false)}
                                ></animated.div>
                            )
                    )}

                    {filterOptions(
                        (styles, item) =>
                            item && (
                                <animated.div
                                    style={styles}
                                    className="optionContent"
                                    // onClick={() => setOptions(false)}
                                >
                                    <div className="options dot">
                                        <ul className={"dot"}>
                                            <li onClick={edtiPopup} className={"dot"}>Edit</li>
                                            {!job.isActive ?
                                                <li onClick={($e) => extendJobAction($e, job)}  className={"dot"}>
                                                    {extendLoading ? "Loading..." : "Activate" }
                                                </li>
                                                : null}
                                            <li  className={"dot"} onClick={() => {setConfirmDelete(!confirmDelete); setOptions(false)}}>
                                                Remove
                                            </li>
                                        </ul>
                                    </div>
                                </animated.div>
                            )
                    )}

                    {!user.logo ? (
                        <img src={baseUrl + selectedPath} alt="avatarImage" className="companyLogo"/>
                    ) : (
                        <img src={company} alt="avatarImage" className="companyLogo"/>
                    )}
                    {/* <img src={selectedPath} alt="avatarImage" className="companyLogo" /> */}

                    {/* <img className="companyLogo" src={company} alt="company" /> */}
                    <div className="cardInfo">
                        <img
                            className="dot"
                            src={dot}
                            alt="dot"
                            id={"dot"}
                            onClick={() => setOptions(!options)}
                        />
                        {job.isActive == true ? (
                            <h5 className="activeJob">
                                <span></span>active job
                            </h5>
                        ) : (
                            <h5 className="inactiveJob">
                                <span></span>inactive job
                            </h5>
                        )}
                        <h2>{job.title}</h2>
                        <div className="applicantsDate">
                            <p>{job.preferredWorkTypes[0]?.name} | {job.applicantCount} applicants</p>
                            <span>{formattedDate}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
