import React from "react";
import AccountSetting from "./AccountSettings/AccountSetting";
import Feedback from "./Feedback/Feedback";
import PreferenceSettings from "./PreferenceSettings/PreferenceSettings";

const Settings = () => {
  return (
    <div className="view">
      <div className="settingContent pagePadding">
        <AccountSetting />
        <PreferenceSettings />
        <Feedback />
      </div>
    </div>
  );
};

export default Settings;
