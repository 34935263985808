import close from "../../../../images/close.svg";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {animated} from "react-spring";
import React, {useEffect, useState} from "react";
import axios from "../../../../services/https";
import {openUserAction} from "../../../../redux/actions/app";
import {useDispatch} from "react-redux";

const Report = ({setOpen, user}) => {
    const [reports, setReports] = useState([]);
    const [reason, setReason] = useState("");
    const [selectedRadio, setSelectedRadio] = useState(null);
    const dispatch = useDispatch();
    const [realUser, setRealUser] = useState(null);

    useEffect(() => {
        axios.get("app-data").then((res) => {
            if (res) {
                setReports(res.data.data.reportReason);
            }
        })

        dispatch(openUserAction(null));

    }, []);

    useEffect(() => {
       if (user) {
           setRealUser(user);
       }
    }, [user]);


    const handleReport = async () => {
        const data = {
            user: realUser,
            reportReason: selectedRadio,
            reason: reason
        };

        await axios.post(`report`, data);
        setOpen(false);
    };


    return (
        <div className="reportHeader">
            <img src={close} alt="close" onClick={() => setOpen(false)} onTouchStart={() => setOpen(false)}/>
            <h3>Report user</h3>
            <p>Why do you want to report this user?</p>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                >
                    {reports.map((r) => {
                        return (
                            <FormControlLabel
                                id={r.id}
                                key={r.id}
                                value={r.title}
                                control={<Radio/>}
                                label={r.title}
                                onClick={() => setSelectedRadio(r)}
                                onTouchStart={() => setSelectedRadio(r)}
                            />
                        );
                    })}
                </RadioGroup>
                {selectedRadio?.id === 3 ?
                    <textarea name={"reason"} style={{resize: "none", fontFamily: "inherit", padding: "10px", borderRadius: '4px', height: "100px"}} value={reason}
                              onChange={(e) => setReason(e.target.value)}/>

                    :  <textarea name={"reason"} style={{resize: "none", fontFamily: "inherit", padding: "10px", borderRadius: '4px', height: "100px", opacity: 0, cursor: "default"}} value={reason}
                                 onChange={(e) => setReason(e.target.value)}/>

                }


                <div className="reportBtns">
                    <button className="close" onClick={() => setOpen(false)} onTouchStart={() => setOpen(false)}>
                        Cancel
                    </button>
                    <button disabled={!selectedRadio} className="report" style={{opacity: !selectedRadio && 0.5}}
                            onClick={handleReport} onTouchStart={handleReport}>
                        Report
                    </button>
                </div>
            </FormControl>
        </div>

    )
}
export default Report;