import * as types from "../types/actionTypes";

export const saveFilter = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_FILTER,
      payload: data,
    });
  };
};

export const saveJob = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_JOB,
      payload: data,
    });
  };
};

export const openFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_FILTER,
      payload,
    });
  };
};

export const updateConversationId = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CONVERSATION_ID,
      payload,
    });
  };
};

export const updateConversationUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CONVERSATION_USER,
      payload,
    });
  };
};

export const saveMandatoryInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_MANDATORY_INFO,
      payload,
    });
  };
};

export const updateUserProfile = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_USER_PROFILE,
      payload,
    });
  };
};

  export const changeUserAction = (payload) => {
    return (dispatch) => {
      dispatch({
        type: types.USER_CHANGED,
        payload,
      });
    };
};

export const likeUserAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.LIKED_USER,
      payload,
    });
  };
};

export const saveUserAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVED_USER,
      payload,
    });
  };
};

export const changeLogoAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.CHANGED_LOGO,
      payload,
    });
  };
};


export const openNewJobAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_NEW_JOB,
      payload,
    });
  };
};


export const openUserAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.OPENED_USER,
      payload,
    });
  };
};

export const matchedUserAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.MATCHED_USER,
      payload,
    });
  };
};


export const openNewMessageAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_NEW_MESSAGE,
      payload,
    });
  };
};

export const openChangeTagAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_CHANGE_TAG,
      payload,
    });
  };
};

export const changeTagAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.CHANGED_TAG,
      payload,
    });
  };
};


export const openJobAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_JOB,
      payload,
    });
  };
};