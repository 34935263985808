import * as types from "../types/actionTypes";

const initialState = {
  appData: null,
  isLoggedIn: false,
  filter: null,
  jobs: null,
  openFilters: false,
  conversationUser: null,
  conversationId: null,
  userProfileData: null,
  userChanged: null,
  likedUser: null,
  savedUser: null,
  changedLogo: null,
  openNewJob: null,
  openedUser: null,
  matchedUser: null,
  openNewMessage: null,
  openChangeTag: null,
  changeTag: null,
  openJob: null,
  mandatoryInfo: {
    images: [],
    name: "",
    cityId: 0,
    logo: ""
  },
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case types.SAVE_JOB:
      return {
        ...state,
        jobs: action.payload,
      };
    case types.OPEN_FILTER:
      return {
        ...state,
        openFilters: action.payload,
      };
    case types.SAVE_MANDATORY_INFO:
      return {
        ...state,
        mandatoryInfo: action.payload,
      };
    case types.UPDATE_CONVERSATION_ID:
      return {
        ...state,
        conversationId: action.payload,
      };
    case types.UPDATE_CONVERSATION_USER:
      return {
        ...state,
        conversationUser: action.payload,
      };
    case types.UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case types.UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case types.USER_CHANGED:
      return {
        ...state,
        userChanged: action.payload,
      };
    case types.LIKED_USER:
      return {
        ...state,
        likedUser: action.payload,
      };
    case types.SAVED_USER:
      return {
        ...state,
        savedUser: action.payload,
      };
    case types.CHANGED_LOGO:
      return {
        ...state,
        changedLogo: action.payload,
      };
    case types.OPEN_NEW_JOB:
      return {
        ...state,
        openNewJob: action.payload,
      };

    case types.OPENED_USER:
      return {
        ...state,
        openedUser: action.payload,
      };

    case types.MATCHED_USER:
      return {
        ...state,
        matchedUser: action.payload,
      };

    case types.OPEN_NEW_MESSAGE:
      return {
        ...state,
        openNewMessage: action.payload,
      };
    case types.OPEN_CHANGE_TAG:
      return {
        ...state,
        openChangeTag: action.payload,
      };
    case types.CHANGED_TAG:
      return {
        ...state,
        changeTag: action.payload,
      };
    case types.OPEN_JOB:
      return {
        ...state,
        openJob: action.payload,
      };

    default:
      return state;
  }
};

export default appReducer;
