import React, {useState, useEffect} from "react";
import {useTransition, animated} from "react-spring";
import SignUp from "../../auth/SignUp/SignUp";
import "./Services.scss";

import {useTranslation} from "react-i18next";

import Match from "../../../images/match.svg";
import Chat from "../../../images/chat.svg";
import Work from "../../../images/work.svg";
import AppStore from "../../../images/appstore.svg";
import GooglePlay from "../../../images/googleplay.png";
import close from "../../../images/close.svg";

const Services = () => {
    const [signUpPopup, setsignUpPopup] = useState(false);
    const {t} = useTranslation();

    // Sign up popup
    const transitionsSignUp = useTransition(signUpPopup, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const signUpPopupTransitions = useTransition(signUpPopup, {
        from: {opacity: 0, transform: "translateY(-100%)"},
        enter: {opacity: 1, transform: "translateY(0%)"},
        leave: {opacity: 0, transform: "translateY(-100%)"},
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="services customPadding">
            <span className={"anchorChangeService"} id={"our-services"}/>
            {/* Sign up popup */}
            {transitionsSignUp(
                (styles, item) =>
                    item && (
                        <animated.div
                            style={styles}
                            className="signInPopupBackground"
                            onClick={() => setsignUpPopup()}
                        ></animated.div>
                    )
            )}

            {signUpPopupTransitions(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="signInPopupContent">
                            <div className="signUpPopupHeader">
                                <div className="closePopup">
                                    <img
                                        src={close}
                                        alt="close"
                                        onClick={() => setsignUpPopup(!signUpPopup)}
                                    />
                                </div>
                            </div>

                            <SignUp/>
                        </animated.div>
                    )
            )}
            {/* End Sign up popup */}
            <div className="steps">
                <div className="chat">
                    <img src={Match} alt="Match"/>
                    <p>{t("ourServices.step1")}</p>
                </div>
                <div className="chat">
                    <img src={Chat} alt="Chat"/>
                    <p>{t("ourServices.step2")}</p>
                </div>
                <div className="work">
                    <img src={Work} alt="Work"/>
                    <p>{t("ourServices.step3")}</p>
                </div>
            </div>

            <div className="cards">
                {/* <img className="line3" src={Line3} alt="line3" /> */}
                <div className="jobSeeker">
                    <h3>
                        <span>{t("ourServices.jobSeeker")}</span> <br/>
                        {t("ourServices.findJob")}
                    </h3>
                    <p>
                        {t("ourServices.findJobDesc")} <a href="/">App Store</a> or{" "}
                        <a href="/">Google Play</a>.
                    </p>
                    <div className="btnsl">
                        <a href="https://apps.apple.com/rs/app/linker/id1671716248" target="_blank">
                            <img src={AppStore} alt="AppStore" className={"storeButton"}/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=se.linker" target="_blank">
                            <img src={GooglePlay} alt="GooglePlay" className={"storeButton"}/>
                        </a>
                    </div>
                </div>
                <div className="Employer">
                    <h3>
                        <span>{t("ourServices.employer")}</span> <br/>
                        {t("ourServices.buildTeam")}
                    </h3>
                    <p>{t("ourServices.employerDesc")}</p>
                    <div className="btnsApply">
                        <button
                            className="getStarted"
                            onClick={() => setsignUpPopup(!signUpPopup)}
                        >
                            Get started
                        </button>
                    </div>
                </div>
                {/* <img className="line4" src={Line4} alt="line4" /> */}
            </div>
        </div>
    );
};

export default Services;
