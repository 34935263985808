import {useDispatch} from "react-redux";
import {matchedUserAction, saveUserAction, updateConversationUser} from "../../../../redux/actions/app";
import {useEffect, useState} from "react";
import "./Matched.scss"
import axios from "../../../../services/https";
import {useNavigate} from "react-router-dom";
import defaultImage from '../../../../images/upload-image.svg';



const Matched = ({app}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [leftStyle, setLeftStyle] = useState({});
    const [rightStyle, setRightStyle] = useState({});
    const [saved, setSaved] = useState(false);
    const myUser = JSON.parse(localStorage.getItem('user'));


    useEffect(() => {
        setTimeout(() => {
            if (document.documentElement.clientWidth <= 992) {
                setLeftStyle({left: "calc(50% - 155px)"});
                setRightStyle({right: "calc(50% - 155px)"});
            } else {
                setLeftStyle({left: "95px"});
                setRightStyle({right: "95px"});
            }
        }, 350);
    }, []);




    const handleClosed = () => {
        dispatch(matchedUserAction(null));
    }

    const handleSendMessage = () => {
        handleClosed();
        setTimeout(() => {
            dispatch(updateConversationUser(app.matchedUser));
            navigate("/messages");
        }, 250);
    }

    const handleSave = () => {
        if (!saved) {
            const data = {
                id: app.matchedUser.id,
                type: 1,
            };

            axios.post("match/save", data);
            dispatch(saveUserAction(1));
            setSaved(true);
        }


    }

    if (app.matchedUser) {
        return (
            <div className={"matchedContainer"}>
                <div className={"matchedTitle"}>It's a match!</div>
                <div className={"matchedImagesContainer"}>
                    <img className={"matchedImage"}
                         src={baseUrl + myUser.logo ? myUser.logo : myUser.images[0].imagePath} style={leftStyle}/>
                    <img className={"matchedImageSecond"} src={app.matchedUser.images.length > 0 ? baseUrl + app.matchedUser.images[0].imagePath : defaultImage}
                         style={rightStyle}/>
                </div>
                <div className={"matchedSendMessage"} onClick={handleSendMessage}>Send message</div>
                <div className={"matchedKeepSwiping"} onClick={handleClosed}>Keep swiping</div>
                <div className={"matchedSaveProfile"} onClick={handleSave}>Save profile</div>
            </div>
        )
    }
}

export default Matched;
