import React, {useEffect, useState} from "react";
import Nav from "./Navigation/Nav";
import Baner from "./Baner/Baner";
import Services from "./Services/Services";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import {useLocation} from "react-router-dom";

const Main = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change
  return (
    <div>
      <Nav />
      <Baner />
      <Services />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;
