import React, { useState, useEffect } from "react";
import Navigation from "../../../Navigation";
import "./Feedback.scss";

import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { useTransition, animated } from "react-spring";
import axios from "../../../../../services/https";

import sucess from "../../../../../images/success.svg";

const FeedbackPage = () => {
  const [stars, setStars] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  });

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 200,
  });

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      rate: stars,
      comment: comment,
    };

    axios
      .post("/feedback", data)
      .then((res) => {
        setOpen(!open);
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });

  };

  return (
    <div className="view">
      <div className="feedbackPage pagePadding">
        {transitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setOpen(false)}
              ></animated.div>
            )
        )}

        {menuTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="emailPopup">
                <div className="emailHeader">
                  <img src={sucess} alt="sucess" />
                  <h3>Thank you for the feedback</h3>
                  <p>
                    Go to your new email to confirm changes. Follow
                    instructions, some description, some description.{" "}
                  </p>
                </div>
              </animated.div>
            )
        )}
        <h2>Give us feedback</h2>

        <h5>Rate us</h5>
        <form onSubmit={handleSubmit}>
          <div className="rate">
            <Rating
              name="simple-controlled"
              value={stars}
              onChange={(e) => setStars(e.target.value)}
            />
            {!error ? "" : <p className="errorMessage">{error.rate}</p>}
          </div>

          {!error ? (
            <TextField
              id="outlined-multiline-static"
              label="Comment"
              multiline
              rows={5}
              variant="outlined"
              onChange={(e) => setComment(e.target.value)}
            />
          ) : (
            <TextField
              error
              id="outlined-error"
              label="Comment"
              value={comment}
              multiline
              rows={5}
              variant="outlined"
              helperText={error.comment}
              onChange={(e) => setComment(e.target.value)}
            />
          )}

          <button className="sendFeedbackBtn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackPage;
