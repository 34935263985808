import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ForgotPassword.scss";

import { API } from "../../services/base";
import axios from "axios";

import SignUpBtn from "../../elements/SignBtn";
import InputField from "../../components/InputField";
import TextField from "@mui/material/TextField";
import GoToLogin from "../steps/GoToLogin";
import OtpSign from "./OtpSign";

const ForgotPassword = ({ navigation }) => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: email,
    };

    axios
      .post(`${API}/auth/reset/password`, data)
      .then((res) => {
        navigation.next();
        localStorage.setItem("email", JSON.stringify(email));
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });
  };

  return (
    <div className="forgotPassword">
      <div className="forgotPasswordContent">
        <h2>{t("modals.forgotPasswordTitle")}</h2>
        <p className="enterCodeText">{t("modals.forgotPasswordDesc")}</p>
        <form onSubmit={handleSubmit}>
          <div className="enterCode">
            {!error ? (
              <InputField
                label="Email address"
                type="email"
                name="forgotPasswordEmail"
                id="forgotPasswordEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!error}
                helperText={error && error.email}
              />
            ) : (
              <TextField
                error
                id="outlined-error-helper-text"
                label="Email address"
                type="email"
                defaultValue={email}
                value={email}
                helperText={error.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
          </div>

          <SignUpBtn title="Send email" />
          {/* <button onClick={() => navigation.next()}>next</button> */}
        </form>

        {/* <p className="resendCode">
          <a href="/">Resend email</a>
        </p> */}

        <GoToLogin />
      </div>
    </div>
  );
};

export default ForgotPassword;
