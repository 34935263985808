import axios from "axios";
import { getToken } from "../Utils/Common";
import { API } from "./base";
import { Navigate } from "react-router-dom";

axios.defaults.baseURL = API;
// axios.defaults.headers.common.IS_PERSONAL_ACCOUNT = 2;

axios.interceptors.request.use(async (instance) => {
  const token = await getToken("token");
  if (token) {
    // eslint-disable-next-line no-param-reassign
    instance.headers.Authorization = `Bearer ${token}`;
  }

  // instance.headers.IS_PERSONAL_ACCOUNT = 2;
  instance.headers["x-is-personal-account"] = 0;

  return instance;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      <Navigate to="/" />;
    }
    return Promise.reject(error);
  }
);

export default axios;
