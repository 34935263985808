import React, {useEffect, useState} from 'react'
import './PreferenceSettings.scss'
import '../Settings.scss'

import Switch from '@mui/material/Switch'
import axios from "../../../../../services/https";

const PreferenceSettings = () => {
  const [profile, setProfile] = React.useState()
  const [location, setLocation] = React.useState()

  const handleProfile = (event) => {
    setProfile(event.target.checked)
    updateSettings(event.target.checked, location);

  }

  const handleLocation = (event) => {
    setLocation(event.target.checked)
    updateSettings(profile, event.target.checked);
  }

  const updateSettings = (profileChange, locationChange) => {
    const data = {profile: profileChange, location: locationChange};
    axios.post("settings", data);

  }

  useEffect(() => {
    axios.get("settings").then((res) => {
      setProfile(res.data.data.profile);
      setLocation(res.data.data.location);
    });
  }, []);

  return (
    <div className="preferenceSettings">
      <h2>Preference settings</h2>

      <div className="settings">
        <div className="switch">
          Show my profile
          <Switch
            checked={profile}
            onChange={handleProfile}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <div className="switch">
          Show my location
          <Switch
            checked={location}
            onChange={handleLocation}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
      </div>
    </div>
  )
}

export default PreferenceSettings
