import React from "react";
import "./About.scss";
import { useTranslation } from "react-i18next";

import Quote from "../../../images/quote.svg";
import QuoteAngle from "../../../images/quote-angle.svg";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="aboutUs customPadding">
      <span className={"anchorChange"} id="about-us"/>
      <div className="quote">
        <img className="quoteImage1" src={Quote} alt="Quote" />
        <p>{t("aboutUs.quote")}</p>
        <img className="quoteImage2" src={QuoteAngle} alt="QuoteAngle" />
      </div>
      <div className="story">
        <h1>
          {t("aboutUs.storyTitle")} <span>{t("aboutUs.storyTitle2")}</span>
        </h1>
        <div className="quote_mobile">
          <img className="quoteImage1" src={Quote} alt="Quote" />
          <p>{t("aboutUs.quote")}</p>
          <img className="quoteImage2" src={QuoteAngle} alt="QuoteAngle" />
        </div>
        <p>
          {t("aboutUs.storyDescription1")}
          <br />
          <br />
          {t("aboutUs.storyDescription2")}
          <br />
          <br />
          {t("aboutUs.storyDescription3")}
          {/* <a href="/">{t("aboutUs.learnMore")}</a> */}
        </p>
      </div>
    </div>
  );
};

export default About;
