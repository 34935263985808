import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./NewPassword.scss";
import { API } from "../../services/base";
import axios from "axios";

import SignUpBtn from "../../elements/SignBtn";
import eye from "../../images/eye.svg";

import TextField from "@mui/material/TextField";

const NewPassword = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const [isShown, setIsSHown] = useState(false);
  const [isShownRepeat, setIsSHownRepeat] = useState(false);

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const togglePasswordRepeat = () => {
    setIsSHownRepeat((isShownRepeat) => !isShownRepeat);
  };

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("email"));
    if (email) {
      setEmail(email);
    }
    const code = JSON.parse(localStorage.getItem("code"));
    if (code) {
      setCode(code);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
      repeatPassword: repeatPassword,
      code: code,
    };

    axios
      .post(`${API}/auth/reset/password/change`, data)
      .then((res) => {
        navigation.go(0);
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });
  };

  return (
    <div className="forgotPassword">
      <div className="forgotPasswordContent">
        <h2>{t("modals.newPassword")}</h2>

        <form onSubmit={handleSubmit}>
          <div className="enterCode">
            <input type="email" value={email} hidden />
            <input type="number" value={code} hidden />

            <div className="passwordField">
              <TextField
                label="Password"
                type={isShown ? "text" : "password"}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                error={error.password}
                helperText={error.password}
              />

              <input
                id="checkbox"
                type="checkbox"
                checked={isShown}
                onChange={togglePassword}
              />

              <label htmlFor="checkbox">
                <img className="passwordEye" src={eye} alt="eye" />
              </label>
            </div>

            <div className="passwordFieldNew" >
              <TextField
                label="Repeat password"
                type={isShownRepeat ? "text" : "password"}
                name="new password"
                onChange={(e) => setRepeatPassword(e.target.value)}
                error={error.repeatPassword}
                helperText={error.repeatPassword}
              />

              <input
                id="checkboxRepeat"
                type="checkbox"
                checked={isShownRepeat}
                onChange={togglePasswordRepeat}
              />

              <label htmlFor="checkboxRepeat">
                <img className="passwordEye" src={eye} alt="eye" />
              </label>
            </div>
          </div>

          <SignUpBtn title="Go to linker" />
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
