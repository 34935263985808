import React, { useState, useEffect } from "react";
import Data from "../../../matchesData.json";
import "./Matches/Matches.scss";
import axios from "../../../services/https";

import avatarImage from '../../../images/upload-image.svg';
import message from "../../../images/messages.svg";

import { useNavigate } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {useDispatch} from "react-redux";
import {changeUserAction, saveUserAction, updateConversationUser, updateUserProfile} from "../../../redux/actions/app";

const List = ({ input, app, type }) => {
  const [page, setPage] = useState(0);
  const [allUsers, setAllUsers] = useState([]);

  const [checked, setChecked] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stopNewData, setStopNewData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasSearch, setHasSearch] = useState(false);


  useEffect(() => {
    inputChangedAction().then((isSearchable) => {
      setPage(0);
      setStopNewData(false);
      getData(isSearchable);
    })
  }, [input]);

  const inputChangedAction = async () => {
    if (input.trim().length > 0) {
      await setHasSearch(true);
      return true;
    } else {
       await setHasSearch(false);
       return false;
    }
  }


  useEffect(() => {
    if (app.userChanged) {
      const index = allUsers.findIndex((m) => {
        return m.id === app.userChanged.id;
      });

      if (index !== -1) {
        allUsers[index] = app.userChanged;
        setAllUsers([...allUsers]);
      }
      dispatch(changeUserAction(null));
    }
  }, [app.userChanged]);

  // unmount
  useEffect( () => () =>     dispatch(updateUserProfile(null)), [] );


  useEffect(() => {
   getData();
  }, []);

  const getData = (isSearchable = false) => {
    setLoading(true);
    if (type === 'matches') {
      if (isSearchable) {
        axios
            .get(`matches/search/${input}/${page}`)
            .then((res) => {
              updateData(res.data.data);
            });
      } else {
        axios
            .get(`matches/${page}`)
            .then((res) => {
              updateData(res.data.data);
            });
      }

    } else if (type === 'likes') {
      if (isSearchable) {
        axios
            .get(`likes/search/${input}/${page}`)
            .then((res) => {
              updateData(res.data.data);
            });
      } else {
        axios
            .get(`likes/${page}`)
            .then((res) => {
              updateData(res.data.data);
            });
      }

    } else if (type === 'saves') {
      if (isSearchable) {
        axios
            .get(`saves/${input}/${page}`)
            .then((res) => {
              updateData(res.data.data);
            });
      } else {
        axios
            .get(`saves/${page}`)
            .then((res) => {
              updateData(res.data.data);
            });
      }

    }
  }

  const updateData = (data) => {
    if (data.length === 0) {
      setStopNewData(true);
    }
    if (page === 0) {
      setAllUsers(data);
    } else {
      setAllUsers([...allUsers, ...data]);
    }
    setLoading(false);
  }

  const handleMatchStatusColor = (user) => {
    if (user.matchStatus.id === 1) {
      return "networking";
    } else if (user.matchStatus.id === 3) {
      return "openToWork";
    } else {
      return "jobSeeking"
    }
  }

  const handleSavematch = async (id, type, index) => {
    const data = {
      id: id,
      type: type,
    };

    axios.post("match/save", data);
    allUsers[index].saved = true;
    setAllUsers([...allUsers]);
    dispatch(saveUserAction(1));
    dispatch(changeUserAction(allUsers[index]));

  };

  const handleDeleteSaveMatch = async (id, type, index) => {
    const data = {
      id: id,
      type: type,
    };

    axios.post("match/delete/save", data);

    allUsers[index].saved = false;
    setAllUsers([...allUsers]);
    dispatch(saveUserAction(-1));


    dispatch(changeUserAction(allUsers[index]));

  };

  const handleSendMessage = async (user) => {
    dispatch(updateConversationUser(user));
    navigate("/messages");
  };

  const openUser = (user) => {
    dispatch(updateUserProfile(user));
  }

  const onScroll = (e) => {
    const scrollPercentage = Math.round((e.target.scrollTop / (e.target.scrollHeight - e.target.offsetHeight)) * 100);
    if (scrollPercentage > 50 && !stopNewData && !loading) {
      setPage(page + 1);
      getData();
    }

  }

  return (
    <ul className="list" onScroll={(e) => onScroll(e)}>
      {allUsers.map((item, index) => (
        <li key={index} className={"listItem"}>
          <div className="matchInfo" onClick={() => openUser(item)}>
            <img
              className={"avatar-image"}
              src={
                item?.images?.length > 0
                  ? process.env.REACT_APP_BASE_URL + item.images[0].imagePath
                  : avatarImage
              }
              alt="avatar"
            />
            <div className="matchUser">
              <span className={"matchUserName"}>{item.name}</span>
              <div className={"pillsContainer"} >
              <div className={"matchPercentages"}>{item.matchPercentage}% match</div>
              {
                item.matchStatus && (
                    <div className={handleMatchStatusColor(item)}>{item.matchStatus?.name}</div>
                )
              }
              </div>
            </div>
          </div>
          <div className="matchFunctions">
            <button onClick={() => handleSendMessage(item)}>
              <img src={message} alt="message" />
            </button>
            {item.saved ? (
                <BookmarkIcon style={{cursor: 'pointer', color: "7F7D87" }}  onClick={() => handleDeleteSaveMatch(item.id, item.type, index)} />
            ) : (
                <BookmarkBorderIcon style={{cursor: 'pointer', color: "7F7D87" }} onClick={() => handleSavematch(item.id, item.type, index)} />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default List;
