import React from "react";

const SignUpBtn = (props, { onClick, className = "" }) => {
  const signBtn = {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    color: "#fff",
    lineHeight: "24px",
    background: "#0066FF",
    border: "2px solid #0066FF",
    borderRadius: "10px",
    width: "100%",
    padding: "10px 0",
    cursor: "pointer",
  };

  return (
    <button style={signBtn} onClick={onClick} className={className}>
      {props.title}
    </button>
  );
};

export default SignUpBtn;
