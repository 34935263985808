import React, { useState, useEffect } from "react";
import Navigation from "../../../Navigation";
import "./DeleteAccount.scss";

import axios from "../../../../../services/https";
import { useTransition, animated } from "react-spring";
import { removeUserSession } from "../../../../../Utils/Common";
import { useNavigate } from "react-router-dom";
import deleteAcc from "../../../../../images/delete.svg";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  });

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
        navigate("/");
        removeUserSession();
      }, 2000);
    }
  }, [open]);

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 200,
  });

  const handleSubmit = (e) => {
    e.preventDefault(e);

    axios
      .delete("/me")
      .then((res) => {
        setOpen(!open);
      })
      .catch((err) => {
        // setError(res.response.data.messages);
      });
  };
  return (
    <div className="pos">
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={deleteAcc} alt="delete" />
                <h3>Delete account</h3>
                <p>Your account is successfully deleted.</p>
              </div>
            </animated.div>
          )
      )}

      <div className="view">
        <div className="deleteAccount pagePadding">
          <h2>Delete account</h2>
          <p>
            Are you sure that you want to delete your account? You will lose all your matches and messages. By clicking the button, your account will be deleted permanently.
          </p>

          <form onSubmit={handleSubmit}>
            <button className="deleteAccountBtn">Delete account</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
