import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./Nav.scss";

import SignIn from "../../auth/SignIn/SignIn";
import SignUp from "../../auth/SignUp/SignUp";

import logo from "../../../images/logo.svg";
import hamburger from "../../../images/hamburger-black.svg";
import close from "../../../images/close.svg";

import SingUpButton from "../../../elements/SignUp";
import SignInButton from "../../../elements/SignIn";

import { useTransition, animated } from "react-spring";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [signInPopup, setsignInPopup] = useState(false);
  const [signUpPopup, setsignUpPopup] = useState(false);
  const myRef = useRef(null);

  // Menu transition
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });

  // Sign in popup
  const transitionsSignIn = useTransition(signInPopup, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const signInPopupTransitions = useTransition(signInPopup, {
    from: { opacity: 0, transform: "translateY(-100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-100%)" },
  });

  // Sign up popup
  const transitionsSignUp = useTransition(signUpPopup, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const signUpPopupTransitions = useTransition(signUpPopup, {
    from: { opacity: 0, transform: "translateY(-100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-100%)" },
  });

  // Multilanguage
  const { t } = useTranslation();

  // const handleClick = (lang) => {
  //   i18n.changeLanguage(lang)
  // }

  const signUpJump = () => {
    setsignUpPopup(true);
    setOpen(false);

    window.scrollTo({
      top: 100,
      left: 0,
      behavior: "smooth",
    });

    // const element = document.getElementById("signUpPopup");
    // if (element) {
    //   element.scrollIntoView({ block: "end" });
    // }
  };

  const signInJump = () => {
    setsignInPopup(true);
    setOpen(false);
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="nav">
      <div className="navigation customPadding">
        {/* Sign in popup */}
        {transitionsSignIn(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="signInPopupBackground"
                onClick={() => setsignInPopup(false)}
              ></animated.div>
            )
        )}

        {signInPopupTransitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                id="signInPopup"
                className="signInPopupContent"
              >
                <div className="signInPopupHeader">
                  {/* <h3>Sign in business account</h3> */}
                  <div className="closePopup">
                    <img
                      src={close}
                      alt="close"
                      onClick={() => setsignInPopup(!signInPopup)}
                    />
                  </div>
                </div>
                <div className="signInPopupInside">
                  <SignIn />
                </div>
              </animated.div>
            )
        )}
        {/* End Sign in popup */}

        {/* Sign up popup */}
        {transitionsSignUp(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="signInPopupBackground"
                onClick={() => setsignUpPopup()}
              ></animated.div>
            )
        )}

        {signUpPopupTransitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                id="signUpPopup"
                className="signInPopupContent"
              >
                <div className="signUpPopupHeader">
                  <div className="closePopup">
                    <img
                      src={close}
                      alt="close"
                      onClick={() => setsignUpPopup(!signUpPopup)}
                    />
                  </div>
                </div>

                <SignUp />
              </animated.div>
            )
        )}
        {/* End Sign up popup */}

        <div className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="menu">
          <ul>
            <li>
              <a href="/#our-services">{t("navigation.ourServices")}</a>
            </li>
            <li>
              <a href="/#about-us">{t("navigation.aboutUs")}</a>
            </li>
            <li>
              <a href="/#contact">{t("navigation.contact")}</a>
            </li>
          </ul>
          <div className="authBtns">
            <SingUpButton onClick={signUpJump} />
            <SignInButton onClick={signInJump} />
          </div>
        </div>

        <div className="mobileMenu">
          <img src={hamburger} alt="hamburger" onClick={() => setOpen(!open)} />
          {transitions(
            (styles, item) =>
              item && (
                <animated.div
                  style={styles}
                  className="menuBackground"
                  onClick={() => setOpen(false)}
                ></animated.div>
              )
          )}

          {/* Menu sidebar */}
          {menuTransitions(
            (styles, item) =>
              item && (
                <animated.div style={styles} className="mobileContentMenu">
                  <div className="mobileMenuHeader">
                    <div className="mobileTitle">
                      <h3>Menu</h3>
                    </div>
                    <div className="closeMenu">
                      <img
                        src={close}
                        alt="close"
                        onClick={() => setOpen(!open)}
                      />
                    </div>
                  </div>
                  <ul>
                    <li>
                      <a onClick={() => setOpen(false)} href="/#our-services">
                        {t("navigation.ourServices")}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setOpen(false)} href="/#about-us">
                        {t("navigation.aboutUs")}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setOpen(false)} href="/#contact">
                        {t("navigation.contact")}
                      </a>
                    </li>
                    <div className="authBtns">
                      <SingUpButton onClick={signUpJump} />
                      <SignInButton onClick={signInJump} />
                    </div>
                  </ul>
                </animated.div>
              )
          )}
          {/* End menu sidebar */}
        </div>
      </div>
    </div>
  );
};

export default Nav;
