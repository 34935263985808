import React, {useState} from "react";
import "./Search.scss";

const Search = ({ setInputText }) => {
    const [timer, setTimer] = useState(null);

    const onKeyDown = () => {
        clearTimeout(timer);
    }
    const onKeyUp = (value) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => setInputText(value), 300));
    }

  return (
    <div className="search">
      <input onKeyUp={(e) => onKeyUp(e.target.value)} onKeyDown={onKeyDown} placeholder="Search" />
    </div>
  );
};

export default Search;
