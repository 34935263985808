import {Outlet} from "react-router-dom";
import Navigation from "../../Navigation";
import UserProfileDesktop from "../../UserProfileDesktop/UserProfileDesktop";
import React, {useEffect, useState} from "react";
import axios from "../../../../services/https";
import {setUserSession} from "../../../../Utils/Common";
import {animated, useTransition} from "react-spring";
import Report from "../Dashboard/Report";
import {useSelector} from "react-redux";
import Matched from "../Dashboard/Matched";
import "./Main.scss"
import NewMessageDrawer from "../NewMessageDrawer/NewMessageDrawer";
import ChangeTagDrawer from "../Messages/ChangeTagDrawer/ChangeTagDrawer";

const Main = () => {
    const [open, setOpen] = useState(false);
    const [matchUserOpen, setMatchUserOpen] = useState(false);
    const app = useSelector((state) => state.app);


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function(position) {

            axios.put("/location", {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })

        });

        axios.get("auth/refresh").then((res) => {
            setUserSession(res.data.data.accessToken, res.data.data.user);
        });
    }, []);

    useEffect(() => {
        if (app.openedUser) {
            setOpen(true);
        }
    }, [app.openedUser]);


    useEffect(() => {
        if (app.matchedUser) {
            setMatchUserOpen(true);
        } else {
            setMatchUserOpen(false);
        }
    }, [app.matchedUser]);


    const menuTransitions = useTransition(open, {
        from: { opacity: 0, transform: "translateX(100%)" },
        enter: { opacity: 1, transform: "translateX(100%)" },
        leave: { opacity: 0, transform: "translateX(100%)" },
        delay: 200,
    });

    const matchedTransitions = useTransition(matchUserOpen, {
        from: { opacity: 0, transform: "translateX(100%)" },
        enter: { opacity: 1, transform: "translateX(100%)" },
        leave: { opacity: 0, transform: "translateX(100%)" },
        delay: 200,
    });


    return <div className={"mainDiv"}>
        <Navigation/>
        <div className={"mainOutlet"}>
            <Outlet/>
        </div>
        <UserProfileDesktop />
        <NewMessageDrawer />
        <ChangeTagDrawer />
        {menuTransitions(
            (styles, item) =>
                item && (
                    <animated.div style={styles} className="reportPopup">
                        <Report setOpen={setOpen} user={app.openedUser} />
                    </animated.div>
                )
        )}

        {matchedTransitions(
            (styles, item) =>
                item && (
                    <animated.div style={styles} className="matchedPopup">
                        <Matched  app={app} />
                    </animated.div>
                )
        )}
    </div>;
}

export default Main;
