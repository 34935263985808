import React from "react";
import { Outlet } from "react-router-dom";
import Main from "./pages/inside/Main";

const useAuth = () => {
  return localStorage.getItem("token") || null;
};

const PrivateRoute = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Main />;
};

export default PrivateRoute;
