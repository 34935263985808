import React, { useState, useEffect, useRef } from "react";
import { useTransition, animated } from "react-spring";
import axios from "../../../../services/https";
import moment from "moment";
import edit from "../../../../images/edit.svg";
import back from "../../../../images/ic_back.svg";
import tdots from "../../../../images/tdots.svg";
import send from "../../../../images/send.svg";
import close from "../../../../images/close.svg";
import uploadImage from '../../../../images/upload-image.svg';
import CircularProgress from '@mui/material/CircularProgress';
import "./Mobile.scss";
import {
  changeTagAction,
  openChangeTagAction,
  openNewMessageAction,
  saveUserAction,
  updateConversationUser, updateUserProfile
} from "../../../../redux/actions/app";
import {useDispatch, useSelector} from "react-redux";
import audioEffect from "../../../../effect.mp3";

const Mobile = ({ 
  conversations,
  setMessages,
  messages,
  page,
  updateMessages,
  backFunc,
  search,
  loadMore,
  loadChat,
  isChatLoading,
    refreshMessage,
    getTime
}) => {
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [deleteConversation, setDeleteConversation] = useState(false);
  const [passId, setPassId] = useState();
  const [name, setName] = useState("");
  const [typeMessage, setTypeMessage] = useState();
  const [userMessage, setUserMessage] = useState("");
  const [body, setBody] = useState("");
  const [me, setMe] = useState([]);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [participantLogo, setParticipantLogo] = useState('');
  const [participantMatchStatus, setParticipantMatchStatus] = useState("");
  const [matchPer, setMatchPer] = useState("");
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const listInnerRef = useRef(null);
  const chatListRef = useRef(null);
  const app = useSelector((state) => state.app);
  const audio = new Audio(audioEffect);
  const [participant, setParticipant] = useState(null);



  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });

  const transitionsList = useTransition(openList, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const listTransitions = useTransition(openList, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });

  const transitionsDelete = useTransition(deleteConversation, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const deleteTransitions = useTransition(deleteConversation, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const onScroll = () => {
    if (listInnerRef.current && conversations.length === (page + 1) * 20) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === ( scrollHeight - 0.5 )) {
        loadMore();
      }
    }
  };

  const onChatScroll = () => {
    if (chatListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListRef.current;
      if (-scrollTop + clientHeight === scrollHeight) {
        loadChat();
      }
    }
  };

  useEffect(() => {
    if (app.conversationUser && app.conversationUser.id !== userMessage) {
      axios.get("/conversation/id/" + app.conversationUser.id).then((res) => {
        if (res.data.data.conversationId && passId !== res.data.data.conversationId ) {
          setPassId(res.data.data.conversationId);
          updateMessages(res.data.data.conversationId);
        } else {
          setUserMessage(app.conversationUser.id);
        }
        setTypeMessage(app.conversationUser.type);
        setUserMessage(app.conversationUser.id);
        setName(app.conversationUser.name);
        setMatchPer(app.conversationUser.matchPercentage);
        if (app.conversationUser.images.length > 0) {
          setParticipantLogo(app.conversationUser.images[0].imagePath);
        } else {
          setParticipantLogo(null);
        }
        setParticipantMatchStatus(app.conversationUser.matchStatus);
        setOpen(true);
        dispatch(updateConversationUser(null))

      });
    }
  }, [app.conversationUser])

  const handleClick = async (conversationId, type, id, name, matchPercentage, logo, matchStatus, images, participant) => {
    setOpen(!open);
    setPassId(conversationId);
    setTypeMessage(type);
    setUserMessage(id);
    setName(name);
    setParticipant(participant);

    if (images.length > 0) {
      setParticipantLogo(images[0].image_path);
    } else {
      setParticipantLogo(null);
    }    setParticipantMatchStatus(matchStatus);
    setMatchPer(matchPercentage);

    if (conversationId) {
      updateMessages(conversationId);
    };
  };

  const handleDelete = () => {
    axios.delete(`/conversation/${passId}`).then((res) => {
      window.location.reload(false);
    });
  };

  const handlePopups = () => {
    setDeleteConversation(!deleteConversation);
    setOpen(!open);
  };

  const handleSaveMatch = async (userMessage, typeMessage) => {
    axios.post("/match/save", {
      id: userMessage,
      type: typeMessage
    }).then((res) => {
      console.log("saved");
      setOpen(!open);
    });
    dispatch(saveUserAction(1));

  };

  const handleDeleteMatch = async (userMessage, typeMessage) => {
    axios.get("/unmatch/" + userMessage).then(() => {
      console.log("saved");
      setOpen(!open);
    });
    dispatch(saveUserAction(1));
  };

  const changeTag = () => {
    dispatch(openChangeTagAction(passId));
    setOpen(false);

  }

  useEffect(() => {
    if (app.changeTag) {
      const index = conversations.findIndex((c) => c.id === app.changeTag.id);

      if (index !== -1) {
        conversations[index].jobPositionTitle = app.changeTag.jobPositionTitle;
        conversations = [...conversations];
      }

      dispatch(changeTagAction(false));

    }
  }, [app.changeTag]);

  const handleMessageSend = (e) => {
    e.preventDefault();

    if (body.length === 0 && body.trim().length === 0) {
      return;
    }

    axios.post("/message", {
      body: body,
      userId: userMessage
    }).then((res) => {
      //
    });

    audio.play();


    setMessages(prev => [...prev, {
      body: body,
      conversationId: passId,
      userId: me.id,
      id: Math.random(),
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss')
    }])

    refreshMessage({
          body: body,
          conversationId: passId,
          userId: me.id,
          id: Math.random(),
          createdAt: moment().format('YYYY-MM-DD hh:mm:ss')
        }
    );

    setBody("")

    textareaRef.current.style.borderRadius = '100px';
    const form = document.querySelector('.messageForm');
    form.style.borderRadius = '100px';
    form.style.alignItems = 'center';
    const messageBtn = document.querySelector('.messageBtn');
    messageBtn.style.alignSelf = 'unset';
    textareaRef.current.style.height = '20px'
  };


  const handleMatchStatusColor = () => {
    if (participantMatchStatus.id === 1) {
      return "networking";
    } else if (participantMatchStatus.id === 3) {
      return "openToWork";
    } else {
      return "jobSeeking"
    } 
  }

  const openUser = () => {
    const userForSend = participant;
    userForSend.images = participant.fineImages;
    dispatch(updateUserProfile(userForSend));
  }

  const searchHandle = (e) => {
    let key = e.target.value;

    axios
      .get(`/conversations/${page}?search=${key}`, {
        search: key
      })
      .then((res) => {
        search(res.data.data);
      })
      .catch((res) => {
        console.log(res.response.data.messages);
      });
  };

  useEffect(() => {
    if (scrollHeight > 42) {
      textareaRef.current.style.borderRadius = '10px';
      const form = document.querySelector('.messageForm');
      form.style.borderRadius = '10px';
      form.style.alignItems = 'unset';
      const messageBtn = document.querySelector('.messageBtn');
      messageBtn.style.alignSelf = 'end';
    } 
    if (scrollHeight === 32) {
      textareaRef.current.style.borderRadius = '100px';
      const form = document.querySelector('.messageForm');
      form.style.borderRadius = '100px';
      form.style.alignItems = 'center';
      const messageBtn = document.querySelector('.messageBtn');
      messageBtn.style.alignSelf = 'unset';
      textareaRef.current.style.height = '20px'
    }
  }, [scrollHeight])

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      setMe(me);
    }
  }, []);

  return (
    <div className="mobile">
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="menuBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {/* Menu sidebar */}
      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="mobileContentMenu">
              <div className="mobileMenuHeader">
                <div className="mobileTitle">
                  <img src={back} alt="back" onClick={() => {
                    setOpen(false);
                    backFunc();
                  }}/>
                  {
                    !participantLogo ? (
                      <div><img alt="no-img" src={uploadImage}  onClick={openUser} onTouchStart={openUser} /></div>
                    ) : (
                      <img src={process.env.REACT_APP_BASE_URL + participantLogo}  onClick={openUser} onTouchStart={openUser} className={"participant-image"} alt="avatar" />
                    )
                  }
                  <div className="info" onClick={openUser} onTouchStart={openUser}>
                    <h3>{name}</h3>
                    <div className="chatStats">
                      <div className="matching">{matchPer}% MATCH</div>
                      {
                        participantMatchStatus && <div className={handleMatchStatusColor()}>{participantMatchStatus.name}</div>
                      }
                    </div>
                  </div>
                </div>
                <div className="closeMenu">
                  <img src={tdots} alt="dots" onClick={() => setOpenList(true)} />
                </div>
              </div>

              <div className="mobileMessages">
                <div className="content" ref={chatListRef} onScroll={onChatScroll}>
                  {isChatLoading ? <div className="loader"><CircularProgress /></div> : messages
                    .map((m) => {
                      return (
                        <div key={m.id}>
                          {me.id === m.userId ? (
                            <div className="right">
                              {/* {m.body === null ? (
                              <div>Start conversation</div>
                            ) : ( */}
                              {m.body === null ? (
                                <div className="empty">
                                  <div className="content">
                                    <p>{m.body}</p>
                                  </div>
                                  <div className="time">
                                    {getTime(m.createdAt)}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="content">
                                    <p>{m.body}</p>
                                  </div>
                                  <div className="time">
                                    {getTime(m.createdAt)}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="leftMessages">
                              <div className="content">
                                <p>{m.body}</p>
                              </div>
                              <div className="time">
                                {getTime(m.createdAt)}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })
                    .reverse()}
                  {/* <div className="rightChat">
                  <div className="message">Hi</div>
                  <div className="time">16:45h</div>
                </div> */}

                  <div className="enterMessage">
                    <div className="textMessage">
                      <form className="messageForm" onSubmit={handleMessageSend}>
                        <textarea
                          ref={textareaRef}
                          className={"textareaField"}
                          onChange={(e) => {
                            setBody(e.target.value)
                            const target = e.target;
                            if (textareaRef && textareaRef.current) {
                              textareaRef.current.style.height = "20px";
                              textareaRef.current.style.height = `${target.scrollHeight - 12}px`;
                              setScrollHeight(target.scrollHeight);
                            }
                          }}
                          rows="1"
                          value={body}
                          placeholder="Type message here"
                        ></textarea>
                        <button className="messageBtn">
                          <img src={send} alt="send" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          )
      )}

      {transitionsList(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpenList(false)}
            ></animated.div>
          )
      )}

      {listTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <ul>
                <li onClick={changeTag}>Change tag/tag job</li>
                <li onClick={() => handleSaveMatch(userMessage, typeMessage)}>
                  Save match
                </li>
                <li onClick={() => handleDeleteMatch(userMessage, typeMessage)}>
                  Remove match
                </li>
                <li onClick={() => setDeleteConversation(true)}>Delete conversation</li>
              </ul>
            </animated.div>
          )
      )}

      {/* Delete conversation */}
      {transitionsDelete(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackgroundDelete"
              onClick={handlePopups}
            ></animated.div>
          )
      )}
      {deleteTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="deletePopup">
              <div className="deleteHeader">
                <img
                  src={close}
                  alt="close"
                  onClick={() => setDeleteConversation(false)}
                />
                <h3>Delete conversation</h3>
                <p>Are you sure that you want to do that?</p>
                <div className="btns">
                  <button
                    className="cancel"
                    onClick={() => setDeleteConversation(false)}
                  >
                    Cancel
                  </button>
                  <button className="delete" onClick={handleDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </animated.div>
          )
      )}
      {/* End delete conversations */}

      <div className="header">
        <div className="messagesSearch">
          <input type="text" placeholder="Search" onChange={searchHandle} />
          {/* <img className="searchIcon" src={search} alt="search" /> */}
        </div>
        <div className="edit" onTouchStart={() => dispatch(openNewMessageAction(true))}>
          <img src={edit} alt="edit" />
        </div>
      </div>

      <div className="list" onScroll={onScroll} ref={listInnerRef}>
        {conversations.sort((a,b) => 
          moment(b?.lastMessage?.createdAt).valueOf() -
          moment(a?.lastMessage?.createdAt).valueOf()).map((c, index) => {
          return (
            <div
              key={index}
              className="conversation"
              onClick={() =>
                handleClick(
                  c.lastMessage.conversationId,
                  c.lastMessage.type,
                  c.participant.id,
                  c.participant.name,
                  c.participant.matchPercentage,
                  c.participant.logo,
                  c.participant.matchStatus,
                    c.participant.images,
                    c.participant
                )
              }
              id={c.lastMessage.conversationId}
            >
              <div className="info">
                <div className="image">
                  {
                    !c.participant?.images?.length > 0 ?
                      <div><img alt="no-img" src={uploadImage} /></div>
                    : (
                      <img src={process.env.REACT_APP_BASE_URL + c.participant?.images[0].minified_path} className={"participant-image"} alt="avatar" />
                    )
                  }
                </div>
                <div className="data">
                  {c.unreadCount > 0 ? (
                    <h3>
                      <strong>{c.participant?.name}</strong>
                    </h3>
                  ) : (
                    <h3>{c.participant?.name}</h3>
                  )}
                  { c.jobPositionTitle ? <h5>About: {c.jobPositionTitle}</h5> : null }
                  {c.unreadCount > 0 ? (
                    <p>
                      <strong>{c.lastMessage.body}</strong>
                    </p>
                  ) : (
                    <p>{c.lastMessage.body}</p>
                  )}
                </div>
              </div>
              <div className="time">
                {getTime(c.lastMessage.createdAt)}
              </div>

              {c.unreadCount === 0 ? (
                ""
              ) : (
                <div className="notification">{c.unreadCount}</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Mobile;
