import React from 'react'
import './SignUp.scss'

import MultiStepForm from '../../../components/MultiStepForm'

const SignUp = () => {
  return (
    <div>
      <MultiStepForm />
    </div>
  )
}

export default SignUp
