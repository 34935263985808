import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import "./ChangeEmail.scss";
import axios from "../../../../../services/https";
import { useTranslation } from "react-i18next";

import useDigitInput from "react-digit-input";
import { setUserSession } from "../../../../../Utils/Common";
import SignUpBtn from "../../../../../elements/SignBtn";

import Navigation from "../../../Navigation";

import TextField from "@mui/material/TextField";
import InputField from "../../../../../components/InputField";
import sucess from "../../../../../images/success.svg";

const ChangeEmail = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [error, setError] = useState("");

  const [value, onChange] = React.useState("");

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange,
  });

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("email"));
    if (newEmail) {
      setNewEmail(newEmail);
    }
  }, []);

  const [verification, setVerification] = useState(false);
  const [open, setOpen] = useState(false);

  // verify email
  const transitionsVerify = useTransition(verification, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  });

  const verifyTransitions = useTransition(verification, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 200,
  });

  // success changed email
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      newEmail: newEmail,
    };

    axios
      .post("/me/change-email", data)
      .then((res) => {
        setVerification(!verification);
        localStorage.setItem("email", JSON.stringify(newEmail));
        console.log(res.data);
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });
  };

  const handleVerify = (e) => {
    e.preventDefault();

    const data = {
      email: newEmail,
      code: value,
    };

    axios
      .post("/me/change-email/verify", data)
      .then((res) => {
        console.log(res);
        setVerification(false);
        setOpen(!open);
        console.log(setUserSession);
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });
  };

  return (
    <div className="pos">
      {/* Verify */}
      {transitionsVerify(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setVerification(false)}
            ></animated.div>
          )
      )}

      {verifyTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="otp">
                <div className="otpContent">
                  <h2>{t("modals.checkInbox")}</h2>
                  <p className="enterCodeText">{t("modals.enterCode")}</p>
                  <form onSubmit={handleVerify}>
                    <div className="enterCode">
                      <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        hidden
                      />

                      <input inputMode="decimal" autoFocus {...digits[0]} />
                      <input inputMode="decimal" {...digits[1]} />
                      <input inputMode="decimal" {...digits[2]} />
                      <input inputMode="decimal" {...digits[3]} />
                    </div>
                    <p className="errorMessage">{error.code}</p>
                    <pre>
                      <code>"{value}"</code>
                    </pre>

                    <SignUpBtn title={t("modals.verify")} />
                  </form>
                </div>
              </div>
            </animated.div>
          )
      )}
      {/* End verify */}

      {/* Success email */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={sucess} alt="sucess" />
                <h3>Successfully changed email</h3>
                <p>
                  Go to your new email to confirm changes. Follow instructions,
                  some description, some description.{" "}
                </p>
              </div>
            </animated.div>
          )
      )}
      {/* End success */}

      <div className="view">

        <div className="changeEmail pagePadding">
          <h2>Change email</h2>

          <form onSubmit={handleSubmit} className="emailFields">
            <TextField
              label="Email address"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error.email}
              helperText={error.email}
            />

            {/* {!error && (
              <InputField
                label="Email address"
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}

            {error && (
              <TextField
                error
                id="outlined-error-helper-text"
                label="Email address"
                defaultValue={email}
                value={email}
                helperText={error.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )} */}

            <TextField
              label="Repeat email address"
              type="email"
              name="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              error={error.newEmail}
              helperText={error.newEmail}
            />

            {/* {!error && (
              <InputField
                label="Repeat email address"
                type="email"
                name="email"
                id="newemail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            )}

            {error && (
              <TextField
                error
                id="outlined-error-helper-text"
                label="Repeat email address"
                defaultValue={newEmail}
                value={newEmail}
                helperText={error.newEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
            )} */}

            <button className="updatePassword" type="submit">
              Update email
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
