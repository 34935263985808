import React, {useEffect, useState} from "react";
import Navigation from "../../Navigation";
import "./Dashboard.scss";
import Matches from "./Matches";
import Card from "./Card";
import Filter from "./Filter";
import axios from '../../../../services/https';
import noMatches from '../../../../images/no-matches.svg';
import CompleteYourProfile from "./CompleteYourProfile/CompleteYourProfile";
import CircularProgress from '@mui/material/CircularProgress';
import {useSelector} from "react-redux";

const Dashboard = () => {
    const [existingUserIds, setExistingUserIds] = useState([]);
    const [matches, setMatches] = useState([]);
    const [reports, setReports] = useState([]);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [noMoreCards, setNoMoreCards] = useState(false);
    const app = useSelector((state) => state.app);


    const getMatchData = async (seenUsers, resetExisting = false) => {
        let filter = app.filter;
        if (filter) {
            filter.existingUserIds = seenUsers;
        } else {
            filter = {existingUserIds: seenUsers};
        }
        const res = await axios.post("business/matches/suggestion", filter)

        if (res) {
            setIsLoading(false);
            if (resetExisting) {
                setExistingUserIds(res.data.data.existingUserIds);
                setMatches(res.data.data.suggestions);
            } else {
                setExistingUserIds(prev => [...prev, ...res.data.data.existingUserIds]);
                setMatches(prev => [...prev, ...res.data.data.suggestions])
            }

            setNoMoreCards(false)
        }
    }

    useEffect(() => {
        if (app.filter) {
            getMatchData([], true);
        }
    }, [app.filter]);


    const getUserInfo = async () => {
        const userData = await axios.get("me");
        setUserData(userData.data.data);
    }

    const removeUserFromList = (userId) => {
        matches.shift();
        setMatches([...matches]);
        if (matches.length === 6) {
            getMatchData(existingUserIds)
        }

        if (matches.length === 0) {
            setTimeout(() => {
                setNoMoreCards(true)
            }, 400);
        }

    };


    useEffect(() => {
        setIsLoading(true);
        getUserInfo();
        getMatchData(existingUserIds, true);
    }, []);

    if (isLoading) {
        return (
            <div className="view">
                <div className="loader">
                    <CircularProgress/>
                </div>
            </div>
        )
    }

    return (
        <div className="view">
            {
                userData?.images?.length === 0 || !userData?.name || !userData?.city || !userData?.logo
                    ? <CompleteYourProfile/> : (
                        <div className="dashboard">
                            <h3 className="title">Matches</h3>
                            <Matches/>
                            {!noMoreCards ? (
                                    matches?.slice(0, 3).map((item, index) => {
                                                return <Card
                                                    cardIndex={index}
                                                    reports={reports}
                                                    key={item.id}
                                                    element={item}
                                                    matches={matches}
                                                    removeUserFromList={() => removeUserFromList(item.id)}
                                                />

                                        }
                                    ).reverse())
                                : (
                                    <div className="noMatches">
                                        <img alt="no more matches" src={noMatches} className="noMoreMatchesIcon"/>
                                        <div className="noMatchesText">
                                            You have seen all users for now
                                        </div>
                                        <p className="noMatchesParagraph">
                                            Try another filter or reload the list.
                                        </p>
                                        <button
                                            onClick={() => {
                                                setExistingUserIds([]);
                                                setIsLoading(true);
                                                getMatchData([], true);
                                            }}
                                            className="reloadBtn">
                                            Reload list
                                        </button>
                                    </div>
                                )
                            }
                            <Filter/>
                        </div>
                    )
            }
        </div>
    );
};

export default Dashboard;
