import React from 'react';
import './CompleteYourProfile.scss';
import noMatches from '../../../../../images/no-matches.svg';
import { useNavigate } from "react-router-dom";

const CompleteYourProfile = () => {
  const navigate = useNavigate();

  return (
    <div className='completeProfile'>
        <img className='completeProfileImg' alt='complete profile' src={noMatches} />
        <div className='completeProfileTitle'>Complete your profile to start matching</div>
        <p className='completeProfileParagraph'>When you match with others you can send messages and start connecting.</p>
        <button className='goToBtn' onClick={() => navigate("/edit-profile")}>Go to profile</button>
    </div>
  )
}

export default CompleteYourProfile