import React, {useEffect, useState} from "react";
import {animated, useTransition} from "react-spring";
import {useDispatch, useSelector} from "react-redux";
import {changeTagAction, openChangeTagAction, openNewMessageAction} from "../../../../../redux/actions/app";
import close from "../../../../../images/close.svg";
import Search from "../../../../../components/Search";
import avatarImage from "../../../../../images/upload-image.svg";
import "./ChangeTagDrawer.scss";
import axios from "../../../../../services/https";

const ChangeTagDrawer = () => {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [conversationId, setConversationId] = useState(null);
    const [inputText, setInputText] = useState("");
    const [jobs, setJobs] = useState([]);

    const desktopTransition = useTransition(open, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 1},
    });

    useEffect(() => {
        const data = {
            title: inputText,
            type: 2 // active jobs
        }
        axios.post("job/filter", data).then((res) => {
            setJobs(res.data.data[0])
        });
    }, [inputText]);



    useEffect(() => {
        if (app.openChangeTag) {
            setOpen(true);
            setConversationId(app.openChangeTag);
        } else {
            setOpen(false);
            setConversationId(null);
        }
    }, [app.openChangeTag]);



    const closePopup = () => {
        setOpen(false);
        setConversationId(null);
        dispatch(openChangeTagAction(null));
    }

    const selectJob = (job) => {

        if (conversationId) {
            const data = {
                id: conversationId,
                jobPositionId: job.id,
                jobPositionTitle: job.title
            };

            axios.put("/conversation", data)
            dispatch(changeTagAction(data));
        }

        setOpen(false);
        setConversationId(null);
        dispatch(openChangeTagAction(null));
    }

    return (
        <div>
            { desktopTransition(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="ChangeTagDrawer">
                            <div className="ChangeTagDrawerHeader">
                                <div className="ChangeTagDrawerHeaderClose" onClick={() => closePopup()}>
                                    <img
                                        src={close}
                                        alt="close"
                                    />
                                </div>
                                <span>Change Tag</span>
                            </div>


                            <div className={"ChangeTagDrawerInner"}>
                                <Search setInputText={setInputText} />

                                <ul className="list">
                                    {jobs.map((job) => {
                                        return <div className={"listItem"} key={job.id} onClick={() => selectJob(job)}>
                                            {job.title}
                                        </div>
                                    })}

                                </ul>
                            </div>
                        </animated.div>
                    )
            )}
        </div>

    );
}

export default ChangeTagDrawer;