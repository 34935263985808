import React from "react";
import { useForm, useStep } from "react-hooks-helper";

import JoinUs from "./steps/JoinUs";
import CreateAcc from "./steps/CreateAcc";
import Location from "./steps/Location";
import Payment from "./steps/Payment";
import Otp from "./steps/Otp";
import SignIn from "../pages/auth/SignIn/SignIn";

const MultiStepForm = () => {
  const defaultData = {
    businessName: "",
    organizationNumber: "",
    emailAdress: "",
    cratePassword: "",
    repeatePassword: "",
  };

  const steps = [
    { id: "joinUs" },
    { id: "createAcc" },
    { id: "otp" },
    { id: "location" },
    { id: "payment" },
    { id: "signIn" },
  ];

  const [formData, setformData] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setformData, navigation };

  switch (step.id) {
    case "joinUs":
      return <JoinUs {...props} />;
    case "createAcc":
      return <CreateAcc {...props} />;
    case "otp":
      return <Otp {...props} />;
    case "location":
      return <Location {...props} />;
    case "payment":
      return <Payment {...props} />;
    case "signIn":
      return <SignIn {...props} />;
    default:
  }

  return <div>MultiStepForm</div>;
};

export default MultiStepForm;
