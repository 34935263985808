import React, {useState, useEffect, useCallback} from "react";
import "./Dashboard.scss";
import {useTransition, animated} from "react-spring";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import axios from "../../../../services/https";

import fitler from "../../../../images/filterBlack.svg";
import close from "../../../../images/close.svg";
import back from "../../../../images/ic_back.svg";


import {useDispatch, useSelector} from "react-redux";
import {openFilter, saveFilter} from "../../../../redux/actions/app";

const Filter = () => {
        const app = useSelector((state) => state.app);
        const initialFilter = {
            cityId: null,
            radius: 50,
            matchStatus: [],
            preferredWorkType: [],
            preferredWorkPlace: [],
            experienceLevel: [],
            tags: [],
            cityName: ''
        };
        const [filter, setFilter] = useState(initialFilter);
        const [allTagCategories, setTagCategories] = useState([]);
        const [allWorkTypes, setWorkTypes] = useState([]);
        const [allWorkPlaces, setWorkPlaces] = useState([]);
        const [allLevels, setLevels] = useState([]);
        const [search, setSearch] = useState("");
        const [city, setCity] = useState([]);
        const [value, setValue] = useState(city[0]);
        const dispatch = useDispatch();
        const [radius, setRadius] = useState(50);
        const [timer, setTimer] = useState(null);
        const [radiusActivated, setRadiusActivated] = useState(false);

        const [workType, setWorkType] = useState([]);
        const [workTypeNext, setWorkTypeNext] = useState([]);

        const [workLevels, setWorkLevels] = useState([]);
        const [workLevelsNext, setWorkLevelsNext] = useState([]);

        const [workCategories, setWorkCategories] = useState([]);
        const [workCategoriesNext, setWorkCategoriesNext] = useState([]);

        const [workPlace, setWorkPlace] = useState([]);
        const [workPlaceNext, setWorkPlaceNext] = useState([]);
        const [tags, setTags] = useState([]);
        const [tagsMode, setTagsMode] = useState(false);
        const [extraTags, setExtraTags] = useState([]);
        const [extraTagsText, setExtraTagsText] = useState("");


    useEffect(() => {
            if (filter) {
                if (!radiusActivated) {
                    filter.radius = null;
                }
                dispatch(saveFilter(filter));
            }
        }, [filter]);

        //   Filter transition
        const transitionsFilter = useTransition(app.openFilters, {
            from: {opacity: 0},
            enter: {opacity: 1},
            leave: {opacity: 0},
        });

        const filterTransitions = useTransition(app.openFilters, {
            from: {opacity: 0, transform: "translateX(100%)"},
            enter: {opacity: 1, transform: "translateX(0%)"},
            leave: {opacity: 0, transform: "translateX(100%)"},
        });

        useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                axios.get(`/tag?tag=${extraTagsText}`).then((res) => {
                    setExtraTags(res.data.data);
                });
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }, [extraTagsText]);


    useEffect(() => {

            const delayDebounceFn = setTimeout(() => {
                if (search.id !== 0 ) {
                    axios.get(`/city?city=${search.name}`).then((res) => {
                        setCity(res.data.data);
                    });
                }
            }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);

        useEffect(() => {
            axios
                .get("app-data")
                .then((res) => {
                    setTagCategories(res.data.data.tagCategories[0].tags);
                    setWorkTypes(res.data.data.tagCategories[1].tags);
                    setWorkPlaces(res.data.data.tagCategories[2].tags);
                    setLevels(res.data.data.tagCategories[3].tags);
                })
                .catch((err) => {
                });
        }, []);

        const locations = [
            {
                value: "St",
                label: "Stockholm",
            },
            {
                value: "RS",
                label: "Serbia",
            },
        ];

        const handleWorkType = (e) => {
            const id = e.target.id;

            let updatedWorkTypes = filter.preferredWorkType;
            const index = updatedWorkTypes.findIndex((c) => c === id);


            if (index === -1) {
                updatedWorkTypes.push(id);
            } else {
                updatedWorkTypes.splice(index, 1);
            }

            setFilter({...filter, preferredWorkType: updatedWorkTypes});
        };

        const isWorkTypeChecked = (id) => {
            const index = filter.preferredWorkType.findIndex((c) => c == id);
            return index !== -1;
        }

        const isMatchStatusChecked = (id) => {
            const index = filter.matchStatus.findIndex((c) => c == id);
            return index !== -1;
        }

        const isLevelChecked = (id) => {
            const index = filter.experienceLevel.findIndex((c) => c == id);
            return index !== -1;
        }

        const isTagChecked = (tag) => {
            const index = filter.tags.findIndex((c) => c == tag.id);
            return index !== -1;
        }

        const isPlaceChecked = (id) => {
            const index = filter.preferredWorkPlace.findIndex((c) => c == id);
            return index !== -1;
        }

        const handleSliderChange = (event, value) => {
            setRadius(value);
            setRadiusActivated(true);
            setSearch({id: 0, name: "My location"})
            clearTimeout(timer);
            setTimer(setTimeout(() => {
                setFilter({...filter, radius: value});
            }, 300));

        }

        const handleLevels = (e) => {
                const id = e.target.id;
                const value = e.target.value;
                const checked = e.target.checked;

                let updatedLevels = filter.experienceLevel;
                const index = updatedLevels.findIndex((c) => c === id);

                if (index === -1) {
                    updatedLevels.push(id);
                } else {
                    updatedLevels.splice(index, 1);
                }


                setFilter({...filter, experienceLevel: updatedLevels});

            };

        const handleTags = (e) => {
            const id = e.target.id;

            let updatedTags = filter.tags;
            const index = updatedTags.findIndex((c) => c == id);

            if (index === -1) {
                updatedTags.push(id);
            } else {
                updatedTags.splice(index, 1);
            }


            setFilter({...filter, tags: updatedTags});

        }

        const handleTagCategories = (e) => {
            const id = e.target.id;
            const value = e.target.value;
            const checked = e.target.checked;

            let updatedCategories = filter.matchStatus;
            const index = updatedCategories.findIndex((c) => c === id);

            if (index === -1) {
                updatedCategories.push(id);
            } else {
                updatedCategories.splice(index, 1);
            }

            setFilter({...filter, matchStatus: updatedCategories});

        };

        const selectedTag = (value) => {
            const tagIndex = tags.findIndex(t => t.id === value.id);

            if (tagIndex === -1) {
                tags.push(value);
            }

            setTags([...tags]);

            let updatedTags = filter.tags;
            const index = updatedTags.findIndex((c) => c === value.id);

            if (index === -1) {
                updatedTags.push(value.id);
            } else {
                updatedTags.splice(index, 1);
            }


            setFilter({...filter, tags: updatedTags});
         }

        const handleWorkPlace = (e) => {
            const id = e.target.id;
            const value = e.target.value;
            const checked = e.target.checked;

            let updatedPlaces = filter.preferredWorkPlace;
            const index = updatedPlaces.findIndex((c) => c === id);


            if (index === -1) {
                updatedPlaces.push(id);

            } else {

                updatedPlaces.splice(index, 1);
            }


            setFilter({...filter, preferredWorkPlace: updatedPlaces});

        };



        const resetData = () => {
            setRadius(50);
            setFilter({...initialFilter, cityName: Math.random()});

        }

        const showText = (e) => {
            setSearch({name: e.target.value});
        };

        return (
            <div className="filter">
                {/* Sign in popup */}
                {transitionsFilter(
                    (styles, item) =>
                        item && (
                            <animated.div
                                style={styles}
                                className="FilterPopupBackground"
                                onClick={() => dispatch(openFilter(false))}
                            ></animated.div>
                        )
                )}

                {filterTransitions(
                    (styles, item) =>
                        item && (
                            <animated.div style={styles} className="FilterPopupContent">
                                <div className="FilterPopupHeader">

                                    { tagsMode?
                                        <h3 style={{display: "flex"}}>
                                            <div className="backPopUp">
                                            <img
                                                src={back}
                                                alt="back"
                                                onClick={() => setTagsMode(false)}
                                            />
                                        </div>
                                            Add match tags</h3>
                                        :
                                        <h3>Filters</h3>
                                    }

                                    { !tagsMode?
                                    <div className="closePopup">
                                        <img
                                            src={close}
                                            alt="close"
                                            onClick={() => dispatch(openFilter(false))}
                                        />
                                    </div>
                                        : null }
                                </div>
                                { tagsMode ?
                                    <div className="filterPopupInside">
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                selectedTag(newValue);
                                            }}
                                            key={"tags_ " + filter.tags.count}
                                            disablePortal
                                            id="combo-box-demo"
                                            getOptionLabel={(option) => option.name}
                                            options={extraTags}
                                            sx={{width: '100%'}}
                                            isOptionEqualToValue={(option, value) =>
                                                option.name === value.name
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search" onChange={(e) => setExtraTagsText(e.target.value)}/>
                                            )}
                                        />

                                        <div className={"otherTags"} >
                                            <div className="tags">
                                                {tags.map((tag) => (
                                                    <div key={tag.id} className="style">
                                                        <input
                                                            type="checkbox"
                                                            id={tag.id}
                                                            name="workType"
                                                            value={tag.name}
                                                            className="chk-btn workTypeButton"
                                                            checked={isTagChecked(tag)}
                                                            onChange={handleTags}

                                                        />
                                                        <label
                                                            htmlFor={tag.id}
                                                            id={tag.id}
                                                            key={tag.id}
                                                            className="chk-btn workTypeButton"
                                                        >
                                                            {" "}
                                                            {tag.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className="filterPopupInside">
                                        <form >
                                            <Autocomplete
                                                onChange={(event, newValue) => {
                                                    setSearch(newValue);
                                                    setRadiusActivated(false);
                                                    setRadius(50);
                                                    if (newValue) {
                                                        setFilter({...filter, cityId: newValue?.id});
                                                    }
                                                }}
                                                key={filter.cityName}
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.name || ""}
                                                options={city}
                                                sx={{width: '100%'}}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.name === value.name
                                                }
                                                value={search}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Location" onChange={showText}/>
                                                )}
                                            />
                                            <div className="radius">
                                                <h6>Search radius in km, 0 - 100+</h6>
                                                <Slider
                                                    defaultValue={50}
                                                    aria-label="Default"
                                                    valueLabelDisplay="on"
                                                    className={radiusActivated ? "searchRadiusActivated" : "searchRadius"}
                                                    value={radius}
                                                    onChange={handleSliderChange}
                                                />
                                            </div>
                                            <div className="tagCategories">
                                                <h6>Categories</h6>
                                                <div className="filterList">
                                                    {allTagCategories.map((tag) => (
                                                        <div key={tag.id} className="levelStyle">
                                                            <label key={tag.id}>
                                                                <div className="tagStyle">
                                                                    {tag.name}
                                                                    <span>{tag.description}</span>
                                                                </div>
                                                                <input
                                                                    type="checkbox"
                                                                    id={tag.id}
                                                                    value={tag.name}
                                                                    onChange={handleTagCategories}
                                                                    checked={isMatchStatusChecked(tag.id)}
                                                                    name="workPlace"
                                                                />
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                        <div className="workTypes">
                                            <h6>Work type</h6>
                                            <div className="tags">
                                                {allWorkTypes.map((tag) => (
                                                    <div key={tag.id} className="style">
                                                        <input
                                                            type="checkbox"
                                                            id={tag.id}
                                                            name="workType"
                                                            value={tag.name}
                                                            className="chk-btn workTypeButton"
                                                            checked={isWorkTypeChecked(tag.id)}
                                                            onChange={handleWorkType}

                                                        />
                                                        <label
                                                            htmlFor={tag.id}
                                                            id={tag.id}
                                                            key={tag.id}
                                                            className="chk-btn workTypeButton"
                                                        >
                                                            {" "}
                                                            {tag.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                            <div className="workPlaces">
                                                <h6>Work places</h6>
                                                <div className="tags">
                                                    {allWorkPlaces.map((tag) => (
                                                        <div key={tag.id} className="style">
                                                            <input
                                                                type="checkbox"
                                                                onChange={handleWorkPlace}
                                                                checked={isPlaceChecked(tag.id)}
                                                                id={tag.id}
                                                                name="workType"
                                                                value={tag.name}
                                                                className="chk-btn"
                                                            />
                                                            <label
                                                                htmlFor={tag.id}
                                                                key={tag.id}
                                                                className="chk-btn"
                                                            >
                                                                {" "}
                                                                {tag.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="levels">
                                                <h6>Levels</h6>
                                                <div className="filterList">
                                                    {allLevels.map((tag) => (
                                                        <div key={tag.id} className="levelStyle">
                                                            <label key={tag.id}>
                                                                <div className="style">
                                                                    {tag.name}
                                                                    <span> {tag.description}</span>
                                                                </div>
                                                                <input
                                                                    type="checkbox"
                                                                    id={tag.id}
                                                                    value={tag.name}
                                                                    onChange={handleLevels}
                                                                    checked={isLevelChecked(tag.id)}
                                                                    name="workPlace"
                                                                />
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="otherTags">
                                                <h6>Your match tags</h6>
                                                <div className="tags">
                                                    {tags.map((tag) => (
                                                        <div key={tag.id} className="style">
                                                            <input
                                                                type="checkbox"
                                                                id={tag.id}
                                                                name="workType"
                                                                value={tag.name}
                                                                className="chk-btn workTypeButton"
                                                                checked={isTagChecked(tag)}
                                                                onChange={handleTags}

                                                            />
                                                            <label
                                                                htmlFor={tag.id}
                                                                id={tag.id}
                                                                key={tag.id}
                                                                className="chk-btn workTypeButton"
                                                            >
                                                                {" "}
                                                                {tag.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={"addMatches"} onClick={() => setTagsMode(true)}>
                                                    + Add match tags
                                                </div>
                                            </div>

                                            <div className="filterButtons" onClick={resetData}>
                                                <button className="clear">Clear filter</button>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </animated.div>
                        )
                )}
                {/* End Sign in popup */}

                <img className="filterImg" src={fitler} alt="filter" onClick={() => dispatch(openFilter(true))}/>
            </div>
        );
    }
;

export default Filter;
