import "./UserProfileDesktop.scss";
import React, {useEffect, useState} from "react";
import {animated, useTransition} from "react-spring";
import ic_back from "../../../images/ic_back.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    changeUserAction, matchedUserAction,
    openUserAction,
    saveUserAction,
    updateConversationUser,
    updateUserProfile
} from "../../../redux/actions/app";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Checkbox from "@mui/material/Checkbox";
import axios from "../../../services/https";
import resume from "../../../images/resume.svg";
import suitcase from "../../../images/suitcase.svg";
import cap from "../../../images/cap.svg";
import location from "../../../images/location.svg";
import ReactHtmlParser from "react-html-parser";
import {useNavigate} from "react-router-dom";
import noImage from '../../../images/upload-image.svg';
import downArrow from "../../../images/down_arrow.svg";
import {useSwipeable} from "react-swipeable";


const UserProfileDesktop = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = useState(false);
    const desktopTransition = useTransition(open, {
        from: {opacity: 0, transform: isMobile ? "translateY(100%)" : "translateX(100%)"},
        enter: {opacity: 1, transform: isMobile ? "translateY(0%)" : "translateX(0%)" },
        leave: {opacity: 1, transform: isMobile ? "translateY(100%)" : "translateX(100%)"},
    });

    const app = useSelector((state) => state.app);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [topImageIndex, setTopImageIndex] = useState(0);
    const navigate = useNavigate();
    const [swipePosition, setSwipePosition] = useState(0);


    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
            window.history.pushState(null, document.title, window.location.href);
            closePopup();
        });
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    });

    const swipedImages = (eventData) => {
        const el = document.getElementsByClassName('UserProfileImage')[0];
        let newIndex = 0;
        if (eventData.dir === "Right") {
            newIndex = topImageIndex - 1;
            if (topImageIndex === 0) {
                newIndex = 0;
            }
            setTopImageIndex(newIndex);
        } else {
            newIndex = topImageIndex + 1;
            if (topImageIndex === user.images.length - 1) {
                newIndex = topImageIndex;
            }
            setTopImageIndex(newIndex);
        }
        el.scrollTo({
            top: 0,
            left: newIndex * el.clientWidth,
            behavior: "smooth",
        });
    }


    const handlers = useSwipeable({
        onSwipeStart: () => true,
        onSwiped: swipedImages,
        onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
        touchEventOptions: { passive: true },
        preventScrollOnSwipe: true,
        trackMouse: true
    });


    const handleResize = () => {
        if (document.documentElement.clientWidth <= 992) {
            setIsMobile(true);
            // setProfileTransition(mobileProfileTransiton);
        } else {
            setIsMobile(false);
            // setProfileTransition(desktopTransition);
        }
    }


    useEffect(() => {
        if (app.userProfileData) {
            setOpen(true);
            setUser(app.userProfileData);
            setTopImageIndex(0);
        } else {
            setOpen(false);
            setTopImageIndex(0);
            setUser(null);
        }
    }, [app.userProfileData]);

    useEffect(() => {
        if (app.userChanged) {
            setUser(app.userChanged);
            dispatch(changeUserAction(null));
        }
    }, [app.userChanged]);


    const openCV = () => {
        if (user.cv) {
            const cv = user?.cv?.split("").slice(9, user?.cv?.length).join("");
            setTimeout(() => {
                window.open(process.env.REACT_APP_BASE_URL + cv, '_blank')
            });
        } else {
            alert("This user has no CV");
        }
    }

    const closePopup = () => {
        setOpen(false);
        setUser(null);
        dispatch(updateUserProfile(null));
    }


    const changeImage = (index) => {
        if ((index + 1) < (user.images.length)) {
            setTopImageIndex(topImageIndex + 1);
        } else {
            setTopImageIndex(0);
        }

    }


    useEffect(() => {
        if (app.userChanged && user) {
            if (app.userChanged.id === user.id) {
                user.saved = app.userChanged.saved;
            }
        }
    }, [app.userChanged]);

    const saveToggle = () => {
        const data = {
            id: user.id,
            type: 1,
        };
        if (user.saved) {
            axios.post("match/delete/save", data);
            setUser({...user, saved: false});
            dispatch(saveUserAction(-1));
            user.saved = false;
            dispatch(changeUserAction(user));
        } else {
            axios.post("match/save", data);
            setUser({...user, saved: true});
            dispatch(saveUserAction(1));
            user.saved = true;
            dispatch(changeUserAction(user));
        }

    }

    const handleMatchStatusColor = () => {
        if (user.matchStatus.id === 1) {
            return "networking";
        } else if (user.matchStatus.id === 3) {
            return "openToWork";
        } else {
            return "jobSeeking"
        }
    }

    const handleSendMessage = () => {
        dispatch(updateConversationUser(user));
        navigate("/messages");
    }

    const handleReport = () => {
        dispatch(openUserAction(user));
    }


    return (
        <div>
                <div>
                    { desktopTransition(
                        (styles, item) =>
                            item && (
                                <animated.div style={styles} className="UserProfile">
                                    { !isMobile ?
                                    <div className="UserProfileHeader" onClick={() => closePopup()}>
                                        <div className="closePopup">
                                            <img
                                                src={ic_back}
                                                alt="close"
                                            />
                                        </div>
                                        <span>User Profile</span>
                                    </div>
                                        : null }

                                    <div className={"UserProfileImageContainer"}>
                                    <div className="UserProfileImage" {...handlers} >
                                        {user?.images.length === 0 ?
                                            <img className={"UserProfileImageOnly"} src={noImage} /> : null}
                                        {user?.images.map((image, index) => (
                                            <img className="UserProfileImageOnly"
                                                 key={index} src={baseUrl + image.imagePath}/>
                                        ))}

                                    </div>
                                        <div className={"moreInfoButton"} onTouchStart={() => closePopup()}>
                                            <img src={downArrow} />
                                        </div>
                                        <div className={"UserProfileImageLines"}>
                                            {user?.images.map((image, index) => (
                                                <div className="UserProfileImageLine"
                                                     style={topImageIndex === index ? {backgroundColor: 'white'} : {backgroundColor: '#7F7D87'}}/>
                                            ))}
                                        </div>
                                        <div className={"UserProfileBookmark"} onClick={() => saveToggle()}>
                                            {user?.saved ? <BookmarkIcon style={{color: '0066FF'}}/> :
                                                <BookmarkBorderIcon style={{color: '0066FF'}}/>}
                                        </div>
                                        <div className={"cvContainer"} onClick={() => openCV()}>
                                            <img
                                                src={resume}
                                                alt="CV"
                                                className={"cvImage"}
                                            /> CV
                                        </div>
                                    </div>
                                    <div className="UserProfileInside">
                                        <div className={"UserProfileName"}>
                                            {user?.name}
                                        </div>
                                        <div className="tags">
                                            <div className="match">{user?.matchPercentage}% match</div>
                                            {
                                                user?.matchStatus && (
                                                    <div className={handleMatchStatusColor()}>{user?.matchStatus?.name}</div>
                                                )
                                            }
                                        </div>
                                        <div className="UserProfileInfo">
                                            <div className="UserProfileInfoWrap">
                                                <img
                                                    src={suitcase}
                                                    alt="suitcase"
                                                    className="icon"
                                                />
                                                {user?.currentJobTitle
                                                    ? `${user?.currentJobTitle}`
                                                    : "-"}
                                            </div>
                                            <div className="UserProfileInfoWrap">
                                                <img
                                                    src={cap}
                                                    alt="cap"
                                                    className="icon"
                                                />
                                                {user?.school ? `${user?.school}` : "-"}
                                            </div>
                                            <div className="UserProfileInfoWrap">
                                                <img
                                                    src={location}
                                                    alt="location"
                                                    className="icon"
                                                    style={{marginLeft: 3}}
                                                />
                                                {user?.city && user?.city.name
                                                    ? `${user?.city?.name}`
                                                    : "-"}
                                                {user?.distance != null && user?.distance !== -1 ? " | " + Math.round(user?.distance / 1000) + "km away" : null}
                                            </div>
                                        </div>
                                        <div className="allTags">
                                            {user?.preferredWorkTypes?.map((tag, index) => {
                                                return <div className={"darkTag"} key={index}> {tag.name} </div>
                                            })
                                            }
                                            {user?.preferredWorkPlaces?.map((tag, index) => {
                                                return <div className={"darkTag"} key={index}> {tag.name} </div>
                                            })
                                            }
                                            {user?.tags.map((tag, index) => {
                                                return <div className={"darkTag"} key={index}> {tag.name} </div>
                                            })
                                            }
                                        </div>
                                        <div className="aboutLinkWrap">
                                            <div className="about">
                                                <h5 className="miniTitle">About me</h5>
                                                <p className="aboutContent">{user?.about ? ReactHtmlParser(user?.about) : "-"}</p>
                                            </div>

                                            {user?.links.length > 0 ? <div className="links">
                                                <h5 className="miniTitle">Links</h5>
                                                <div className={"linksContainer"}>
                                                    {user?.links.map((link, index) => {
                                                        return <a href={link.url} target={"_blank"} key={index}
                                                                  className={"UserProfileLink"}>{link.url}</a>
                                                    })}
                                                </div>
                                            </div> : null}

                                        </div>
                                        <div className="buttons">
                                            <div
                                                className="UserProfileSendMessage"
                                                onClick={() => handleSendMessage()}
                                            >
                                                Send message
                                            </div>
                                            <hr className="UserProfileLine"/>
                                            <div
                                                className="UserProfileReport"
                                                onClick={() => handleReport()}
                                            >
                                                Report user
                                            </div>
                                        </div>
                                    </div>
                                </animated.div>
                            )
                    )}
                </div>

        </div>
    )

}

export default UserProfileDesktop;