import axios from "axios";
import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../CheckoutForm";

import "./Payment.scss";
import {useNavigate} from "react-router-dom";

const stripePromise = loadStripe(`${process.env.REACT_APP_STAGING}`);

const Payment = () => {
  const [subscription, setSubscription] = useState();
  const [subscriptionType, setPayment] = useState();

  const [type, setType] = useState();
  const navigate = useNavigate();

  const options = {
    clientSecret: `${type}`,
  };

  useEffect(() => {
    axios.get("/app-data").then((res) => {
      setSubscription(res.data.data.subscriptionTypes);
    });

    showSubscriptionType();
  }, [subscriptionType]);

  const showSubscriptionType = () => {
    axios.get("/intent/start").then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    });
  };

  const onOptionChange = (id) => {
    axios.get("/intent/start/" + id).then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    });
  };

  // const handlePayment = (e) => {
  //   e.preventDefault();

  //   const data = {
  //     price: subscriptionType,
  //   };

  //   console.log(data);
  // };

  return (
    <div className="payment">
      <div className="paymentContent">
        <h2>Choose payment model</h2>
        {/* {type} */}
        <div className="paymentType">
          {subscription?.map((s) => (
            <div className="radioPayment" key={s.id}>
              <label>
                <input
                  type="radio"
                  name="topping"
                  value={s.type}
                  id={s.id}
                  defaultValue={s.id === 1}
                  onChange={() => onOptionChange(s.id)}
                />
                <div className="radioDesign">
                  <h3>{s.type}</h3>{" "}
                  <span>
                    {s.price} &nbsp; {s.currency}
                  </span>
                </div>
              </label>
            </div>
          ))}
        </div>

        {/*{subscriptionType && (*/}
        {/*  <Elements options={options} stripe={stripePromise}>*/}
        {/*    <CheckoutForm subscriptionType={subscriptionType} />*/}
        {/*  </Elements>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default Payment;
