import React, { useState, useEffect } from "react";
import "./Contact.scss";
// import { useTransition, animated } from 'react-spring'
import subscribe from "../../../images/subscribe.svg";
import axios from "../../../services/https";

import { useTranslation } from "react-i18next";
import { useTransition, animated } from "react-spring";

import contactUp from "../../../images/contact_up.svg";
import sucess from "../../../images/success.svg";
import error from "../../../images/error.svg";

const Contact = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const [success, setSuccess] = useState(false);
  const [errorSub, setErrorSub] = useState(false);

  const transitions = useTransition(success, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  useEffect(() => {
    if (success === true) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [success]);

  const successTransitions = useTransition(success, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  // Error
  const transitionsError = useTransition(errorSub, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  useEffect(() => {
    if (errorSub === true) {
      setTimeout(() => {
        setErrorSub(false);
      }, 2000);
    }
  }, [errorSub]);

  const errorTransitions = useTransition(errorSub, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmail("");
    // setSuccess(!success);
    // setErrorSub(!errorSub);

    axios
      .get(`/newsletter/${email}`)
      .then((res) => {
        console.log(email);
        setSuccess(!success);
        // localStorage.setItem("accessToken", res.data.data.accessToken);
        // console.log(res.data.data.accessToken);
      })
      .catch((err) => {
        setErrorSub(!errorSub);
        console.log(err);
      });
  };
  return (
    <div id="contact" className="contact">
      {/* Success subscribe */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setSuccess(false)}
            ></animated.div>
          )
      )}

      {successTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={sucess} alt="sucess" />
                <h3>Successfully subscribed</h3>
                <p>You have successfully subscribed to our newsletter</p>
              </div>
            </animated.div>
          )
      )}
      {/* End success */}

      {/* Error subscribe */}
      {transitionsError(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setErrorSub(false)}
            ></animated.div>
          )
      )}

      {errorTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={error} alt="error" />
                <h3>Subscribe error</h3>
                <p>
                  You have failed to register to our newsletter. Please check if
                  the email is correct format.
                </p>
              </div>
            </animated.div>
          )
      )}
      {/* End Error subscribe */}

      <div className="wrapper">
        {/* <h1>
          {t("contactUs.latestUpdates")} <br /> {t("contactUs.latestUpdates1")}
        </h1> */}
        <img className="mobileUp" src={contactUp} alt="contactUp" />
        <h1>{t("contactUs.latestText1")}</h1>
        <h1 className="second">{t("contactUs.latestText2")}</h1>
        <div className="notifyMe">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder={t("modals.enterYourEmail")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <button>{t("contactUs.notifyMe")}</button>
          </form>
        </div>
        <img className="notificationImage" src={contactUp} alt="contactUp" />
      </div>
    </div>
  );
};

export default Contact;
