import React, { useState, useEffect } from "react";
import "./Location.scss";
import { useTranslation } from "react-i18next";
import axios from "../../services/https";

import InputField from "../../components/InputField";
import SignUpBtn from "../../elements/SignBtn";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Location = ({ navigation }) => {
  // const config = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //   },
  // };

  const { t } = useTranslation();

  const [user, setUser] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address, SetAddress] = useState("");
  const [location, setLocation] = useState("");

  const [search, setSearch] = useState([]);
  const [city, setCity] = useState([]);
  const [value, setValue] = useState(city[0]);

  const [error, setError] = useState("Location is required field");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios
        .get(`/city?city=${search}`)
        .then((res) => {
          // console.log(res.data.data[0]);
          setCity(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const showText = (e) => {
    setSearch(e.target.value);
  };

  const showClickedList = () => {};

  function getUsers() {
    axios.get("/me").then((res) => {
      setUser(res.data.data);
      setPostalCode(res.data.data.postalCode);
      SetAddress(res.data.data.address);
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      address: address,
      postalCode: postalCode,
      cityId: !value ? error : value.id,
    };

    axios
      .put("/me", data)
      .then((res) => {
        getUsers();
        navigation.next();
        // navigation.go(5);
      })
      .catch((err) => {});
  };

  return (
    <div className="location">
      <h2>{t("modals.businessLocation")}</h2>
      <div className="locationContent">
        <form onSubmit={handleSubmit}>
          <Autocomplete
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(option) => option.name}
            options={city}
            sx={{ width: 300 }}
            // onChange={(e) => setSearch(e.target.value)}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option) => (
              <li {...props} key={option.id} onChange={showClickedList}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField onChange={showText} {...params} label="Location *" />
            )}
          />
          <p>{error}</p>

          <InputField
            label="Postal code"
            type="text"
            name="postalCode"
            id="postalCode"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
          <InputField
            label="Business address"
            type="text"
            name="businessAddress"
            id="businessAddress"
            value={address}
            onChange={(e) => SetAddress(e.target.value)}
          />
          <SignUpBtn title="Sign up" />
          {/* <button type="submit" onClick={() => navigation.next()}>
            test link
          </button> */}
        </form>
        <div className="locationInfo">
          <p>{t("modals.useOfBusinessLocation")}</p>
        </div>
      </div>
    </div>
  );
};

export default Location;
