import React from "react";
import { Link } from "react-router-dom";
import "./Feedback.scss";
import "../Settings.scss";

import arrow from "../../../../../images/arrow.svg";

const Feedback = () => {
  return (
    <div className="feedback">
      <h2>Feedback</h2>
      <Link to="/feedback" className="field">
        Give us feedback <img src={arrow} alt="arrow" />
      </Link>
    </div>
  );
};

export default Feedback;
