import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAMlxlsKSs5OAt3OHV57EeUc-s_nJCw374",
  authDomain: "linker-7fd41.firebaseapp.com",
  projectId: "linker-7fd41",
  storageBucket: "linker-7fd41.appspot.com",
  messagingSenderId: "940767279018",
  appId: "1:940767279018:web:750ac9d4361496cf7addd3",
  measurementId: "G-X2X3JV6TWR",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) =>
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log("firebase fgm", payload);
    })
  );

export const onBackgroundMessage = () =>
  new Promise((resolve) =>
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log("firebase bgm", payload);
    })
  );
