import React from "react";

const AccText = ({ navigation, onClick }) => {
  const accText = {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#7F7D87",
    lineHeight: "24px",
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "20px",
  };

  const link = {
    color: "#040015",
    fontWeight: "600",
    textDecoration: "underline",
    cursor: "pointer",
  };

  return (
    <div style={accText}>
      Already have an account?{" "}
      <a style={link} onClick={onClick}>
        Sign in!
      </a>
    </div>
  );
};

export default AccText;
