import React, { useState } from "react";
import "./Matches.scss";

import Navigation from "../../Navigation";
import Search from "../../../../components/Search";

import { useSelector } from "react-redux";
import List from "../List";

const Matches = () => {
  const [inputText, setInputText] = useState("");
  const app = useSelector((state) => state.app);

  return (
    <div className="view">
      <div className="matchContent pagePadding">
        <Search setInputText={setInputText} />
        <List input={inputText} app={app} type={"matches"} />
      </div>
    </div>
  );
};

export default Matches;
