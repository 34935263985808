import {TailSpin} from "react-loader-spinner";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import payment from "../../../../images/payment-error.svg";
import "./ExtendPayment.scss";
import axios from "axios";


const ExtendPayment = ({jobId, uid}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoader, setIsLoader] = useState(false);
    const [openPJ, setOpenPJ] = useState(false);


    const handleSubmit = async (event) => {

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                // return_url: "https://example.com/order/123/complete",
            },
            redirect: "if_required",
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
        } else {
            setIsLoader(true);
            // setOpen(!open);

            // stripe.createToken(elements)
            const data = {
                intentId: result.setupIntent.id,
                clientSecret: result.setupIntent.client_secret,
                paymentMethod: result.setupIntent.payment_method,
                subscriptionType: {subscriptionType: 'job'},
                jobUid: uid
            };


            const jsonData = {
                id: jobId,
            };

            axios.post("/subscribe/confirm", data).then((result) => {
                axios
                    .post("republish", jsonData)
                    .then((res) => {
                        setIsLoader(false);
                        window.location.reload(false);
                    })
                    .catch((err) => {
                        // error screen
                        console.log("Nije uspesno prosao job");
                    });
            });



            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return <div className={"jobPayment"}>
        {isLoader ? (
            <TailSpin
                height="80"
                width="80"
                color="#808080"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="spiner"
                visible={true}
            />
        ) : !openPJ ? (
            <form onSubmit={handleSubmit}>
                {/* End success */}
                <PaymentElement/>
                <button disabled={!stripe} >
                    Continue
                </button>
                <span className="info">

          </span>
            </form>
        ) : (
            <div className="successJob">
                <img src={payment} alt="payment"/>
                <h3>Successfully published job ad</h3>
                <p>
                </p>
            </div>
        )}
    </div>

}

export default ExtendPayment;