import React, { useState, useEffect } from "react";
import "./Otp.scss";
import { useTranslation } from "react-i18next";
import useDigitInput from "react-digit-input";
import { API } from "../../services/base";
import axios from "axios";
import { setUserSession } from "../../Utils/Common";

import SignUpBtn from "../../elements/SignBtn";
import { getFirebaseToken } from "../../firebase";
import version from "../../../package.json";

const Otp = ({ navigation }) => {
  const { t } = useTranslation();
  const [value, onChange] = React.useState("");
  const [reveal, setReveal] = useState(false);

  const [newT, setNewT] = useState();
  const [me, setMe] = useState([]);

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("email"));
    if (email) {
      setEmail(email);
    }

    handleGetFirebaseToken();

    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      setMe(me);
    }
  }, []);

  const handleGetFirebaseToken = () => {
    getFirebaseToken().then((firebaseToken) => {
      // console.log("Firebase token: ", firebaseToken);
      setNewT(firebaseToken);
    });
  };

  const [email, setEmail] = useState("");
  // const [code, setCode] = useState("");

  const [error, setError] = useState("");

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange,
  });

  const revealAnswer = () => {
    setReveal((reveal) => !reveal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      code: value,
    };

    const dataf = {
      token: newT,
      model: navigator.product,
      appVersion: version.version,
      type: 3,
    };

    axios
      .post(`${API}/me/verify`, data)
      .then((res) => {
        navigation.next();
        setUserSession(res.data.data.accessToken, res.data.data.user);
        localStorage.setItem("firebaseToken", newT);
        // console.log(setUserSession);
        axios.post("device", dataf).then((res) => {
          // console.log("device 200");
        });
      })
      .catch((res) => {
        console.log(res);
        setError(res.response.data.errorMessage);
      });

    // console.log(data);
  };

  const handleResetCode = (e) => {
    e.preventDefault();

    const data = {
      email: email,
    };

    axios
      .post(`${API}/me/resend-verification`, data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div className="otp">
      <div className="otpContent">
        <h2>{t("modals.checkInbox")}</h2>
        <p className="enterCodeText">{t("modals.enterCode")}</p>
        <form onSubmit={handleSubmit}>
          <div className="enterCode">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              hidden
            />
            {/* <input type="number" onChange={(e) => setCode(e.target.value)} /> */}

            <input inputMode="decimal" autoFocus {...digits[0]} />
            <input inputMode="decimal" {...digits[1]} />
            <input inputMode="decimal" {...digits[2]} />
            <input inputMode="decimal" {...digits[3]} />
          </div>
          <p className="errorMessage">{error}</p>

          <pre>
            <code>"{value}"</code>
          </pre>

          <SignUpBtn title={t("modals.verify")} />
          <p className="resetInfo">
            If you haven't received the code, make sure to check your spam or junk folder.
          </p>
        </form>
        {/* <button onClick={() => navigation.next()}>next</button> */}
        <div className="resendCode">
          <form onSubmit={handleResetCode}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              hidden
            />
            <br />
            <br />
            <button type="submit" onClick={revealAnswer}>
              {t("modals.resend")}
            </button>

          </form>
        </div>
      </div>
    </div>
  );
};

export default Otp;
