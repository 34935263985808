import React from "react";
import { useStep } from "react-hooks-helper";

import ForgotPassword from "./signInSteps/ForgotPassword";
import SignInStep from "./signInSteps/SignInStep";
import OtpSign from "./signInSteps/OtpSign";
import NewPassword from "./signInSteps/NewPassword";
import CreateAcc from "./steps/CreateAcc";
import Otp from "./steps/Otp";
import Location from "./steps/Location";
import Payment from "./steps/Payment";

const SignInSteps = () => {
  const steps = [
    { id: "signIn" },
    { id: "forgotPassword" },
    { id: "OtpSign" },
    { id: "newPassword" },
    { id: "createAcc" },
    { id: "otp" },
    { id: "location" },
    { id: "payment" },
  ];

  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { navigation };

  switch (step.id) {
    case "signIn":
      return <SignInStep {...props} />;
    case "forgotPassword":
      return <ForgotPassword {...props} />;
    case "OtpSign":
      return <OtpSign {...props} />;
    case "newPassword":
      return <NewPassword {...props} />;
    case "createAcc":
      return <CreateAcc {...props} />;
    case "otp":
      return <Otp />;
    case "location":
      return <Location />;
    case "payment":
      return <Payment />;
    default:
  }

  return <div>signInSteps</div>;
};

export default SignInSteps;
