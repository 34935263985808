import React, {useEffect, useState} from "react";
import "./EditProfile.scss";
import axios from "../../../../services/https";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import plus from "../../../../images/plus-upload.svg";
import change from "../../../../images/change.svg";
import close from "../../../../images/close.svg";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CircularProgress from '@mui/material/CircularProgress';
import {useDispatch} from "react-redux";
import {changeLogoAction, saveMandatoryInfo} from "../../../../redux/actions/app";
import BeatLoader from "react-spinners/BeatLoader";
import imageCompression from 'browser-image-compression';
import {createTheme} from "@material-ui/core";
import {ThemeProvider} from "@emotion/react";


const EditProfile = () => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [companyUrl, setLink] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedImg, setSelectedImg] = useState(null);
    const [selectedPath, setSelectedPath] = useState("");
    const [newImages, setNewImages] = useState([]);
    const [selectedP, setSelectedP] = useState(null);
    const [images, setImages] = useState([]);
    const [error, setError] = useState("");
    const [text, setText] = useState("");
    const [search, setSearch] = useState("");
    const [city, setCity] = useState([]);
    const [value, setValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadIndex, setUploadIndex] = useState(null);
    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState({
        nameError: '',
        cityError: '',
        logoError: '',
        imagesError: ''
    })

    const dispatch = useDispatch();

    const showText = (e) => {
        setSearch(e.target.value);
    };

    const getUsers = async () => {
        const userData = await axios.get("me")

        if (userData && userData.data) {
            setName(userData.data.data?.name || '');
            setAddress(userData.data.data?.address || '');
            setAbout(userData.data.data?.about || '');
            setValue(userData.data.data?.city || [])
            setSelectedPath(userData.data.data?.logo || "");
            setImages(userData.data.data?.images?.map((item) => ({
                path: item.imagePath,
                minifiedPath: item.minifiedPath
            })) || []);
            setLink(userData.data.data?.companyUrl || '');
            setSearch(userData.data.data?.city?.name || "");
        }
    }

    const compressImage = async (image) => {
        const options = {
            maxSizeMB: 2,
        }
        try {
            return await imageCompression(image, options);
        } catch (error) {
            return image;
        }
    }

    const uploadLogo = async (selectedLogo) => {
        setSelectedImage(selectedLogo)
        setUploadIndex(-2);
        axios
            .post("file", {file: await compressImage(selectedLogo)}, config)
            .then((res) => {
                setSelectedPath(res.data.data.path);
                setUploadIndex(null);
            })
            .catch((res) => {
                setUploadIndex(null);
                setSelectedImage(null);
                alert("There was an error with uploading");
            });
    };

    const uploadImages = async (selectedImage) => {
        setSelectedImg(selectedImage);
        setUploadIndex(-1);
        axios
            .post("file", {file: await compressImage(selectedImage)}, config)
            .then((res) => {
                setSelectedP({
                    path: res.data.data.path,
                    minifiedPath: res.data.data.minifiedPath
                });
                setImages(prev => [...prev, {
                    path: res.data.data.path,
                    minifiedPath: res.data.data.minifiedPath
                }])

                setSelectedImg(null);
                setUploadIndex(null);

            })
            .catch((res) => {
                setUploadIndex(null);
                setSelectedImg(null);
                alert("There was an error with uploading");
            });
    };

    const editUploadedImage = async (image, index) => {

        setUploadIndex(index);
        axios
            .post("file",{file:  await compressImage(image)}, config)
            .then((res) => {
                setSelectedP({
                    path: res.data.data.path,
                    minifiedPath: res.data.data.minifiedPath
                });
                images[index] = {
                    path: res.data.data.path,
                    minifiedPath: res.data.data.minifiedPath
                };
                setImages([...images]);
                setUploadIndex(null);
            })
            .catch((res) => {
                setUploadIndex(null);
                alert("There was an error with uploading");
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !value || !selectedPath || images.length === 0) {
            setErrors({
                nameError: !name && 'Name field is required',
                cityError: !value && 'Location field is required',
                imagesError: images.length === 0 && 'At least one image is required',
                logoError: !selectedPath && 'Logo is required'
            })
        } else {
            setErrors({
                nameError: '',
                cityError: '',
                imagesError: '',
                logoError: ''
            })
        }
            setIsLoading(true);

            axios.put("me", {
                name: name,
                about: text,
                companyUrl: companyUrl,
                cityId: value?.id,
                logo: selectedPath,
                images: images || [],
                address: address
            }).then(res => {
                setIsLoading(false)
                setError("");
                dispatch(changeLogoAction(selectedPath));
                dispatch(saveMandatoryInfo({
                    images: images,
                    name: name,
                    cityId: value?.id,
                    logo: selectedPath
                }))

                const userData = res;

                if (userData && userData.data) {
                    setName(userData.data.data?.name || '');
                    setAddress(userData.data.data?.address || '');
                    setAbout(userData.data.data?.about || '');
                    setValue(userData.data.data?.city || [])
                    setSelectedPath(userData.data.data?.logo || "");
                    setImages(userData.data.data?.images?.map((item) => ({
                        path: item.imagePath,
                        minifiedPath: item.minifiedPath
                    })) || []);
                    setLink(userData.data.data?.companyUrl || '');
                    setSearch(userData.data.data?.city?.name || "");
                }
            }).catch(err => {
                setIsLoading(false)
                setError(err?.response?.data)
            })

    }

    const clickOnUpload = (index) => {
        const id = "edit-image-" + index;
        const element = document.getElementById(id);
        if (element) {
            element.click();
        }
    }

    const deleteImage = (index) => {
        images.splice(index, 1);
        setImages([...images]);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get(`/city?city=${search}`).then((res) => {
                setCity(res.data.data);
            });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    useEffect(() => {
        getUsers();
    }, [])

    return isLoading ? (
        <div className="view">
            <div className="loader">
                <CircularProgress/>
            </div>
        </div>
    ) : (
        <div className="view">


            <div className="edtiProfile">
                <form onSubmit={handleSubmit}>
                    <div className="uploadLogo">
                        <p>Logo image <span>*</span> </p>

                        <div className="image-upload">
                            <label htmlFor="file-input">
                                {uploadIndex === -2 ?
                                    <div className={"loadingContainer"} >
                                        <BeatLoader size={3} color="#7F7D87" />
                                    </div> :
                                    <img src={plus} alt="plus"/>}

                            </label>

                            <input
                                id="file-input"
                                type="file"
                                name="myImage"
                                onChange={(e) => {
                                    uploadLogo(e.target.files[0]);
                                }}
                            />

                            {(selectedImage && (
                                <div>
                                    <img
                                        alt={selectedImage.name}
                                        className="uploadedImage"
                                        src={URL.createObjectURL(selectedImage)}
                                    />
                                </div>
                            )) || (
                                <div className="image-upload">
                                    {selectedPath && (
                                        <img
                                            alt="upload-img"
                                            className="logo"
                                            src={process.env.REACT_APP_BASE_URL + selectedPath}
                                        />
                                    )}

                                    <label htmlFor="file-input">
                                        {uploadIndex === -2 ?
                                            <div className={"loadingContainer"} >
                                                <BeatLoader size={3} color="#7F7D87" />
                                            </div> :
                                            <img src={change} alt="change"/>}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="error" style={{opacity: errors.logoError ? 1 : 0}}>{errors.logoError}</div>


                    <div className="UploadOtherImages">
                        <p>Other images <span>*</span></p>

                        <div className="uploadOther">
                            <div className="showImages">
                                {!images
                                    ? ""
                                    : images.map((img, index) => (
                                        <div className={"uploadOtherImage"}
                                             key={`${img.path}${index}`} >
                                            <div className={"for-delete-button"}
                                                 onClick={() => deleteImage(index)}
                                                 onTouchStart={() => deleteImage(index)}>
                                                <img alt={"delete"} src={close} />
                                            </div>
                                            <label htmlFor={"edit-image-" + index}>
                                                {uploadIndex === -2 ?
                                                    <div className={"loadingContainer"} >
                                                        <BeatLoader size={3} color="#7F7D87" />
                                                    </div> :
                                                    <img className={"labelImage"} src={change} alt="change"/>}
                                            </label>
                                            <input
                                                id={"edit-image-" + index}
                                                type="file"
                                                name="editImage"
                                                onChange={(e) => {
                                                    editUploadedImage(e.target.files[0], index);
                                                }}
                                                style={{visibility: 'hidden', position: 'absolute', zIndex: '-2'}}
                                            />
                                            <img alt={img.path} style={{cursor: 'pointer'}}
                                                 src={process.env.REACT_APP_BASE_URL + img.path}/>
                                        </div>
                                    ))}
                            </div>
                            {images.length < 5 ?
                                <div className="imagesUpload">
                                    <label htmlFor="file-inputt">
                                        {uploadIndex === -1 ?
                                            <div className={"loadingContainer"} >
                                                <BeatLoader size={3} color="#7F7D87" />
                                            </div> :
                                            <img src={plus} alt="plus"/>}
                                    </label>

                                    <input
                                        id="file-inputt"
                                        type="file"
                                        name="myImage"
                                        onChange={(e) => {
                                            uploadImages(e.target.files[0]);
                                        }}
                                    />

                                    {(selectedImg && (
                                        <div>
                                            <img
                                                alt={selectedImg.name}
                                                className="uploadedImage"
                                                src={URL.createObjectURL(selectedImg)}
                                            />
                                        </div>
                                    )) || (
                                        <div>
                                            {selectedP ? (
                                                <div></div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                                </div>
                                : null}

                        </div>
                    </div>
                    <div className="error" style={{opacity: errors.imagesError ? 1 : 0}}>{errors.imagesError}</div>


                    <TextField
                        error={errors.nameError && errors.nameError}
                        label="Organization"
                        value={name}
                        id="outlined-required"
                        required
                        defaultValue={name}
                        variant="outlined"
                        color={"primary"}
                        inputProps={{maxLength: 12}}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <div className="error" style={{opacity: errors.nameError ? 1 : 0}}>{errors.nameError}</div>

                    <Autocomplete
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        value={value}
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.name}
                        options={city}
                        sx={{width: 300}}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                error={errors.cityError && errors.cityError}
                                label="Location"
                                required
                                onChange={showText}
                                {...params}
                            />
                        )}
                    />
                    <div className="error" style={{opacity: errors.cityError ? 1 : 0}}>{errors.cityError}</div>

                    <TextField
                        error={errors.addressError && errors.addressError}
                        label="Address"
                        value={address}
                        id="outlined-required"
                        style={{marginBottom: "40px"}}
                        defaultValue={address}
                        inputProps={{maxLength: 500}}
                        onChange={(e) => setAddress(e.target.value)}
                    />

                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: ["heading", "|", "bold", "italic", "bulletedList"],
                        }}
                        data={about ? about : ""}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setText(data);
                        }}
                        value={about}
                    />

                    <TextField
                        label="Company URL"
                        value={companyUrl}
                        id="outlined-required"
                        defaultValue={companyUrl}
                        className="companyURL"
                        onChange={(e) => setLink(e.target.value)}
                    />

                    <button className="updateBtn" type="submit" onClick={handleSubmit}>
                        Update profile
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditProfile;
