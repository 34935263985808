import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import "./ChangePassword.scss";
import Navigation from "../../../Navigation";
import axios from "../../../../../services/https";
import sucess from "../../../../../images/success.svg";

import TextField from "@mui/material/TextField";
import eye from "../../../../../images/eye.svg";
import {useTranslation} from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const [isShown, setIsSHown] = useState(false);
  const [isShownRepeat, setIsSHownRepeat] = useState(false);
  const [isShownConfirmed, setIsShownConfirmed] = useState(false);

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const togglePasswordRepeat = () => {
    setIsSHownRepeat((isShownRepeat) => !isShownRepeat);
  };

  const togglePasswordConfirmed = () => {
    setIsShownConfirmed((isShownConfirmed) => !isShownConfirmed);
  };

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  });

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 200,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      password: password,
      newPassword: newPassword,
      newPasswordConfirmed: newPasswordConfirmed,
    };

    axios
      .post("/me/change-password", data)
      .then((res) => {
        setOpen(!open);
        console.log(res.data);
      })
      .catch((res) => {
        setError(res.response.data.messages);
      });
  };

  return (
    <div className="pos">
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={sucess} alt="sucess" />
                <h3>Successfully changed password</h3>
                <p>
                  Go to your new email to confirm changes. Follow instructions,
                  some description, some description.{" "}
                </p>
              </div>
            </animated.div>
          )
      )}

      <div className="view">
        <div className="changePassword pagePadding">
          <h2>Change password</h2>

          <form className="passwordFields" onSubmit={handleSubmit}>
            <div className="passwordField" style={{width: '390px'}} >
              <TextField
                label="Password"
                type={isShown ? "text" : "password"}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                error={error.password}
                helperText={error.password}
              />

              <input
                id="checkbox"
                type="checkbox"
                checked={isShown}
                onChange={togglePassword}
              />

              <label htmlFor="checkbox">
                <img className="passwordEye" src={eye} alt="eye" />
              </label>
            </div>

            <div className="passwordFieldNew" style={{width: '390px'}} >
              <TextField
                label="New password"
                type={isShownRepeat ? "text" : "password"}
                name="new password"
                onChange={(e) => setNewPassword(e.target.value)}
                error={error.newPassword}
                helperText={error.newPassword}
              />

              <input
                id="checkboxRepeat"
                type="checkbox"
                checked={isShownRepeat}
                onChange={togglePasswordRepeat}
              />

              <label htmlFor="checkboxRepeat">
                <img className="passwordEye" src={eye} alt="eye" />
              </label>
            </div>
            <div className="changePasswordReqs">{t("modals.passwordLimit")}</div>

            <div className="passwordFieldConfirmed" style={{width: '390px'}}>
              <TextField
                label="Confirm password"
                type={isShownConfirmed ? "text" : "password"}
                name="confirm password"
                onChange={(e) => setNewPasswordConfirmed(e.target.value)}
                error={error.newPasswordConfirmed}
                helperText={error.newPasswordConfirmed}
              />

              <input
                id="checkboxConfirmed"
                type="checkbox"
                checked={isShownConfirmed}
                onChange={togglePasswordConfirmed}
              />

              <label htmlFor="checkboxConfirmed">
                <img className="passwordEye" src={eye} alt="eye" />
              </label>
            </div>


            <button className="updatePassword" type="submit">
              Update password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
