import React, { useState } from "react";
import "./Tabs.scss";

// import { Card } from "../Cards/Card";
import Tab from "@material-ui/core/Tab";
import TabList from "@material-ui/lab/TabList";

const Tabs = ({ onChange }) => {
  return (
    <TabList onChange={onChange} variant="scrollable">
      <Tab label="All jobs" value="1" />
      <Tab label="Inactive jobs" value="2" />
      <Tab label="Active jobs" value="3" />
    </TabList>
  );
};

export default Tabs;
