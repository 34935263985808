import React, { useState, useEffect } from "react";
import "./SignInStep.scss";
import { useTranslation } from "react-i18next";
import { setUserSession, removeUserSession } from "../../Utils/Common";
import { useNavigate } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import axios from "../../services/https";
import { API } from "../../services/base";

import SignUpBtn from "../../elements/SignBtn";
import GoToSignUp from "../steps/GoToSignUp";

import AppStore from "../../images/apple.svg";
import GooglePlay from "../../images/googleplay.svg";
import AppStoreDesktop from "../../images/appstore-desktop.svg";
import GooglePlayDesktop from "../../images/googleplay-desktop.svg";
import eye from "../../images/eye.svg";
import info from "../../images/info.svg";

import TextField from "@mui/material/TextField";

import { getFirebaseToken } from "../../firebase";
import version from "../../../package.json";
import Payment from "../steps/Payment";

const SignInStep = ({ navigation }) => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [isShown, setIsSHown] = useState(false);

  const [open, setOpen] = useState(false);
  const [pay, setPay] = useState(false);

  const [newT, setNewT] = useState();
  const [me, setMe] = useState([]);

  const [user, setUser] = useState("");

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    }
  }, [open]);

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsPay = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const payTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const navigate = useNavigate();

  useEffect(() => {
    handleGetFirebaseToken();

    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      setMe(me);
    }
  }, []);

  const handleGetFirebaseToken = () => {
    getFirebaseToken().then((firebaseToken) => {
      // console.log("Firebase token: ", firebaseToken);
      setNewT(firebaseToken);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
      isPersonalAccount: false
    };

    axios
      .post("/auth/login/email", data)
      .then((res) => {
        setUserSession(res.data.data.accessToken, res.data.data.user);

        axios.get("me").then((res) => {
          console.log(res.data.data);
          setUser(res.data.data);

          if (!res.data.data.isSubscribed) {
            navigation.go(7);
          } else {
            navigate("/dashboard");
          }
        });

        // localStorage.setItem("accessToken", res.data.data.accessToken);

        const dataf = {
          token: newT,
          model: navigator.product,
          appVersion: version.version,
          type: 3,
        };

        localStorage.setItem("firebaseToken", newT);

        axios.post("device", dataf).then((res) => {
        });
      })

      .catch((res) => {
        setError(res.response.data.messages);

        alert(res.response.data.messages.message);
      });
  };

  return (
    <div className="signIn">
      {/* Success email */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {menuTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup">
              <div className="emailHeader">
                <img src={info} alt="info" />
                <h3>
                  COMING SOON! <br /> ( APRIL 2023)
                </h3>
                <p>
                  The service is currently under construction. Sign up to our
                  newsletter to get notified when it launches
                </p>
              </div>
            </animated.div>
          )
      )}
      {/* End success */}

      {/* Success email */}
      {transitionsPay(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {payTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="emailPopup1">
              <div className="emailHeader">
                <img src={info} alt="info" />
                <h3>
                  COMING SOON! <br /> ( MARCH 2023)
                </h3>
                <p>
                  The service is currently under construction. Sign up to our
                  newsletter to get notified when it launches
                </p>
              </div>
            </animated.div>
          )
      )}
      {/* End success */}

      <h2>Sign in business account</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email address"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={error.email}
          helperText={error.email}
        />

        <div className="passwordField">
          <TextField
            label="Password"
            type={isShown ? "text" : "password"}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            error={error.password}
            helperText={error.password}
          />

          <input
            id="checkbox"
            type="checkbox"
            checked={isShown}
            onChange={togglePassword}
          />

          <label htmlFor="checkbox">
            <img className="passwordEye" src={eye} alt="eye" />
          </label>
        </div>

        <SignUpBtn title="Sign in" />
        {/* <div className="forgotPassword" onClick={() => setOpen(!open)}>
          {t("modals.forgotPassword")}
        </div> */}
        { <div className="forgotPassword" onClick={() => navigation.next()}>
          {t("modals.forgotPassword")}
        </div> }
      </form>

      <div className="businessInside">
        <div className="businessContent">
          <h2 className="title">{t("modals.individual")}</h2>
          <p>{t("modals.individualDescription")}</p>
          <div className="icons">
            <a href="https://apps.apple.com/rs/app/linker/id1671716248" target="_blank">
              <img className="appMobile" src={AppStore} alt="AppStore" />
              <img
                className="appDesktop"
                src={AppStoreDesktop}
                alt="AppStoreDesktop"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=se.linker" target="_blank">
              <img className="googleMobile" src={GooglePlay} alt="GooglePlay" />
              <img
                className="googleDesktop"
                src={GooglePlayDesktop}
                alt="GooglePlayDesktop"
              />
            </a>
          </div>
        </div>
      </div>

      <GoToSignUp onClick={() => navigation.go(4)} />
      {/* <GoToSignUp onClick={() => setOpen(!open)} /> */}
    </div>
  );
};

export default SignInStep;
