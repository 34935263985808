import React, { useState, useEffect } from "react";
import "./DesktopNavigation.scss";

import { useNavigate } from "react-router-dom";
import { removeUserSession } from "../../../Utils/Common";
import axios from "../../../services/https";

import { NavLink } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLogoAction,
  likeUserAction,
  openNewJobAction,
  saveJob,
  saveMandatoryInfo,
  saveUserAction
} from "../../../redux/actions/app";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import parse from "html-react-parser";

import logo from "../../../images/logo.png";
import dashboard from "../../../images/dashboard.svg";
import matches from "../../../images/matches-menu.svg";
import rightSwipe from "../../../images/right-swipes.svg";
import savedProfile from "../../../images/saved-profile.svg";
import messages from "../../../images/messages.svg";
import myJobs from "../../../images/my-jobs.svg";
import editProfile from "../../../images/edit-profile.svg";
import settingBlue from "../../../images/setting_blue.svg";
import settings from "../../../images/settings.svg";
import logOut from "../../../images/log-out.svg";
import close from "../../../images/close.svg";
import back from "../../../images/ic_back.svg";
import avatarImage from "../../../images/upload-image.svg";
import version from "../../../../package.json";

const DesktopNavigation = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState("");
  const [user, setUser] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [name, setName] = useState("");
  const [menuCounters, setMenuCounters] = useState({
    rightSwipes: 0,
    matches: 0,
    saved: 0,
    suggestions: 0,
  });

  const [me, setMe] = useState([]);

  const [filter, setOpenFilter] = useState(false);
  const [reviewJob, setReviewJob] = useState(false);

  const [gToken, setGToken] = useState(localStorage.getItem("firebaseToken"));

  const [value, setValue] = useState("1");
  const [jobs, setJobs] = useState([]);

  const [allWorkTypes, setWorkTypes] = useState([]);
  const [allWorkPlaces, setWorkPlaces] = useState([]);
  const [allLevels, setLevels] = useState([]);

  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [jobTitle, setJobTitle] = useState("");
  const [workType, setWorkType] = useState([]);
  const [workTypeNext, setWorkTypeNext] = useState([]);
  const [workPlace, setWorkPlace] = useState([]);
  const [workPlaceNext, setWorkPlaceNext] = useState([]);
  const [workLevels, setWorkLevels] = useState([]);
  const [workLevelsNext, setWorkLevelsNext] = useState([]);
  const [salary, setSalary] = useState(false);
  const [jobDescription, setJobDescription] = useState("");
  const [jobApplicationPage, setJobApplicationPage] = useState("");
  const [val, setVal] = useState([]);
  const [links, setLink] = useState([]);
  const [isActive, setIsActive] = useState("0");
  const [isAutoApply, setIsAutoApply] = useState(0);
  // const [isCompetitiveCompensation, setIsCompetitiveCompensation] =
  //   useState("0");

  const [about, setAbout] = useState("");
  const [selectedPath, setSelectedPath] = useState("");
  const [imageURLs, setImageURLs] = useState([]);

  const [text, setText] = useState("");
  const [search, setSearch] = useState([]);
  const [city, setCity] = useState([]);
  const [valueS, setValues] = useState();
  const [showCity, setShowCity] = useState("");
  const [showCityId, setShowCityId] = useState("");

  const [showClickedLocation, setShowClickedLocation] = useState("");
  const [showClickedLocationId, setShowClickedLocationId] = useState("");

  const mandatoryInfo = useSelector(state => state.app.mandatoryInfo);
  const app = useSelector((state) => state.app);


  let [itemList, setItemList] = useState([]);
  const dispatch = useDispatch();
  // const user = getUser();

  // const config = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //   },
  // };

  useEffect(() => {
    if (app.changedLogo) {
      setUser({...user, logo: app.changedLogo});
      dispatch(changeLogoAction(null));

    }
  }, [app.changedLogo]);

  //   Filter transition
  const transitionsFilter = useTransition(filter, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const filterTransitions = useTransition(filter, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });
  //  End filter transition

  //   Job review
  const transitionsJobReview = useTransition(reviewJob, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const filterTransitionsReview = useTransition(reviewJob, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });
  //   End job review

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (app.likedUser && menuCounters) {
        menuCounters.rightSwipes += 1;
        dispatch(likeUserAction(null));
    }
  }, [app.likedUser]);

  useEffect(() => {
    if (app.savedUser && menuCounters) {
      if (app.savedUser === 1) {
        menuCounters.saved += 1;
      } else {
        if (menuCounters.saved > 0) {
          menuCounters.saved -= 1;
        }
      }
      dispatch(saveUserAction(null));
    }
  }, [app.savedUser]);

  const showText = (e) => {
    setSearch(e.target.value);
  };

  const showClickedList = (name, id) => {
    const n = name;
    const i = id;

    setShowClickedLocation(n);
    setShowClickedLocationId(i);
  };

  const handleWorkType = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setWorkType([...workType, id]);
      setWorkTypeNext([...workTypeNext, value]);
    } else {
      setWorkType(workType.filter((e) => e !== id));
      setWorkTypeNext(workTypeNext.filter((e) => e !== value));
    }
  };

  const handleWorkPlace = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setWorkPlace([...workPlace, id]);
      setWorkPlaceNext([...workPlaceNext, value]);
    } else {
      setWorkPlace(workPlace.filter((e) => e !== id));
      setWorkPlaceNext(workPlaceNext.filter((e) => e !== value));
    }
  };

  const handleLevels = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setWorkLevels([...workLevels, id]);
      setWorkLevelsNext([...workLevelsNext, value]);
    } else {
      setWorkLevels(workLevels.filter((e) => e !== id));
      setWorkLevelsNext(workLevelsNext.filter((e) => e !== value));
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const abc = [...val, []];
    setVal(abc);
  };

  const handleAddLinks = (e) => {
    e.preventDefault();
    const link = [...links, []];
    setLink(link);
  };

  const handleContact = (onChangeValue, i) => {
    const inputdata = [...val];
    inputdata[i] = onChangeValue.target.value;
    setVal(inputdata);
  };

  const handleLinks = (onChangeValue, ls) => {
    const inputdata = [...links];
    inputdata[ls] = onChangeValue.target.value;
    setLink(inputdata);
  };

  const goBack = () => {
    setOpenFilter(!filter);
    setReviewJob(!reviewJob);
  };

  useEffect(() => {
    axios.get("me").then((res) => {
      // setUser(res.data.data);
      setUser(res.data.data);
      setName(res.data.data.name);
      setAbout(res.data.data.about);
      setSelectedPath(res.data.data.logo);
      setImageURLs(res.data.data.images.map((i) => i.imagePath));
      setLink(res.data.data.companyUrl);
      dispatch(saveMandatoryInfo({
        images: res.data.data.images,
        name: res.data.data.name,
        cityId: res.data.data.city.id,
        logo: res.data.data.logo
      }))
    });
    getMenuCounters();
    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      setMe(me);
    }
  }, []);

  // useEffect(() => {
  //   getUsers();
  // }, []);

  function getUsers() {
    axios.get("me").then((res) => {
      // setUser(res.data.data);
      setUser(res.data.data);
      setName(res.data.data.name);
      setAbout(res.data.data.about);
      setSelectedPath(res.data.data.logo);
      setImageURLs(res.data.data.images.map((i) => i.imagePath));
      // setLink(res.data.data.companyUrl);
    });
  }

  useEffect(() => {
    axios
      .get("app-data")
      .then((res) => {
        setWorkTypes(res.data.data.tagCategories[1].tags);
        setWorkPlaces(res.data.data.tagCategories[2].tags);
        setLevels(res.data.data.tagCategories[3].tags);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("job")
      .then((res) => {
        setJobs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const itemList = JSON.parse(localStorage.getItem("mydata"));
    if (itemList) {
      setItemList(itemList);
    }

    getUsers();
  }, []);

  // const getUsers = () => {
  //   axios.get("/me").then((res) => {
  //     setUser(res.data.data);
  //     setName(res.data.data.name);
  //   });
  // };

  function getMenuCounters() {
    axios.get("/menu-counters").then((res) => {
      setMenuCounters(res.data);
    });
  }

  const handleLogout = () => {
    const data = {
      userId: me.id,
      token: gToken,
      model: navigator.product,
      appVersion: version.version,
      type: 3,
    };
    axios.delete("device", data).then((res) => {
      removeUserSession();
      navigate("/");
    });
  };

  const handlePostJob = (e) => {
    e.preventDefault();
    dispatch(openNewJobAction(true));
    navigate("/my-jobs");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleInactive = (e) => {
    e.preventDefault();

    const data = {
      title: jobTitle,
      isActive: 0,
      isAutoMatch: isAutoApply,
      // isCompetitiveCompensation: isCompetitiveCompensation,
      preferredWorkType: workType,
      preferredWorkPlace: workPlace,
      experienceLevel: workLevels,
      isCompetitiveCompensation: salary,
      description: jobDescription,
      contacts: val,
      applicantPageUrl: jobApplicationPage,
      links: links,
      cityId: !showCityId ? valueS?.id : showCityId,
    };

    axios
      .post("job", data)
      .then((res) => {
        // window.location.reload(false);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handleReview = (e) => {
    e.preventDefault();

    itemList = {
      title: jobTitle,
      isActive: 1,
      isAutoMatch: isAutoApply,
      // isCompetitiveCompensation: isCompetitiveCompensation,
      preferredWorkType: workType,
      preferredWorkPlace: workPlace,
      experienceLevel: workLevels,
      isCompetitiveCompensation: salary,
      description: jobDescription,
      contacts: val,
      applicantPageUrl: jobApplicationPage,
      links: links,
    };

    // localStorage.setItem("mydata", JSON.stringify(itemList));
    dispatch(saveJob(itemList));

    setOpenFilter(!filter);
    setReviewJob(!reviewJob);
  };

  const handleSubmitPreview = (e) => {
    e.preventDefault();

    const data = {
      title: jobTitle,
      isActive: 1,
      isAutoMatch: isAutoApply,
      preferredWorkType: workType,
      preferredWorkPlace: workPlaceNext,
      experienceLevel: workLevels,
      isCompetitiveCompensation: salary,
      description: jobDescription,
      contacts: val,
      applicantPageUrl: jobApplicationPage,
      links: links,
      cityId: showClickedLocationId,
    };

    axios
      .post("job", data)
      .then((res) => {
        // window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disableNavLink = (e) => {
    getMenuCounters();
    if (mandatoryInfo?.images?.length === 0 || !mandatoryInfo?.name || !mandatoryInfo?.cityId || !mandatoryInfo?.logo ) {
      e.preventDefault()
    }
  }

  return (
    <div className="navigationDesktop">
      {/* Add job */}
      {transitionsFilter(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="FilterPopupBackground"
              onClick={() => setOpenFilter(false)}
            ></animated.div>
          )
      )}

      {filterTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="FilterPopupContent">
              <div className="FilterPopupHeader">
                <h3>Post a new job</h3>
                <div className="closePopup">
                  <img
                    src={close}
                    alt="close"
                    onClick={() => setOpenFilter(!filter)}
                  />
                </div>
              </div>
              <div className="filterPopupInside">
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Job title"
                    value={jobTitle}
                    id="outlined-required"
                    defaultValue={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                  />

                  <Autocomplete
                    onChange={(event, newValue) => {
                      setValues(newValue);
                    }}
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    options={city}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) =>
                      option.name === valueS.name
                    }
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.id}
                        onChange={() => showClickedList(option.name, option.id)}
                      >
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        onChange={showText}
                        {...params}
                        label={showCity ? showCity : "Location"}
                      />
                    )}
                  />

                  <div className="workType">
                    <h5>Work type</h5>
                    <div className="content">
                      {allWorkTypes.map((tag) => (
                        <div className="style">
                          <input
                            type="checkbox"
                            onChange={handleWorkType}
                            id={tag.id}
                            name="workType"
                            value={tag.name}
                            className="chk-btn"
                          />
                          <label
                            htmlFor={tag.id}
                            key={tag.id}
                            className="chk-btn"
                          >
                            {" "}
                            {tag.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="workPlace">
                    <h5>Work place</h5>
                    <div className="content">
                      {allWorkPlaces.map((tag) => (
                        <div className="style">
                          <input
                            type="checkbox"
                            id={tag.id}
                            value={tag.name}
                            onChange={handleWorkPlace}
                            name="workPlace"
                            className="chk-btn"
                          />
                          <label
                            htmlFor={tag.id}
                            key={tag.id}
                            className="chk-btn"
                          >
                            {tag.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="level">
                    <h5>Level</h5>
                    {allLevels.map((tag) => (
                      <div className="levelStyle">
                        <label key={tag.id}>
                          <div className="style">
                            {tag.name}
                            <span>{tag.description}</span>
                          </div>
                          <input
                            type="checkbox"
                            id={tag.id}
                            value={tag.name}
                            onChange={handleLevels}
                            name="workPlace"
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="salaryCompetitive">
                    <h5>Competitive salary</h5>
                    <label>
                      Competitive salary
                      <input
                        type="checkbox"
                        name="salary"
                        onChange={() => setSalary((salary) => !salary)}
                        // onChange={handleSalary}
                      />
                    </label>
                  </div>
                  <div className="jobDescription">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "bulletedList",
                        ],
                        height: 500
                      }}
                      data={jobDescription}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setJobDescription(data);
                      }}
                    />
                  </div>

                  <div className="contactPerson">
                    <div className="title">
                      <h5>Contact person</h5>
                      <button onClick={handleAdd}>+ Add new</button>
                    </div>
                    {val.map((data, i) => (
                      <div>
                        <TextField
                          id="outlined-required"
                          onChange={(e) => handleContact(e, i)}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="jobApplicationPage">
                    <h5>Job application page</h5>
                    <TextField
                      value={jobApplicationPage}
                      id="outlined-required"
                      onChange={(e) => setJobApplicationPage(e.target.value)}
                    />
                  </div>

                  <div className="links">
                    <div className="title">
                      <h5>Links</h5>
                      <button onClick={handleAddLinks}>+ Add new</button>
                    </div>

                    {links.map((data, ls) => (
                      <div>
                        <TextField
                          id="outlined-required"
                          onChange={(e) => handleLinks(e, ls)}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="salaryCompetitive">
                    <h5>Auto match</h5>
                    <label>
                      Auto match (70%)
                      <input
                        type="checkbox"
                        name="autoApply"
                        // onChange={handleApply}
                        onChange={() =>
                          setIsAutoApply((isAutoApply) => !isAutoApply)
                        }
                      />
                    </label>
                  </div>

                  <p className="adCost">
                    The ad will cost <span>1499 SEK</span> and will last for
                    <span>30 days</span>
                    <br/>
                    (Note that the price are exclusive of applicable taxes.)
                  </p>
                  <button className="reviewPublich" onClick={handleReview}>
                    Review & publish
                  </button>

                  <button onClick={handleInactive} className="inactiveJob">
                    Save as inactive
                  </button>
                </form>
              </div>
            </animated.div>
          )
      )}
      {/* End add job */}

      {/* Preview job */}
      {transitionsJobReview(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="FilterPopupBackground"
              onClick={() => setReviewJob(false)}
            ></animated.div>
          )
      )}

      {filterTransitionsReview(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="FilterPopupContent">
              <div className="FilterPopupHeader">
                <h3>
                  <img src={back} alt="back" onClick={goBack} />
                  Review job ad
                </h3>
              </div>
              <div className="filterPopupInside">
                <form onSubmit={handleSubmit}>
                  <div className="jobPreview">
                    <div className="imageSlides">
                      <Carousel
                        responsive={responsive}
                        ssr={true}
                        swipeable={true}
                        infinite={true}
                        showDots={true}
                        removeArrowOnDeviceType={[
                          "desktop",
                          "tablet",
                          "mobile",
                        ]}
                      >
                        {imageURLs.map((img) => (
                          <img src={process.env.REACT_APP_BASE_URL + img} />
                        ))}
                      </Carousel>
                    </div>
                    <div className="jobPreviewContent">
                      <div className="companyLogoName" >
                        <div className="logo">
                          <img
                            className="logo"
                            src={process.env.REACT_APP_BASE_URL + selectedPath}
                          />
                        </div>
                        <div className="name">{name}</div>
                      </div>
                      <h2>{jobTitle ? jobTitle : "-"}</h2>
                      <div className="levels">
                        {workLevelsNext ? (
                          <div className="workLevelNext">
                            {workLevelsNext.map((wl) => (
                              <p>{wl}</p>
                            ))}
                          </div>
                        ) : (
                          "-"
                        )}
                        {/* {workLevelsNext ? workLevelsNext : "-"} */}
                      </div>
                      <div className="location">{showClickedLocation}</div>
                      <div className="workType">
                        {workTypeNext ? (
                          <div className="workTypesNext">
                            {workTypeNext.map((wt) => (
                              <p>{wt}</p>
                            ))}
                          </div>
                        ) : (
                          "-"
                        )}
                        {/* {workTypeNext ? workTypeNext : "-"} */}
                      </div>
                      <h3>Job description</h3>
                      <div className="jobDesc">{parse(jobDescription)}</div>
                      <h3>Salary</h3>
                      <div className="salary">-</div>
                      <h3>Links</h3>
                      <div className="links">
                        {links ? (
                          <div className="link">
                            {links.map((l) => (
                              <a href={l} target="_blank" rel="noreferrer">
                                {l}
                              </a>
                            ))}
                          </div>
                        ) : (
                          "-"
                        )}
                        {/* {links ? links : "-"} */}
                      </div>
                      <h3>Job applicant page</h3>
                      <div className="jobApplicantPage">
                        {jobApplicationPage ? (
                          <a
                            className="link"
                            href={jobApplicationPage}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {jobApplicationPage}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <h3>Contact person</h3>
                      <div className="contactPerson">
                        {val ? (
                          <div className="text">
                            {val.map((v) => (
                              <p>{v}</p>
                            ))}
                          </div>
                        ) : (
                          "-"
                        )}

                        {/* {val ? val : "-"} */}
                      </div>
                      {workPlaceNext}
                    </div>
                  </div>
                  <p className="adCostReview">
                    The ad will cost <span>1499 SEK</span> and will last for
                    <span>30 days</span>
                    <br/>
                    (Note that the price are exclusive of applicable taxes.)
                  </p>
                  <button
                    className="reviewPublich"
                    type="submit"
                    onClick={handleSubmitPreview}
                  >
                    Review & publish
                  </button>
                </form>
              </div>
            </animated.div>
          )
      )}
      {/* End preview job */}

      <div className="navigationContent">
        <a href="/dashboard">
          {" "}
          <img className="logo" src={logo} alt="logo" />
        </a>
        <div className="profileInfo" onClick={() => navigate("/edit-profile")}>
          {user.logo ? (
            <img src={baseUrl + user.logo} alt="avatarImage" />
          ) : (
            <img src={avatarImage} alt="avatarImage" />
          )}

          <h3>{user.name}</h3>
        </div>
      </div>
      <ul className="menu">
        <li>
          <NavLink exact="true" activeclassname="active" to="/dashboard">
            <img src={dashboard} alt="dashboard" />
            Dashboard
            {menuCounters?.suggestions > 0 ? (
              <span className="notifications">{menuCounters?.suggestions}</span>
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={disableNavLink} activeclassname="active" to="/match">
            <img src={matches} alt="matches" />
            Matches
            {menuCounters?.matches > 0 ? (
              <span className="notifications">{menuCounters?.matches}</span>
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={disableNavLink} to="/right-swipes">
            <img src={rightSwipe} alt="rightSwipe" />
            Right swipes
            {menuCounters?.rightSwipes > 0 ? (
              <span className="notifications">{menuCounters?.rightSwipes}</span>
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={disableNavLink} to="/saved-profiles">
            <img src={savedProfile} alt="savedProfile" />
            Saved profiles
            {menuCounters?.saved > 0 ? (
              <span className="notifications">{menuCounters?.saved}</span>
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink onClick={disableNavLink} to="/messages">
            <img src={messages} alt="messages" />
            Messages
          </NavLink>
        </li>
        <li>
          <NavLink onClick={disableNavLink} activeclassname="active" to="/my-jobs">
            <img src={myJobs} alt="myJobs" />
            My jobs
          </NavLink>
        </li>
        <li>
          <NavLink to="/edit-profile">
            <img src={editProfile} alt="editProfile" />
            Edit profile
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/settings">
            <img src={settings} alt="settings blue" />
            Settings
          </NavLink>
        </li>
      </ul>
      <div className="logOut">
        <a type="button" value="Logout" onClick={handleLogout}>
          <img src={logOut} alt="logOut" />
          Log out
        </a>
      </div>

      <button onClick={handlePostJob} className="postJob">
        Post a new job
      </button>
    </div>
  );
};

export default DesktopNavigation;
