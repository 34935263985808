import React from "react";
import "./Terms.scss";
import Nav from "../Navigation/Nav";
import Footer from "../Footer/Footer";

const Terms = () => {
  return (
    <div>
      <Nav />
      <iframe src={"https://prod.linker.se/business-terms"} style={{width:"100vw", height: "calc(100vh - 106px)", border: 0}} />
      <Footer />
    </div>
  );
};

export default Terms;
