import React, { useState, useEffect } from "react";
import "./CreateAcc.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { API } from "../../services/base";
import axios from "axios";

import SignUpBtn from "../../elements/SignBtn";
import AccText from "../AccText";
import eye from "../../images/eye.svg";

import TextField from "@mui/material/TextField";
import version from "../../../package.json";
import {getFirebaseToken} from "../../firebase";
import {setUserSession} from "../../Utils/Common";

const CreateAcc = ({ navigation }) => {
  const { t } = useTranslation();

  const isPersonalAccount = 0;
  const isTosAccepted = 0;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setRepeatPassword] = useState("");
  const [name, setName] = useState("");
  const [vatNumber, setVat] = useState("");

  const [error, setError] = useState("");

  const [isShown, setIsSHown] = useState(false);
  const [isShownRepeat, setIsSHownRepeat] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const togglePasswordRepeat = () => {
    setIsSHownRepeat((isShownRepeat) => !isShownRepeat);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      isPersonalAccount: isPersonalAccount,
      isTosAccepted: isTosAccepted,
      email: email,
      password: password,
      passwordRepeat: passwordRepeat,
      name: name,
      vatNumber: vatNumber,
    };

    axios
      .post(`${API}/me`, data)
      .then((res) => {
        navigation.next();
        localStorage.setItem("email", JSON.stringify(email));
        setUserSession(res.data.data.accessToken, res.data.data.user);
        handleGetFirebaseToken();

      })
      .catch((res) => {
        console.log(res);
        setError(res.response.data.messages);
        alert(res.response.data.errorMessage);
      });
  };

  const handleGetFirebaseToken = () => {
    getFirebaseToken().then((firebaseToken) => {
      const dataf = {
        token: firebaseToken,
        model: navigator.product,
        appVersion: version.version,
        type: 3,
      };

      localStorage.setItem("firebaseToken", firebaseToken);

      axios.post("device", dataf).then((res) => {});
    });
  };


  return (
    <div className="createAcc">
      <h2 className="title">{t("modals.createBusinessAcc")}</h2>
      <div className="createAccContent">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Business name"
            type="text"
            name="business name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={error.name}
            helperText={error.name}
          />

          <TextField
            label="Organization number"
            type="text"
            name="organization number"
            value={vatNumber}
            onChange={(e) => setVat(e.target.value)}
            error={error.vatNumber}
            helperText={error.vatNumber}
          />

          <TextField
            label="Email address"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={error.email}
            helperText={error.email}
          />

          <div className="passwordField">
            <TextField
              label="Password"
              type={isShown ? "text" : "password"}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              error={error.password}
              helperText={error.password}
            />

            <input
              id="checkbox"
              type="checkbox"
              checked={isShown}
              onChange={togglePassword}
            />

            <label htmlFor="checkbox">
              <img className="passwordEye" src={eye} alt="eye" />
            </label>
          </div>

          <div className="passwordFieldNew">
            <TextField
              label="Repeat password"
              type={isShownRepeat ? "text" : "password"}
              name="new password"
              onChange={(e) => setRepeatPassword(e.target.value)}
              error={error.passwordRepeat}
              helperText={error.passwordRepeat}
            />

            <input
              id="checkboxRepeat"
              type="checkbox"
              checked={isShownRepeat}
              onChange={togglePasswordRepeat}
            />

            <label htmlFor="checkboxRepeat">
              <img className="passwordEye" src={eye} alt="eye" />
            </label>
          </div>

          <div className="passwordRequireds">{t("modals.passwordLimit")}</div>
          {/* <button onClick={() => navigation.next()}>next</button> */}
          <SignUpBtn type="submit" title="Sign up" />
        </form>
        <div className="termsAndPrivacy">
          <p>
            {t("modals.agree")}
            <a href={"https://linker.se/terms"} target="_blank">{t("modals.terms")}</a> {t("modals.and")}{" "}
            <a href={"https://linker.se/privacy"} target="_blank">{t("modals.privacy")}.</a>{" "}
          </p>
        </div>
        <AccText />
      </div>
    </div>
  );
};

export default CreateAcc;
