import React, {useEffect, useState, useRef} from "react";
import "./Messages.scss";

import Navigation from "../../Navigation";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import {onBackgroundMessage} from '../../../../firebase';
import axios from '../../../../services/https';
import moment from "moment";

const Messages = () => {
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(0);
    const [activeConversation, setActiveConversation] = useState(0);
    const [chatPage, setChatPage] = useState(0);
    const [isChatLoading, setIsChatLoading] = useState(false);

    const chatListRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    });

    useEffect(() => {
        handleResize();
    }, []);


    const handleResize = () => {
        if (document.documentElement.clientWidth <= 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {

        onBackgroundMessage()
            .then((payload) => {
                const conversationIndex = conversations.findIndex((conversation) => {
                    return conversation.id == payload.data.objectId;
                });

                if (conversationIndex != -1 && payload.data.action === "SEND_MESSAGE") {
                    conversations[conversationIndex].lastMessage = JSON.parse(
                        payload.data.message
                    );

                    if (conversations[conversationIndex].id !== activeConversation) {
                        conversations[conversationIndex].unreadCount += 1;
                    }

                    setConversations([...conversations]);
                }

                if (messages[0].conversationId === parseInt(payload.data.objectId)) {
                    setMessages(prev => [...prev, JSON.parse(payload.data.message)]);
                    chatListRef?.current.scrollTo({top: 0, behavior: 'smooth'});
                }

            })
            .catch((err) =>
                console.log(
                    "An error occured while retrieving background message. ",
                    err
                )
            );
    });

    const updateMessages = (conversationId) => {
        if (activeConversation !== conversationId) {
            setActiveConversation(conversationId);
            setChatPage(0);
            setIsChatLoading(true);
            axios.get(`/messages/${conversationId}/0`).then((res) => {

                setMessages(res.data.data.reverse());
                setIsChatLoading(false);
            })

            reorderConversations(conversationId);
        }
    }
    const reorderConversations = (conversationId) => {
        const updatedConversations = conversations.map(item => {
            if (item.lastMessage?.conversationId === conversationId) {
                return {
                    ...item,
                    unreadCount: 0
                }
            }

            return item
        })

        setConversations(updatedConversations)
    }


    const refreshMessage = (message) => {
        const conversationIndex = conversations.findIndex((c) => {
            return c.id === message.conversationId
        });
        if (conversationIndex !== -1) {
            conversations[conversationIndex].lastMessage = message;
            setConversations([...conversations]);
        } else {
            getConversations();
        }
    }

    useEffect(() => {
        if (chatPage > 0 && messages.length >= (chatPage * 20)) {
            axios.get(`/messages/${activeConversation}/${chatPage}`).then((res) => {
                res.data.data.forEach(item => {
                    const found = messages.some(el => el.id === item.id)
                    if (!found) {
                        setMessages((prev) => [item, ...prev])
                    }
                });
            })
        }
    }, [activeConversation, chatPage, messages])

    useEffect(() => {
        getConversations();
    }, [page, activeConversation]);


    const getConversations = async () => {
        await axios.get(`/conversations/${page}`).then((res) => {
            if (page > 0) {
                setConversations(prev => [...prev, ...res.data.data]);
            } else {
                setConversations(res.data.data);
            }
        });
    }

    const getTime = (realTime) => {

        const date = new Date(realTime);
        const today = new Date();

        if (today.toDateString() === date.toDateString()) {
            return moment(realTime).format("LT")
        } else {
            return moment(realTime).format("DD.MM.")
        }

    }

    return (
        <div className="view">
            <div className="messages">
                {isMobile ? <Mobile
                        conversations={conversations}
                        messages={messages}
                        setMessages={setMessages}
                        page={page}
                        updateMessages={updateMessages}
                        backFunc={() => setActiveConversation(0)}
                        search={(searchData) => setConversations(searchData)}
                        loadMore={() => setPage(page + 1)}
                        loadChat={() => setChatPage(chatPage + 1)}
                        isChatLoading={isChatLoading}
                        refreshMessage={refreshMessage}
                        getTime={getTime}
                    /> :
                    <Desktop
                        ref={chatListRef}
                        conversations={conversations}
                        messages={messages}
                        setMessages={setMessages}
                        page={page}
                        updateMessages={updateMessages}
                        search={(searchData) => setConversations(searchData)}
                        loadMore={() => setPage(page + 1)}
                        loadChat={() => setChatPage(chatPage + 1)}
                        isChatLoading={isChatLoading}
                        refreshMessage={refreshMessage}
                        getTime={getTime}

                    />
                }
            </div>
        </div>
    );
};

export default Messages;
