import React from "react";
import TabPanel from "@material-ui/lab/TabPanel";

import Card from "../Cards/Card";

export const Content = ({ jobs }) => {
  return (
    <div className="contentCards">
      <TabPanel value="1">
        {jobs
          .sort((a, b) => b.isActive - a.isActive)
          .map((job) => (
            <Card key={job.id} job={job} />
          ))}
      </TabPanel>
      <TabPanel value="2">
        {jobs
          .filter((job) => job.isActive == false)
          .map((job) => (
            <Card key={job.id} job={job} />
          ))}
      </TabPanel>
      <TabPanel value="3">
        {jobs
          .filter((job) => job.isActive == true)
          .map((job) => (
            <Card key={job.id} job={job} />
          ))}
      </TabPanel>
    </div>
  );
};

export default Content;
